var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import * as styles from "./styles.module.sass";
import NavigationItems from "./NavigationItems/NavigationItems";
import { hot } from "react-hot-loader";
import { Drawer } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { navDrawerAtom } from "../atoms";
import { makeStyles } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
var drawerWidth = "21.4rem";
var useStyles = makeStyles(function (theme) { return ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundColor: "#222222",
    },
}); });
var NavBar = function () {
    var MuiClasses = useStyles();
    var _a = useRecoilState(navDrawerAtom), navDrawerState = _a[0], setNavDrawerState = _a[1];
    var isOpen = navDrawerState.isOpen;
    var isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    React.useEffect(function () {
        if (!isTabletOrMobile && !isOpen) {
            setNavDrawerState(function (prevState) { return (__assign(__assign({}, prevState), { isOpen: true })); });
        }
        else if (isTabletOrMobile && isOpen) {
            setNavDrawerState(function (prevState) { return (__assign(__assign({}, prevState), { isOpen: false })); });
        }
    }, [isTabletOrMobile]);
    return (React.createElement(Drawer, { open: isOpen, anchor: "left", variant: "persistent", className: MuiClasses.drawer, classes: { paper: MuiClasses.drawerPaper } },
        React.createElement("nav", { className: "" + styles.navBar },
            React.createElement("div", { className: styles.items },
                React.createElement(NavigationItems, null)),
            React.createElement("div", { style: { marginTop: "auto", width: "100%" }, className: styles.showS },
                React.createElement("div", { style: { margin: 0 }, className: styles.separator }),
                React.createElement("p", { className: styles.footer }, "\u00A9 Koinz LLC. 2020")))));
};
export default hot(module)(NavBar);
