import React from "react";
import * as styles from "./styles.module.sass";
var CourierInformation = function (Props) {
    var _a, _b;
    // get courier info and delivery time
    var delivery_time = Props.delivery_time, tracking_url = Props.tracking_url, tracking_id = Props.tracking_id, courier_info = Props.courier_info;
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.courierInfoWrapper },
            React.createElement("span", { className: styles.title }, "Courier Information"),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.contentWrapper, style: { alignItems: "center" } },
                    React.createElement("div", { className: styles.courierInfoContentWrapper },
                        React.createElement("div", { className: styles.courierInfoRow },
                            React.createElement("img", { src: require("../../../../assets/c-user.svg") }),
                            React.createElement("span", null, (_a = courier_info) === null || _a === void 0 ? void 0 : _a.full_name)),
                        React.createElement("div", { className: styles.courierInfoRow },
                            React.createElement("img", { src: require("../../../../assets/c-phone.svg") }),
                            React.createElement("span", null, (_b = courier_info) === null || _b === void 0 ? void 0 : _b.phone_number)))),
                React.createElement("div", { style: { margin: " 0 1rem" } })),
            React.createElement("span", { className: styles.title }, "Delivery Information"),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.contentWrapper, style: { alignItems: "center" } },
                    React.createElement("div", { className: styles.deliveryInfoContentWrapper },
                        React.createElement("div", { className: styles.element },
                            React.createElement("span", { className: styles.key }, "ETA for delivereing"),
                            React.createElement("span", { className: styles.value },
                                delivery_time,
                                " min")),
                        React.createElement("div", { className: styles.element },
                            React.createElement("span", { className: styles.key }, "Delivery tracking ID"),
                            React.createElement("span", { className: styles.value }, tracking_id)),
                        tracking_url && (React.createElement("div", { className: styles.element },
                            React.createElement("span", { className: styles.key }, "Delivery tracking URL"),
                            React.createElement("a", { href: tracking_url, target: "_blank", className: styles.valueLink }, "Open tracking URL"))))),
                React.createElement("div", { style: { margin: " 0 1rem" } })))));
};
export default CourierInformation;
