import reducer from "./rootReducer";
import { applyMiddleware, compose, createStore } from "redux";
// The redux dev tools.
import { composeWithDevTools } from "redux-devtools-extension"; // eslint-disable-line
// The redux middlewares.
import createSagaMiddleware from "redux-saga";
// The redux store enhancers.
import { persistStore } from "redux-persist";
// Import the combined sagas.
import rootSaga from "./rootSaga";
import { loadingBarMiddleware } from "react-redux-loading-bar";
export var isDev = process.env.NODE_ENV === "development";
import { createMySocketMiddleware } from "../pusher/socketMiddleWare";
// The middlewares to be applied.
var middlewares = [];
// Instantiates the saga middleware.
var sagaMiddleware = createSagaMiddleware();
var socketMiddleWare = createMySocketMiddleware();
middlewares.push(
// optimisticUIMiddleware,
socketMiddleWare, sagaMiddleware);
middlewares.push(loadingBarMiddleware({
    promiseTypeSuffixes: ["REQUESTED", "FULFILLED", "REJECTED"]
}));
if (isDev) {
    // middlewares.push(logger);
    // } else {
    //   middlewares.push(crashReporter);
}
// Combine the middlewares.
var middleware = applyMiddleware.apply(void 0, middlewares);
var enhancerParams = [middleware];
// Compose Enhancers (With/Without Dev Tools)
var enhancer = isDev
    ? composeWithDevTools.apply(void 0, enhancerParams) : compose.apply(void 0, enhancerParams);
// Import the combined reducer.
export var store = createStore(reducer, enhancer);
export default (function () {
    return new Promise(function (res) {
        persistStore(store, {}, function () {
            res({ store: store });
            sagaMiddleware.run(rootSaga);
        });
    });
});
