/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, } from "reactstrap";
import * as styles from "./styles.module.sass";
import { useToggleState } from "../../hooks/useToggleState";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var SelectItem = function (_a) {
    var item = _a.item;
    var _b, _c;
    return ((_b = item) === null || _b === void 0 ? void 0 : _b.label) ? React.createElement("div", { className: styles.item }, (_c = item) === null || _c === void 0 ? void 0 : _c.label) : null;
};
var defaultRenderSelectToggle = function (item) { var _a; return (_a = item) === null || _a === void 0 ? void 0 : _a.label; };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var SelectItemWrapper = function (props) {
    var onSelect = function () { return props.onSelect(props.value); };
    return (React.createElement(DropdownItem, { "data-test-id": "cy-drop-down-search-input-select-item", onClick: onSelect }, props.children));
};
export var DropdownSearchInput = function (props) {
    var _a, _b, _c;
    var options = props.options, className = props.className, renderSelectItem = props.renderSelectItem, placeholder = props.placeholder, disabled = props.disabled, renderSelectToggle = props.renderSelectToggle, onChange = props.onChange, initialValue = props.initialValue;
    var _d = React.useState(initialValue), value = _d[0], selectValue = _d[1];
    var _e = useToggleState(false), isOpen = _e[0], toggleMenu = _e[1];
    var _f = React.useState(""), searchTerm = _f[0], setSearchTem = _f[1];
    var _g = React.useState(options), shownOptions = _g[0], setShownOptions = _g[1];
    // effect to apply defaults from props
    React.useEffect(function () {
        if (options && options[0]) {
            selectValue(options[0]);
        }
    }, []);
    React.useEffect(function () {
        setShownOptions(options);
    }, [options]);
    var handleChangeSearch = function (e) {
        setSearchTem(e.target.value);
    };
    React.useEffect(function () {
        var _a;
        var result = (_a = options) === null || _a === void 0 ? void 0 : _a.filter(function (option) { var _a, _b; return (_b = (_a = option) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchTerm.trim().toLowerCase()); });
        setShownOptions(result);
    }, [searchTerm]);
    var handleChange = function (v) {
        selectValue(v);
        onChange(v);
    };
    var defaultRenderSelectItem = function (option) { return (React.createElement(SelectItem, { item: option })); };
    return (React.createElement("div", { className: (className || "") + " " + styles.dropDownPart },
        React.createElement(Dropdown, { style: { height: "100%" }, isOpen: isOpen, toggle: toggleMenu },
            React.createElement(DropdownToggle, { disabled: disabled, className: styles.switch }, (renderSelectToggle || defaultRenderSelectToggle)(value) ||
                initialValue),
            React.createElement(DropdownMenu, { className: styles.menuWrapper, "data-test-id": "cy-drop-down-search-input-options" },
                React.createElement("div", { className: styles.searchWrapper },
                    React.createElement("div", { className: styles.inputWrapper },
                        React.createElement("input", { type: "text", onChange: handleChangeSearch, placeholder: placeholder, value: searchTerm, className: styles.input, "data-test-id": "cy-drop-down-search-input" }),
                        React.createElement("img", { className: styles.icon, src: require("../../../assets/search-google.svg") }))),
                ((_a = shownOptions) === null || _a === void 0 ? void 0 : _a.length) === 0 && searchTerm.trim() ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.emptyWrapper, "data-test-id": "cy-no-matched-result" },
                        React.createElement("span", { className: styles.emptyTitle }, "No result for"),
                        React.createElement("span", { className: styles.value }, "\u201C" + searchTerm + "\u201D")))) : (React.createElement(React.Fragment, null, ((_b = shownOptions) === null || _b === void 0 ? void 0 : _b.length) === 0 ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.emptyWrapper },
                        React.createElement("span", { className: styles.emptyTitle }, "No result")))) : (React.createElement(React.Fragment, null, (_c = shownOptions) === null || _c === void 0 ? void 0 : _c.map(function (option, index) { return (React.createElement(SelectItemWrapper, { key: index, value: option, onSelect: handleChange }, (renderSelectItem || defaultRenderSelectItem)(option))); })))))))));
};
