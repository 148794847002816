var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { withLoadingReducer } from "./withLoadingState";
import { reducer, on } from "ts-action";
import { getCustomerInfoSuccess } from "../actions/get-order-details-action";
import { clearPrevOrderDetailsAction, getCustomerInfoAction, } from "../../constants";
var initialState = {
    customerInfo: {
        customer: {
            app_version: "",
            birth_date: 0,
            country_code: "",
            created_at: 0,
            email: "",
            id: "",
            last_active_time: 0,
            name: "",
            password: "",
            phone_number: "",
            shopxId: "",
            update_data_screen_status: 0,
            updated_at: 0,
            area: {
                ArName: "",
                EnName: "",
                code: "",
            },
            country: {
                ArName: "",
                EnName: "",
                ISO_code: "",
                country_code: "",
                last_city_code: 0,
                pickup_status: "",
            },
            government: {
                ArName: "",
                EnName: "",
                address_by_google: "",
                code: "",
                latitude: 0,
                longitude: 0,
                rocket_code: "",
            },
        },
        total_orders: 0,
    },
};
export var CustomerInfoReducer = withLoadingReducer(reducer(initialState, on(getCustomerInfoSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { customerInfo: __assign(__assign({}, state.customerInfo), payload) }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { customerInfo: initialState.customerInfo })); })), getCustomerInfoAction);
