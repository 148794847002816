var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, debounce } from "redux-saga/effects";
import { selectToken } from "../selectors";
import { getBrandsAPI } from "../../axios/getBrands";
import { BrandStatues } from "../../components/BranchStatus/Brands";
import { brandsGetNextPage, brandsGetNextPageFailure, brandsGetNextPageRefresh, brandsGetNextPageSuccess, filterBrands, } from "../actions/brand-get-next-page-action";
function activeBrandsSaga() {
    var token, _a, store_country_code, routing_method, search, currentPage, next_page_status, ordering_availability, isFiltering, currentStatus_1, res, nextStatus, brands, isLastPage, stateToGetNext, isLastPageEver, page, e_1;
    var _b;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 8, , 10]);
                return [4 /*yield*/, select(function (state) { return state.brandGetNextPageReducer; })];
            case 3:
                _a = _d.sent(), store_country_code = _a.store_country_code, routing_method = _a.routing_method, search = _a.search, currentPage = _a.server_page, next_page_status = _a.next_page_status, ordering_availability = _a.ordering_availability, isFiltering = _a.isFiltering;
                currentStatus_1 = ordering_availability || next_page_status;
                return [4 /*yield*/, call(getBrandsAPI, token, 250, currentPage, currentStatus_1, routing_method, store_country_code, search)];
            case 4:
                res = _d.sent();
                nextStatus = (_b = {},
                    _b[BrandStatues.inactive] = BrandStatues.active,
                    _b[BrandStatues.active] = BrandStatues.closed,
                    _b[BrandStatues.closed] = BrandStatues.manual,
                    _b[BrandStatues.manual] = BrandStatues.manual,
                    _b);
                brands = ((_c = res.data) === null || _c === void 0 ? void 0 : _c.stores) || [];
                brands = brands.map(function (b) { return (__assign(__assign({}, b), { status: currentStatus_1 })); });
                isLastPage = brands.length === 0;
                stateToGetNext = void 0;
                if (isFiltering && ordering_availability) {
                    stateToGetNext = BrandStatues.inactive;
                }
                else {
                    stateToGetNext = isLastPage ? nextStatus[currentStatus_1] : currentStatus_1;
                }
                isLastPageEver = isLastPage && currentStatus_1 === BrandStatues.manual;
                page = !isLastPage ? currentPage + 1 : 0;
                return [4 /*yield*/, put(brandsGetNextPageSuccess({
                        brands: brands,
                        status: currentStatus_1,
                        stateToGetNext: stateToGetNext,
                        page: page,
                        isLastPageEver: isLastPageEver,
                    }))];
            case 5:
                _d.sent();
                if (!!isLastPageEver) return [3 /*break*/, 7];
                return [4 /*yield*/, put(brandsGetNextPage())];
            case 6:
                _d.sent();
                _d.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                e_1 = _d.sent();
                return [4 /*yield*/, put(brandsGetNextPageFailure(e_1))];
            case 9:
                _d.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function watchBrandsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, debounce(200, [brandsGetNextPageRefresh.type, brandsGetNextPage.type, filterBrands.type], activeBrandsSaga)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
