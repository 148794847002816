var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { withLoadingReducer } from "./withLoadingState";
import { reducer, on } from "ts-action";
import { getOrderAddressAction } from "../../constants";
import { getAddressSuccess } from "../actions/get-address-actions";
import { clearPrevOrderDetailsAction } from "../../constants/index";
var initialState = {
    address: {
        delivery_address: {
            apartment: "",
            building: "",
            floor: "",
            id: "",
            more_info: "",
            street: "",
            type: "",
            lat: 0,
            lng: 0
        },
        delivery_zone: {
            district_ar_name: "",
            district_en_name: "",
            sub_district_ar_name: "",
            sub_district_en_name: "",
            sub_district_id: "",
            city_en_name: "",
            city_ar_name: "",
        },
    },
};
export var getAddressReducer = withLoadingReducer(reducer(__assign({}, initialState), on(getAddressSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { address: __assign(__assign({}, state.address), payload) }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { address: initialState.address })); })), getOrderAddressAction);
