var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import * as styles from "./styles.module.sass";
import PasswordMask from "react-password-mask";
var eyeIcon = (React.createElement("img", { style: { width: "2.5rem" }, src: require("../../../assets/mask.svg") }));
var validIcon = (React.createElement("img", { style: { width: "2.5rem" }, src: require("../../../assets/tick.svg") }));
var invalidIcon = (React.createElement("img", { style: { width: "2.5rem" }, src: require("../../../assets/inputError.svg") }));
var hideIcon = (React.createElement("img", { style: { width: "2.5rem" }, src: require("../../../assets/eye_password-line.svg") }));
var RenderPasswordField = /** @class */ (function (_super) {
    __extends(RenderPasswordField, _super);
    function RenderPasswordField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RenderPasswordField.prototype.render = function () {
        var _a = this.props, input = _a.input, disabled = _a.disabled, label = _a.label, className = _a.className, maxLength = _a.maxLength, _b = _a.meta, dirty = _b.dirty, error = _b.error, valid = _b.valid, invalid = _b.invalid, active = _b.active, touched = _b.touched;
        var isInvalid = (dirty || touched) && invalid;
        var isValid = (dirty || touched) && valid;
        var mask = eyeIcon;
        var validationIcon = isInvalid ? invalidIcon : validIcon;
        mask = touched ? validationIcon : mask;
        mask = active ? eyeIcon : mask;
        return (React.createElement("div", { onFocusCapture: input.onFocus, className: className ? styles.fullWidthInput : styles.inputWrapper },
            React.createElement("label", { htmlFor: input.name, className: styles.label },
                React.createElement("span", null, label)),
            React.createElement(PasswordMask, __assign({ className: styles.passwordMaskWrapper, maxLength: maxLength, inputClassName: styles.passInput + " " + (active ? styles.active : "") + " " + (isInvalid ? styles.invalid : "") + " " + (isValid ? styles.valid : ""), showButtonContent: mask, hideButtonContent: hideIcon, disabled: disabled, placeholder: "......." }, input, { buttonStyles: { background: "none" }, id: input.name })),
            isInvalid && (React.createElement("p", { style: {
                    margin: 0,
                    padding: "1rem",
                    color: "#db3030",
                    fontSize: "1.2rem"
                } },
                "*",
                error))));
    };
    return RenderPasswordField;
}(React.Component));
export { RenderPasswordField };
