import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import ar from "./ar";
i18n.use(LanguageDetector).init({
    resources: {
        en: en,
        ar: ar
    },
    ns: ["translations"],
    defaultNS: "translations"
});
export default i18n;
