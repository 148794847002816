var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { regrantPointsAction } from "../../constants";
import { regrantPoints, regrantPointsSuccess, } from "../actions/user-fraud-actions";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    regrant_payload: {
        period_from: 0,
        period_to: 0,
        store_id: "",
        user_identifier: "",
    },
    message: {
        code: "",
        message: "",
    },
};
export var regrantPointsReducer = withLoadingReducer(reducer(initialState, on(regrantPoints, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { regrant_payload: payload }));
}), on(regrantPointsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { message: payload }));
})), regrantPointsAction);
