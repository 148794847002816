import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import OrderTracking from "./OrderTracking";
import OrderInDetails, { PaymentMethods } from "./OrderDetails";
import OrderInfo from "./OrderInfo";
import GenericButtonWithIcon from "../Shared-Components/GenericButtonWithIcon";
import { useDispatch } from "react-redux";
import { getOrderDetails, getCustomerInfo, getBranchInfo, getStoreInfo, getAssignedCashier, } from "../../redux-store/actions/get-order-details-action";
import { useSelect } from "../../helper/use-select";
import { orderStatus, noShow, } from "../../redux-store/actions/order-status-action";
import history from "../../helper/history";
import { getOrder } from "../../redux-store/actions/order-action";
import i18n from "../../i18n/i18n";
import { getLocalizedCurrency, getLocalizedCountry } from "../../constants/lib";
import { cashier } from "../../redux-store/actions/cashiers-action";
import { ClipLoader, DotLoader } from "react-spinners";
import { EnableCallNotification } from "../../redux-store/actions/get-call-logs";
import { useCallback, useMemo } from "react";
import { requestCashierToken } from "../../redux-store/actions/cashier-remote-login-actions";
import { getAddress } from "../../redux-store/actions/get-address-actions";
import { OrderType } from "./OrderInfo/CustomerInformation";
import { OrderStatus } from "../../types";
import diffInMinutes from "date-fns/differenceInMinutes";
import { DMSOrderStatus, AvailabilityStatus, RoutingMethodPOS, } from "../../constants/types";
import RoutingMethod from "./RoutingMethod";
import { getDeliveryReasons, getPickupReasons, } from "../../redux-store/actions/reasons-action";
import OrderingSource from "./OrderingSource";
import { changeBranchAvailabilityStatus, getBranchAvailabilityStatus, } from "../../redux-store/actions/branch-availability-actions";
import { LoadingStatus } from "../../redux-store/reducers/withLoadingState";
import { getUserProfile } from "../../redux-store/actions/user-profile-actions";
import { useMediaQuery } from "react-responsive";
import { clearPrevOrderDetailsAction } from "../../constants";
export var addDeliveryFees = function (left, order) {
    return left + (order.delivery_fees || 0);
};
var accepted = OrderStatus.accepted, out_for_delivery = OrderStatus.out_for_delivery, ready_for_pickup = OrderStatus.ready_for_pickup;
var inProgressStates = { accepted: accepted, out_for_delivery: out_for_delivery, ready_for_pickup: ready_for_pickup };
export var useGetCurrency = function (countryIsoCode) {
    return React.useMemo(function () {
        if (countryIsoCode) {
            getLocalizedCurrency(i18n.language, countryIsoCode);
        }
        return getLocalizedCurrency(i18n.language, countryIsoCode);
    }, [i18n.language, countryIsoCode]);
};
export var useGetCountry = function (iso_code) {
    return React.useMemo(function () {
        if (iso_code) {
            getLocalizedCountry(i18n.language, iso_code);
        }
        return getLocalizedCountry(i18n.language, iso_code);
    }, [i18n.language, iso_code]);
};
var zeroOut = function (num) {
    if (num === void 0) { num = 0; }
    return (num < 0 ? 0 : num);
};
var OrderDetails = function (_a) {
    var _b, _c;
    var match = _a.match;
    var _d, _e;
    var id = match.params.id;
    var dispatch = useDispatch();
    var back = function () {
        history.push("/wall");
    };
    React.useEffect(function () {
        dispatch(EnableCallNotification());
    }, []);
    React.useEffect(function () {
        dispatch(clearPrevOrderDetailsAction());
        dispatch(getStoreInfo(id));
        dispatch(getAddress(id));
        dispatch(getAssignedCashier(id));
        dispatch(getBranchInfo(id));
        dispatch(getCustomerInfo(id));
        dispatch(getOrderDetails({ id: id }));
        dispatch(orderStatus(id));
        dispatch(getOrder(id));
        dispatch(cashier(id));
        dispatch(noShow(id));
        var getOrderDetailsHandle = setInterval(function () {
            dispatch(orderStatus(id));
        }, 60000);
        var noShowHandle = setInterval(function () {
            dispatch(noShow(id));
            dispatch(getOrderDetails({ id: id }));
        }, 180000);
        return function () {
            clearInterval(noShowHandle);
            clearInterval(getOrderDetailsHandle);
        };
    }, [id]);
    var _f = useSelect(function (state) { return state.BranchInfoReducer; }), branchInfo = _f.branchInfo, loadingStatus = _f.loadingStatus;
    var customerInfo = useSelect(function (state) { return state.CustomerInfoReducer; }).customerInfo;
    var orderDetails = useSelect(function (state) { return state.orderDetailsReducer; }).orderDetails;
    var status = useSelect(function (state) { return state.orderStatusReducer; }).status;
    var storeInfo = useSelect(function (state) { return state.StoreInfoReducer; }).storeInfo;
    var order = useSelect(function (state) { return state.orderReducer; }).order;
    var currency = useGetCurrency(storeInfo.country_iso_code);
    var country = useGetCountry(storeInfo.country_iso_code);
    var customer_no_show = useSelect(function (state) { return state.noShowReducer; }).customer_no_show;
    var branch_availability_status = useSelect(function (state) { return state.branchAvailabilityReducer; }).status;
    var changeBrachAvailabilityLoading = useSelect(function (state) { return state.changeBranchAvailabilityReducer.loadingStatus; });
    var address = useSelect(function (state) { return state.getAddressReducer; }).address;
    var differenceInMs = Date.now() - order.created_at;
    var sinceCreationMinutes = Math.floor(differenceInMs / 1000 / 60);
    var estimatedCustomerArrival = zeroOut((order.estimated_customer_arrival_interval || 0) - sinceCreationMinutes);
    var estimatedDeliveryMaxTime = zeroOut(diffInMinutes(order.estimated_delivery_max_time || 0, new Date()));
    var estimatedDeliveryArrival = zeroOut(Number(estimatedDeliveryMaxTime) - sinceCreationMinutes);
    var canOpenCashier = useMemo(function () {
        return storeInfo.id && branchInfo.id;
    }, [id, storeInfo, branchInfo]);
    var goToBranchCashierApp = useCallback(function () {
        if (canOpenCashier) {
            dispatch(requestCashierToken({
                branch_id: branchInfo.id,
                store_id: storeInfo.id,
                store_pos: storeInfo.pos,
            }));
        }
    }, [storeInfo, branchInfo, canOpenCashier]);
    var branch_id = branchInfo.id;
    React.useEffect(function () {
        if (branch_id) {
            dispatch(getBranchAvailabilityStatus({ branch_id: branchInfo.id }));
        }
        var interval = setInterval(function () {
            dispatch(getBranchAvailabilityStatus({ branch_id: branch_id }));
        }, 60000);
        return function () { return clearInterval(interval); };
    }, [branch_id]);
    React.useEffect(function () {
        if (id && customerInfo.customer.phone_number) {
            dispatch(getUserProfile({
                phone_number: customerInfo.customer.phone_number,
            }));
        }
    }, [id, customerInfo.customer.phone_number]);
    var cancelledStates = (_b = {},
        _b["rejected"] = OrderStatus.rejected,
        _b["canceled_by_agent"] = OrderStatus.canceled_by_agent,
        _b["canceled_by_cashier"] = OrderStatus.canceled_by_cashier,
        _b["canceled_by_customer"] = OrderStatus.canceled_by_customer,
        _b["customer_no_show"] = OrderStatus.customer_no_show,
        _b["rejected_for_payment_failure"] = OrderStatus.rejected_for_payment_failure,
        _b);
    // Delivery Management System Statuses
    var dmsStatuses = (_c = {},
        _c[DMSOrderStatus.rejected] = "Rejected",
        _c[DMSOrderStatus.assigning_driver] = "Assigning courier ...",
        _c[DMSOrderStatus.courier_assigned] = "Courier assigned",
        _c[DMSOrderStatus.courier_delivering] = "Courier delivering ...",
        _c[DMSOrderStatus.courier_picking_up] = "Courier picking up the order ...",
        _c[DMSOrderStatus.canceled] = "Canceled",
        _c[DMSOrderStatus.delivered] = "Delivered by courier",
        _c[DMSOrderStatus.submitted] = "Submitted",
        _c[DMSOrderStatus.pending] = "Pending",
        _c[DMSOrderStatus.cancelled] = "Canceled",
        _c);
    React.useEffect(function () {
        if (orderDetails.order_type === OrderType.delivery &&
            cancelledStates[order.status]) {
            if (cancelledStates[order.status]) {
                dispatch(getDeliveryReasons(cancelledStates[order.status]));
            }
        }
        else if (cancelledStates[order.status] &&
            orderDetails.order_type === OrderType.pickup) {
            if (cancelledStates[order.status]) {
                dispatch(getPickupReasons(cancelledStates[order.status]));
            }
        }
    }, [order.status]);
    var setManualDispatching = function () {
        dispatch(changeBranchAvailabilityStatus({
            branch_id: branchInfo.id,
            data: { status: AvailabilityStatus.disconnected },
        }));
    };
    var isMobile = useMediaQuery({ maxWidth: 767 });
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.orderWrapper },
            React.createElement("div", { className: "d-flex" },
                React.createElement(GenericButtonWithIcon, { className: isMobile ? styles.mobileBtnWrapper : styles.btnWrapper, iconPath: require("../../assets/back.svg"), icon: styles.icon, onClick: back }),
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                        React.createElement("span", { className: styles.title },
                            "Order ID: ",
                            order.order_code),
                        orderDetails.order_type && (React.createElement("div", { className: styles.orderTypeWrapper },
                            React.createElement("img", { className: styles.orderTypeIcon, src: orderDetails.order_type === OrderType.delivery
                                    ? require("../../assets/motorcycle.svg")
                                    : require("../../assets/pickup.svg") }),
                            React.createElement("span", { className: orderDetails.order_type === OrderType.delivery
                                    ? styles.orderTypeTitle
                                    : styles.orderTypeTitlePickup }, orderDetails.order_type === OrderType.delivery
                                ? "Delivery"
                                : "Pickup")))),
                    orderDetails.payment_method !== PaymentMethods.cash && (React.createElement("span", { className: styles.transactionTitle },
                        "Transaction ID: ",
                        orderDetails.payment_transaction_id)),
                    orderDetails.order_type === OrderType.delivery ? (order.status in inProgressStates && (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { marginTop: "0.6rem" } }),
                        React.createElement("span", { className: styles.arrivalTitle },
                            "Estimated Delivery time:",
                            " ",
                            Number(estimatedDeliveryArrival.toFixed(2)) || 0,
                            " minutes")))) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { marginTop: "0.6rem" } }),
                        React.createElement("span", { className: styles.arrivalTitle },
                            "Estimated arrival time:",
                            " ",
                            Number(estimatedCustomerArrival.toFixed()) || 0,
                            " minutes"),
                        React.createElement("span", { className: styles.arrivalTitle },
                            "Avg. preparation time: ",
                            branchInfo.pickup_prep_min || 0,
                            " min ~",
                            " ",
                            branchInfo.pickup_prep_max || 0,
                            " min"))),
                    order.dms_order_status && (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { marginTop: "0.6rem" } }),
                        React.createElement("span", { className: styles.arrivalTitle },
                            "Fleet order status : ",
                            dmsStatuses[order.dms_order_status]),
                        order.dms_order_status === DMSOrderStatus.rejected && (React.createElement("span", { className: styles.arrivalTitle },
                            "Rejection reason : ",
                            order.dms_order_status_reason)))))),
            React.createElement("div", { className: styles.helper },
                typeof order.cashier_code !== "number" &&
                    order.routing_method !== RoutingMethodPOS.pos && (React.createElement("button", { onClick: goToBranchCashierApp, className: styles.goToCashier, disabled: !canOpenCashier },
                    React.createElement("img", { src: require("../../assets/share.svg"), alt: "", style: {
                            width: "1.6rem",
                            height: "1.6rem",
                            margin: "0 0.4rem",
                        } }),
                    "Open cashier live preview")),
                (branch_availability_status === AvailabilityStatus.disconnected ||
                    branch_availability_status === AvailabilityStatus.manual) && (React.createElement("div", { className: styles.onManualWrapper },
                    React.createElement("span", { className: styles.onManualTitle }, "Order was manually dispatched"),
                    branch_availability_status === AvailabilityStatus.manual ? (React.createElement("button", { className: styles.orderDispatchingBtn, onClick: setManualDispatching }, changeBrachAvailabilityLoading === LoadingStatus.loading ? (React.createElement(ClipLoader, { size: 25, color: "#ffffff" })) : ("Disconnect branch"))) : (branch_availability_status ===
                        AvailabilityStatus.disconnected && (React.createElement("div", { className: styles.branchDisconnected },
                        React.createElement("img", { className: styles.branchDisconnectedIcon, src: require("../../assets/tick.svg") }),
                        React.createElement("span", { className: styles.branchDisconnectedTitle }, "Branch disconnected")))))))),
        React.createElement("div", { className: styles.orderDetailsWrapper }),
        loadingStatus === "success" ? (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.detailsWrapper) },
            React.createElement("div", { className: styles.orderTrackingWrapper },
                React.createElement(OrderTracking, { isMobile: isMobile, order_type: orderDetails.order_type, order_id: order.id, status: status, courier_name: (_e = (_d = order) === null || _d === void 0 ? void 0 : _d.courier_info) === null || _e === void 0 ? void 0 : _e.full_name, routingMethod: order.routing_method }),
                React.createElement("div", { style: { margin: "0 1rem" } }),
                React.createElement("div", { className: styles.typeWrapper },
                    order.ordering_source && (React.createElement(OrderingSource, { order_type: orderDetails.order_type, ordering_source: order.ordering_source })),
                    order.routing_method && (React.createElement(RoutingMethod, { routingMethod: order.routing_method, pos_type: order.pos_type, cashierCode: order.cashier_code })))),
            isMobile ? (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { margin: "0 1rem" } }),
                React.createElement(OrderInDetails, { isMobile: isMobile, isCallCenter: order.routing_method === "call_center", customer_name: customerInfo.customer.name, call_store_to_cancel_with_penalty: order.call_store_to_cancel_with_penalty, routing_method: order.routing_method, branch_id: branchInfo.id, store_id: storeInfo.id, no_show: customer_no_show, order_id: id, orderDetails: orderDetails, total_invoice: addDeliveryFees(order.total_invoices || 0, order), currency: currency, promotion: orderDetails.promotion, merchantVisibility: orderDetails.promo_merchant_visibility, total_invoices_without_promo_code: addDeliveryFees(orderDetails.total_invoices_without_promo_code, order), delivery_method: order.delivery_method, cashierCode: order.cashier_code, dispatched_to_third_party_delivery: order.dispatched_to_third_party_delivery, created_at: order.created_at }),
                React.createElement("div", { style: { margin: "0 1rem" } }),
                React.createElement(OrderInfo, { isMobile: isMobile, order: orderDetails, delivery_time: storeInfo.delivery_time, delivery_fees: storeInfo.delivery_fees, orderType: orderDetails.order_type, address: address, BranchInfo: branchInfo, CustomerInfo: customerInfo, area: customerInfo.customer.area, country: customerInfo.customer.country, district_ar_name: orderDetails.district_ar_name, district_en_name: orderDetails.district_en_name, sub_district_ar_name: orderDetails.sub_district_ar_name, city_en_name: address.delivery_zone.city_en_name, sub_district_en_name: orderDetails.sub_district_en_name, government: customerInfo.customer.government, store: storeInfo, customer_country: country, currency: currency, comment: orderDetails.comment, delivery_method: order.delivery_method, courier_info: order.courier_info, tracking_url: order.tracking_url, tracking_id: order.dms_order_id }))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { margin: "0 1rem" } }),
                React.createElement(OrderInfo, { order: orderDetails, delivery_time: storeInfo.delivery_time, delivery_fees: storeInfo.delivery_fees, orderType: orderDetails.order_type, address: address, BranchInfo: branchInfo, CustomerInfo: customerInfo, area: customerInfo.customer.area, country: customerInfo.customer.country, district_ar_name: orderDetails.district_ar_name, district_en_name: orderDetails.district_en_name, sub_district_ar_name: orderDetails.sub_district_ar_name, city_en_name: address.delivery_zone.city_en_name, sub_district_en_name: orderDetails.sub_district_en_name, government: customerInfo.customer.government, store: storeInfo, customer_country: country, currency: currency, comment: orderDetails.comment, delivery_method: order.delivery_method, courier_info: order.courier_info, tracking_url: order.tracking_url, tracking_id: order.dms_order_id }),
                React.createElement("div", { style: { margin: "0 1rem" } }),
                React.createElement(OrderInDetails, { isCallCenter: order.routing_method === "call_center", customer_name: customerInfo.customer.name, call_store_to_cancel_with_penalty: order.call_store_to_cancel_with_penalty, routing_method: order.routing_method, branch_id: branchInfo.id, store_id: storeInfo.id, no_show: customer_no_show, order_id: id, orderDetails: orderDetails, total_invoice: addDeliveryFees(order.total_invoices || 0, order), currency: currency, promotion: orderDetails.promotion, merchantVisibility: orderDetails.promo_merchant_visibility, total_invoices_without_promo_code: addDeliveryFees(orderDetails.total_invoices_without_promo_code, order), delivery_method: order.delivery_method, cashierCode: order.cashier_code, dispatched_to_third_party_delivery: order.dispatched_to_third_party_delivery, created_at: order.created_at }))))) : (React.createElement("div", { style: {
                display: "flex",
                top: "20rem",
                position: "relative",
            } },
            React.createElement(DotLoader, { color: "#f09440" })))));
};
export default hot(module)(OrderDetails);
