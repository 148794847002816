import { payload, action } from "ts-action";
import { getBranchInfoAction, getCustomerInfoAction, getOrderDetailsAction, getStoreInfoAction, getAssignedCashierAction, ACTION_EDIT_ORDER_DETAILS, } from "../../constants/index";
export var getBranchInfo = action(getBranchInfoAction.requested, payload());
export var getBranchInfoSuccess = action(getBranchInfoAction.fulfilled, payload());
export var getBranchInfoFailure = action(getBranchInfoAction.rejected, payload());
export var getCustomerInfo = action(getCustomerInfoAction.requested, payload());
export var getCustomerInfoSuccess = action(getCustomerInfoAction.fulfilled, payload());
export var getCustomerInfoFailure = action(getCustomerInfoAction.rejected, payload());
// order details
export var getOrderDetails = action(getOrderDetailsAction.requested, payload());
export var getOrderDetailsSuccess = action(getOrderDetailsAction.fulfilled, payload());
export var getOrderDetailsFailure = action(getOrderDetailsAction.rejected, payload());
export var editOrderDetails = action(ACTION_EDIT_ORDER_DETAILS, payload());
export var getStoreInfo = action(getStoreInfoAction.requested, payload());
export var getStoreInfoFailure = action(getStoreInfoAction.rejected, payload());
export var getStoreInfoSuccess = action(getStoreInfoAction.fulfilled, payload());
export var getAssignedCashier = action(getAssignedCashierAction.requested, payload());
export var getAssignedCashierSuccess = action(getAssignedCashierAction.fulfilled, payload());
export var getAssignedCashierFailure = action(getAssignedCashierAction.rejected, payload());
