import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
var SingleItem = function (props) {
    var itemName = props.itemName, itemNum = props.itemNum, itemPrice = props.itemPrice, modifiers = props.modifiers, currency = props.currency, category_ar_name = props.category_ar_name, category_en_name = props.category_en_name, isMobile = props.isMobile, comment = props.comment;
    var modifiersItems = modifiers.map(function (it, index) {
        return (React.createElement("div", { className: styles.mod, key: index },
            React.createElement("div", { className: styles.whiteSpace }),
            React.createElement("div", { className: styles.modNames },
                React.createElement("p", { className: styles.extras },
                    it.en_name,
                    " - ",
                    it.ar_name),
                it.modifiers.map(function (ite, index) { return (React.createElement("span", { key: index, className: styles.itemSpan }, ite.name.trim() ? ite.name : ite.en_name + " - " + ite.ar_name)); })),
            React.createElement("div", { className: styles.modPrices },
                React.createElement("p", { className: styles.extrasSpace }),
                it.modifiers.map(function (ite, index) { return (React.createElement("span", { className: styles.itemPriceSpan, key: index },
                    ite.price,
                    " ",
                    currency)); }))));
    });
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileItemWrapper : styles.itemWrapper) },
        React.createElement("div", { className: styles.itemHeaders },
            React.createElement("span", { className: styles.itemNumber },
                "x",
                itemNum),
            React.createElement("div", { className: styles.content },
                React.createElement("span", { className: styles.header }, itemName)),
            React.createElement("div", { className: styles.price },
                React.createElement("span", null,
                    itemPrice,
                    " ",
                    currency))),
        category_ar_name !== null && category_en_name !== null && (React.createElement("div", { className: styles.categoryWrapper },
            React.createElement("span", { className: styles.category }, category_en_name),
            " ",
            React.createElement("span", { className: styles.separator }, "-"),
            React.createElement("span", { className: styles.category }, category_ar_name))),
        modifiersItems,
        comment && (React.createElement("div", { className: styles.commentContainer },
            React.createElement("span", { className: styles.commentTitle }, "Comment"),
            React.createElement("span", { className: styles.commentSubTitle }, comment)))));
};
export default hot(module)(SingleItem);
