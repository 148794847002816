import axios from "../axios";
import { OrderStatus } from "../../types";
export var changeOrderStatus = function (token, cashier_id, order_id, status) {
    return axios.put("/api/watchers/pickup-orders/" + status.replace(/_/g, '-') + "-order-status/" + order_id, {
        cashier_id: cashier_id
    }, {
        headers: {
            token: token
        }
    });
};
export var markAsReadyForPickupAPI = function (token, data) {
    return changeOrderStatus(token, data.cashier_id, data.order_id, OrderStatus.ready_for_pickup);
};
