import axios from './axios';
import * as Requests from './requests';
export var regrantPointsApi = function (token, payload) {
    return axios.post(Requests.regrantingPointsURL, payload, {
        headers: {
            token: token
        },
        baseURL: process.env.ENDPOINT
    });
};
