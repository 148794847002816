var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from "redux-saga/effects";
import React from "react";
import { acceptOrderAction } from "../../constants";
import { editOrderStatus } from "../../axios/editOrderStatus";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import * as actions from "../actions/order-status-action";
import { acceptOrderFailure, acceptOrderSuccess, } from "../actions/order-status-action";
import { getAssignedCashier, getOrderDetails, } from "../actions/get-order-details-action";
import { canAcceptOrRejectOrder, getOrderStatus } from "./lib";
import { getOrder } from "../actions/order-action";
import { toast } from "react-toastify";
var actionType = union(actions.acceptOrder);
function acceptOrderSaga(action) {
    var token, _a, cashier_id, id, status, res, e_1;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _e.sent();
                _a = action.payload, cashier_id = _a.cashier_id, id = _a.id;
                _e.label = 2;
            case 2:
                _e.trys.push([2, 16, , 18]);
                return [4 /*yield*/, call(getOrderStatus, id)];
            case 3:
                status = _e.sent();
                if (!canAcceptOrRejectOrder(status)) return [3 /*break*/, 11];
                return [4 /*yield*/, call(editOrderStatus, token, cashier_id, id)];
            case 4:
                res = _e.sent();
                return [4 /*yield*/, put(acceptOrderSuccess(res.data))];
            case 5:
                _e.sent();
                if (!(res.status === 200)) return [3 /*break*/, 10];
                return [4 /*yield*/, put(getAssignedCashier(id))];
            case 6:
                _e.sent();
                return [4 /*yield*/, put(actions.orderStatus(id))];
            case 7:
                _e.sent();
                return [4 /*yield*/, put(getOrderDetails({ id: id }))];
            case 8:
                _e.sent();
                return [4 /*yield*/, put(getOrder(id))];
            case 9:
                _e.sent();
                _e.label = 10;
            case 10: return [3 /*break*/, 15];
            case 11: return [4 /*yield*/, put(getOrderDetails({ id: id }))];
            case 12:
                _e.sent();
                return [4 /*yield*/, put(getOrder(id))];
            case 13:
                _e.sent();
                return [4 /*yield*/, put(actions.orderStatus(id))];
            case 14:
                _e.sent();
                _e.label = 15;
            case 15: return [3 /*break*/, 18];
            case 16:
                e_1 = _e.sent();
                return [4 /*yield*/, put(acceptOrderFailure(e_1))];
            case 17:
                _e.sent();
                if (e_1.response.data.message && e_1.response.status === 500) {
                    toast.error(React.createElement("span", { style: { fontSize: "1.6rem" } }, (_d = (_c = (_b = e_1) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message));
                }
                else {
                    toast.error(React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                        React.createElement("span", { style: { fontSize: "1.6rem" } }, "\"Call the branch to accept this order"),
                        React.createElement("span", { style: { fontSize: "1.6rem" } }, " \u0627\u062A\u0635\u0644 \u0628\u0627\u0644\u0645\u0637\u0639\u0645 \u0644\u0642\u0628\u0648\u0644 \u0627\u0644\u0637\u0644\u0628\"")));
                }
                return [3 /*break*/, 18];
            case 18: return [2 /*return*/];
        }
    });
}
export function watchAcceptOrderSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(acceptOrderAction.requested, acceptOrderSaga)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
