var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import { customNotify } from "../components/Shared-Components/Notification";
import { logout } from "../redux-store/actions/logout-actions";
import { store } from "../redux-store/configureStore";
import { login } from "./requests";
var handleSuccess = function (res) { return res; };
var handleError = function (error) {
    var isLogin = error.config.url === process.env.ADMIN_ENDPOINT + login;
    var myError = error.response;
    if (!myError) {
        // notify.hide();
        // notify.show(
        //   i18n.t(checkInternet, {
        //     ns: "axios"
        //   }),
        //   "error"
        // );
        throw error;
    }
    if (error.response.status === 401 && !isLogin) {
        store.dispatch(logout());
    }
    throw error;
};
export var createAxiosInstance = function (baseURL) {
    var instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json"
        }
    });
    instance.interceptors.response.use(handleSuccess, handleError);
    instance.interceptors.request.use(function (req) {
        if (!window.navigator.onLine) {
            customNotify.networkConnection("Check your internet connection");
        }
        return window.navigator.onLine ? __assign({}, req) : null;
    });
    return instance;
};
export default createAxiosInstance(process.env.PICKUP_ENDPOINT);
