import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRecoilState } from "recoil";
import { navDrawerAtom } from "../components/atoms";
import { loadableWithBounce } from "../components/LoadableRoute";
import ScrollToTop from "../components/Scroller/index";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
var drawerWidth = "21.4rem";
var useStyles = makeStyles(function (theme) { return ({
    content: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
}); });
var CallsLogs = loadableWithBounce(function () {
    return import("../components/CallsLogs/index");
});
var Order = loadableWithBounce(function () { return import("../components/Reviews/index"); });
var WatchTower = loadableWithBounce(function () {
    return import("../components/BranchStatus/index");
});
var OrderDetails = loadableWithBounce(function () {
    return import("../components/OrderDetails/index");
});
var UserFraud = loadableWithBounce(function () { return import("../components/UserFraud"); });
var WebPortalSupport = loadableWithBounce(function () {
    return import("../components/WebPortalSupport/index");
});
var UserProfile = loadableWithBounce(function () {
    return import("../components/UserProfile/index");
});
var AuthenticatedRoutes = function () {
    var _a;
    React.useEffect(function () {
        Notification.requestPermission().catch(function () {
            alert("\u0647\u0648 \u0643\u062F\u0627 \u0643\u0645\u0627 \u064A\u0628\u062F\u0648 \u0641\u064A\u0647 \u062D\u062F \u063A\u062F\u0631 \u0628\u064A\u0627 \u0648\u0644\u0645 \u064A\u0633\u0645\u062D \u0644\u064A \u0628\u0623\u0646 \u0623\u0646\u0634\u0631 \u0627\u0644\u062A\u0646\u0628\u064A\u0647\u0627\u062A\n      \u0644\u062A\u062C\u0631\u0628\u0629 \u0639\u0633\u0644\u064A\u0629 \u0642\u0645 \u0628\u062A\u0641\u0639\u064A\u0644 \u0627\u0644\u062A\u0646\u0628\u064A\u0647\u0627\u062A \u0644\u064A\u0635\u0644\u0643 \u0643\u0644 \u062C\u062F\u064A\u062F \u064A\u0627 \u0648\u062D\u0634!");
        });
    }, []);
    var classes = useStyles();
    var _b = useRecoilState(navDrawerAtom), navDrawerState = _b[0], setNavDrawerState = _b[1];
    var isOpen = navDrawerState.isOpen;
    return (React.createElement(ScrollToTop, null,
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("div", { className: clsx(classes.content, (_a = {},
                    _a[classes.contentShift] = isOpen,
                    _a)) },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/", render: function () { return React.createElement(Redirect, { to: "/wall" }); } }),
                    React.createElement(Route, { path: "/calls-logs", component: CallsLogs }),
                    React.createElement(Route, { exact: true, path: "/wall", component: Order }),
                    React.createElement(Route, { path: "/watch-tower", component: WatchTower }),
                    React.createElement(Route, { path: "/wall/order-details/:id", component: OrderDetails }),
                    React.createElement(Route, { path: "/settings/web-portal-support", component: WebPortalSupport }),
                    React.createElement(Route, { path: "/fraud-center", component: UserFraud }),
                    React.createElement(Route, { path: "/user-profile/:phone_number", component: UserProfile }))))));
};
export default AuthenticatedRoutes;
