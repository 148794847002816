var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getCountriesAction } from "../../constants";
import { getCountriesSuccess } from "../actions/get-countries-actions";
export var countriesReducer = withLoadingReducer(reducer({
    countries: [],
    countriesById: {}
}, on(getCountriesSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { countries: payload.map(function (a) { return a.ISO_code; }), countriesById: payload.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.ISO_code] = next, _a)));
        }, {}) }));
})), getCountriesAction);
