var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useMemo } from "react";
import { CustomDropDownSelect } from "../CustomDropDownSelect/index";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import { UserSearchBox } from "./UserSearchBox";
import { useRecoilState } from "recoil";
import { navDrawerAtom, ordersFilterAtom } from "../atoms";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "@material-ui/core";
import { FilterList as FilterIcon, Menu as MenuIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
var languages = ["ar", "en"];
var images = {
    en: require("../../assets/en-flag.svg"),
    ar: require("../../assets/ar-flag.svg"),
};
var onChangeLanguage = function (lng) {
    i18n.changeLanguage(lng);
    setTimeout(function () { return location.reload(); });
};
var LanguageToggle = function (_a) {
    var language = _a.language;
    var t = useTranslation("switchLanguage").t;
    return (React.createElement("div", { className: styles.switchLanguageToggleWrapper },
        React.createElement("div", { className: styles.languageWrapper },
            React.createElement("img", { className: styles.languageImage, src: images[language] }),
            React.createElement("span", { className: styles.languageLabel }, t(language))),
        React.createElement("div", { className: styles.arrowsWrapper },
            React.createElement(ArrowUp, null),
            React.createElement(ArrowDown, null))));
};
var LanguageSelectItem = function (_a) {
    var language = _a.language;
    var t = useTranslation("switchLanguage").t;
    return (React.createElement("div", { className: styles.languageSelectIem },
        React.createElement("div", { className: styles.languageWrapper },
            React.createElement("img", { className: styles.languageImage, src: images[language] }),
            React.createElement("span", { className: styles.languageLabel }, t(language)))));
};
var renderLanguageToggle = function (lng) { return React.createElement(LanguageToggle, { language: lng }); };
var renderLanguageItem = function (lng) { return React.createElement(LanguageSelectItem, { language: lng }); };
var SwitchLanguage = function () {
    var initialLang = useMemo(function () { return i18n.language.split("-")[0]; }, [
        i18n.language,
    ]);
    return (React.createElement("span", null,
        React.createElement(CustomDropDownSelect, { initialValue: initialLang, options: languages, onChange: onChangeLanguage, renderSelectItem: renderLanguageItem, renderSelectToggle: renderLanguageToggle })));
};
var Header = function () {
    var _a = React.useState(18), hamburgerSize = _a[0], setHamburgerSize = _a[1];
    var _b = useRecoilState(navDrawerAtom), navDrawerState = _b[0], setNavDrawerState = _b[1];
    var isOpen = navDrawerState.isOpen;
    var history = useHistory();
    var isMidScreen = useMediaQuery({ maxWidth: 1440 });
    var isTablet = useMediaQuery({ maxWidth: 1024 });
    var isMobile = useMediaQuery({ maxWidth: 767 });
    var isSmallScreen = useMediaQuery({ maxWidth: 414 });
    React.useEffect(function () {
        if (isSmallScreen) {
            setHamburgerSize(8);
        }
        else if (isMobile) {
            setHamburgerSize(12);
        }
        else if (isTablet) {
            setHamburgerSize(16);
        }
    }, [isMidScreen, isTablet, isMobile, isSmallScreen]);
    var handleDrawerOpen = function () {
        setNavDrawerState(function (prevState) { return (__assign(__assign({}, prevState), { isOpen: !prevState.isOpen })); });
    };
    var _c = useRecoilState(ordersFilterAtom), ordersFilterDrawerState = _c[0], setOrdersFilterDrawerState = _c[1];
    var isOrdersFilterDrawerOpen = ordersFilterDrawerState.isOpen;
    var handleOrdersFilterClick = function () {
        setOrdersFilterDrawerState(function (prevState) { return (__assign(__assign({}, prevState), { isOpen: !isOrdersFilterDrawerOpen })); });
    };
    var isHomePage = history.location.pathname.includes("wall");
    return (React.createElement("header", { className: styles.header },
        (isTablet || isMobile || isSmallScreen) && (React.createElement(IconButton, { onClick: handleDrawerOpen },
            React.createElement(MenuIcon, { style: { fontSize: hamburgerSize } }))),
        React.createElement("div", { className: styles.search },
            React.createElement("img", { className: styles.logo, src: require("../../assets/group-24.svg") })),
        React.createElement("div", { className: styles.headerRightSide },
            React.createElement(UserSearchBox, null),
            (isTablet || isMobile || isSmallScreen) && isHomePage && (React.createElement(IconButton, { onClick: handleOrdersFilterClick },
                React.createElement(FilterIcon, { style: { fontSize: hamburgerSize } }))))));
};
export default hot(module)(Header);
