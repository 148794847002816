var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { getUserProfileApi } from "../../axios/get-user-profile";
import { customNotify } from "../../components/Shared-Components/Notification";
import { getUserProfileAction } from "../../constants";
import { getUserProfile, getUserProfileFailure, getUserProfileSuccess, } from "../actions/user-profile-actions";
import { selectToken } from "../selectors";
var actionType = union(getUserProfile);
function getUserProfileSaga(_a) {
    var token, res, error_1;
    var _b = _a.payload, phone_number = _b.phone_number, history = _b.history;
    var _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                _m.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _m.sent();
                return [4 /*yield*/, call(getUserProfileApi, token, phone_number)];
            case 2:
                res = _m.sent();
                if (!(res.status === 200)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(getUserProfileSuccess(res.data))];
            case 3:
                _m.sent();
                // pushing to user profile and keeping phone number for future refresh
                if (history) {
                    // => if we request change from header input , we will pass useHistory().push / history.push function
                    history.push("/user-profile/" + phone_number);
                }
                return [3 /*break*/, 6];
            case 4:
                if (!(res.status === 204)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(getUserProfileFailure(new Error()))];
            case 5:
                _m.sent();
                customNotify.error("User not found " + String.fromCodePoint(0x1f622));
                _m.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7:
                error_1 = _m.sent();
                if ((_e = (_d = (_c = error_1) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message) {
                    customNotify.error((_h = (_g = (_f = error_1) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.message);
                }
                return [4 /*yield*/, put(getUserProfileFailure((_l = (_k = (_j = error_1) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.data, (_l !== null && _l !== void 0 ? _l : { code: "", message: "Connection error" }))))];
            case 8:
                _m.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function watchGetUserProfileSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getUserProfileAction.requested, getUserProfileSaga)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
