import * as React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, } from "reactstrap";
import * as styles from "./styles.module.sass";
import { useToggleState } from "../../../components/hooks/useToggleState";
import { useEffect, useState } from "react";
var SelectItem = function (_a) {
    var item = _a.item;
    return React.createElement("div", { className: styles.item }, item.label);
};
var defaultRenderSelectToggle = function (item) { return item.label; };
var SelectItemWrapper = function (props) {
    var onSelect = function () { return props.onSelect(props.value); };
    return React.createElement(DropdownItem, { onClick: onSelect }, props.children);
};
export var CustomDropDownSelect = function (props) {
    var options = props.options, className = props.className, renderSelectItem = props.renderSelectItem, disabled = props.disabled, renderSelectToggle = props.renderSelectToggle, onChange = props.onChange, initialValue = props.initialValue;
    var _a = useState(initialValue || options[0]), value = _a[0], selectValue = _a[1];
    var _b = useToggleState(false), isOpen = _b[0], toggleMenu = _b[1];
    useEffect(function () {
        if (initialValue) {
            selectValue(initialValue);
        }
    }, [initialValue]);
    var handleChange = function (v) {
        selectValue(v);
        onChange(v);
    };
    var defaultRenderSelectItem = function (option) { return (React.createElement(SelectItem, { item: option })); };
    return (React.createElement("div", { className: (className || "") + " " + styles.dropDownPart },
        React.createElement(Dropdown, { isOpen: isOpen, toggle: toggleMenu },
            React.createElement(DropdownToggle, { disabled: disabled, className: styles.switch }, (renderSelectToggle || defaultRenderSelectToggle)(value)),
            React.createElement(DropdownMenu, { className: styles.menuWrapper }, options.map(function (option, index) { return (React.createElement(SelectItemWrapper, { key: index, value: option, onSelect: handleChange }, (renderSelectItem || defaultRenderSelectItem)(option))); })))));
};
