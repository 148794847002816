import { useMutation } from "react-query";
import { sendingCompensationalPointsApi } from "../axios/sendingCompensationalPointApi";
import { useSelect } from "../helper/use-select";
export var useSendCompensationalPoints = function () {
    var token = useSelect(function (state) { return state.authReducer; }).token;
    return useMutation(function (data) {
        return sendingCompensationalPointsApi(token, data);
    }, {
        retry: 3,
    });
};
