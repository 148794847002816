import { getOrdersAction, checkOrdersUpdateAction, ACTION_INCOMING_ORDER_STATUS } from "../../constants/index";
import { payload, action } from "ts-action";
export var getOrders = action(getOrdersAction.requested, payload());
export var getOrdersSuccess = action(getOrdersAction.fulfilled, payload());
export var getOrdersFailure = action(getOrdersAction.rejected, payload());
export var notifyNewOrder = action("ACTION_NOTIFY_NEW_ORDER", payload());
export var checkOrdersUpdate = action(checkOrdersUpdateAction.requested);
export var checkOrdersUpdateSuccess = action(checkOrdersUpdateAction.fulfilled, payload());
export var checkOrdersUpdateFailure = action(checkOrdersUpdateAction.rejected);
// if an order exceeds 3 minutes without reaching the `accepted` OrderStatus
export var notifyLateOrder = action("ACTION_NOTIFY_LATE_ORDER", payload());
export var incomingOrderStatus = action(ACTION_INCOMING_ORDER_STATUS, payload());
