import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import Modal from "@material-ui/core/Modal";
import GenericButtonWithIcon from "../../../Shared-Components/GenericButtonWithIcon";
import { useDispatch } from "react-redux";
import { refundOrder } from "../../../../redux-store/actions/order-status-action";
var RefundModal = function (_a) {
    var onClose = _a.onClose, open = _a.open, order_id = _a.order_id, customer_name = _a.customer_name;
    var dispatch = useDispatch();
    var refund = useCallback(function () {
        dispatch(refundOrder({ order_id: order_id }));
        onClose();
    }, [order_id]);
    return (React.createElement(Modal, { className: styles.main, open: open, onClose: onClose },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.headerContent },
                React.createElement("span", { className: styles.title },
                    "Refund \u201C",
                    customer_name,
                    "\u201D"),
                React.createElement("div", { className: styles.alarmWrapper },
                    React.createElement("img", { src: require("../../../../assets/alarm.svg") }),
                    React.createElement("span", { className: styles.alarmTitle }, "Kindly note that the refunding action can\u2019t be undone"))),
            React.createElement("div", { className: styles.btnWrapper },
                React.createElement(GenericButtonWithIcon, { className: styles.cancel, onClick: onClose, label: "Cancel" }),
                React.createElement(GenericButtonWithIcon, { onClick: refund, className: styles.refund, label: "Refund" })))));
};
export default hot(module)(RefundModal);
