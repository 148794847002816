var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { withLoadingReducer } from "./withLoadingState";
import { getBranchInfoAction } from "../../constants";
import { reducer, on } from "ts-action";
import { getBranchInfoSuccess } from "../actions/get-order-details-action";
import { clearPrevOrderDetailsAction } from "../../constants/index";
var initialState = {
    branchInfo: {
        address: "",
        created_at: 0,
        detailed_address: "",
        id: "",
        lat: 0,
        lng: 0,
        name: "",
        numbers: [],
        phone: "",
        store_id: "",
        type: 0,
        updated_at: 0,
        pickup_prep_max: 0,
        pickup_prep_min: 0,
        delivery_zones: [],
        delivery_fees: 0,
        app_version: "",
    },
};
export var BranchInfoReducer = withLoadingReducer(reducer(initialState, on(getBranchInfoSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { branchInfo: payload }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { branchInfo: initialState.branchInfo })); })), getBranchInfoAction);
