import React, { useMemo } from "react";
import { ClipLoader } from "react-spinners";
import { Modal } from "@material-ui/core";
import { useProcessImageResource } from "../../../../../../../BranchStatus/useProcessImageResource";
import styles from "./styles.module.sass";
import { useSelect } from "../../../../../../../../helper/use-select";
var OrderConfirmationPopup = function (_a) {
    var open = _a.open, avatar = _a.avatar, onClose = _a.onClose, currency = _a.currency, total_invoices = _a.total_invoices, status = _a.status, description = _a.description, user_name = _a.user_name, onConfirm = _a.onConfirm, disabledConfirmationButton = _a.disabledConfirmationButton;
    var customer_image = useProcessImageResource(avatar, process.env.ENDPOINT);
    var refund_summary = useSelect(function (state) { return ({
        refund_summary: state.refundSummaryReducer,
        orderDetails: state.orderDetailsReducer.orderDetails,
    }); }).refund_summary;
    var customerRefundValue = useMemo(function () {
        return (refund_summary.order_total_invoices *
            refund_summary.customer_refund_percentage) /
            100;
    }, [refund_summary]);
    return (React.createElement(Modal, { open: open, onClose: onClose },
        React.createElement("div", { className: styles.modalWrapper },
            React.createElement("div", { className: styles.modalBody },
                React.createElement("div", { className: styles.header }),
                React.createElement("div", { className: styles.body },
                    React.createElement("img", { src: (customer_image !== null && customer_image !== void 0 ? customer_image : require("../../../../../../../../assets/temp-user.svg")), className: styles.image }),
                    React.createElement("h2", { className: styles.name },
                        user_name,
                        " "),
                    React.createElement("span", { className: styles.type }, description),
                    React.createElement("span", { className: styles.amountWrapper },
                        React.createElement("img", { className: styles.icon, src: require("../../../../../../../../assets/icon_discount.svg") }),
                        refund_summary.order_total_invoices > 0 ? (React.createElement("span", { className: styles.amount },
                            customerRefundValue,
                            " ",
                            currency)) : (React.createElement("span", { className: styles.amount },
                            total_invoices,
                            " ",
                            currency)))),
                React.createElement("div", { className: styles.footer },
                    React.createElement("button", { className: styles.cancel, onClick: onClose }, "Cancel"),
                    React.createElement("button", { className: styles.send, disabled: disabledConfirmationButton, onClick: onConfirm }, status === "loading" ? (React.createElement(ClipLoader, { size: 25, color: "#fff" })) : ("Confirm")))))));
};
export default OrderConfirmationPopup;
