import React from "react";
import styles from "./styles.module.sass";
var OrderCancellationRefundDetails = function (_a) {
    var order_total_invoices = _a.order_total_invoices, refunded_target = _a.refunded_target, refund_percentage = _a.refund_percentage, icon = _a.icon, currency = _a.currency;
    return (React.createElement("div", { className: styles.cancelDetails },
        React.createElement("div", { className: styles.iconRoundWrapper },
            React.createElement("img", { src: icon, alt: "clock icon" })),
        React.createElement("div", null,
            React.createElement("p", null,
                refunded_target,
                " should be refunded with \u00A0",
                React.createElement("span", null, " " + refund_percentage + "%   "),
                " \u00A0 of order = \u00A0",
                React.createElement("span", null, " " + ((order_total_invoices * refund_percentage) / 100).toFixed(2) + " ",
                    " ",
                    currency)))));
};
export default OrderCancellationRefundDetails;
