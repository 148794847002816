import { CURRENCIES } from "./currencies";
import { COUNTRIES } from "./countries";
export var getCountriesMetaData = function () {
    return import("../../static/shopx-countries").then(function (_a) {
        var countries = _a.default;
        return countries;
    });
};
export var getShopXCountries = function () {
    return import("../../static/shopx-countries").then(function (_a) {
        var countries = _a.default;
        return countries;
    });
};
export var getLocalizedCurrency = function (lang, iso_code) {
    // @ts-ignore
    var langCurrencies = CURRENCIES[lang.slice(0, 2)];
    // @ts-ignore
    return langCurrencies[iso_code] || CURRENCIES.en[iso_code];
};
export var getLocalizedCountry = function (lang, iso_code) {
    // @ts-ignore
    var langCountries = COUNTRIES[lang.slice(0, 2)];
    // @ts-ignore
    return langCountries[iso_code] || COUNTRIES.en[iso_code];
};
export var Segments;
(function (Segments) {
    Segments["high"] = "high";
    Segments["low"] = "low";
    Segments["medium"] = "medium";
    Segments["new_ordering_customer"] = "new_ordering_customer";
})(Segments || (Segments = {}));
export var userSegments = {
    high: "High-tier",
    low: "Low-tier",
    medium: "Mid-tier",
    new_ordering_customer: "New ordering customer"
};
