import axios from "./axios";
import * as Requests from "./requests";
export var getPickupReasonsApi = function (token, order_status) {
    return axios.get(Requests.pickupReasonsURL, {
        headers: {
            token: token,
        },
        params: { order_status: order_status },
    });
};
export var getDeliveryReasonsApi = function (token, order_status) {
    return axios.get(Requests.deliveryReasonsURL, {
        headers: {
            token: token,
        },
        params: { order_status: order_status },
    });
};
