var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all } from "redux-saga/effects";
import { watchLoginSaga } from "./sagas/loginSaga";
import { watchOrderTrackingSaga } from "./sagas/orderTrackingSaga";
import { watchStoreSaga } from "./sagas/storeSaga";
import { watchBranchesSaga } from "./sagas/branchesSaga";
import { watchOrdersSaga } from "./sagas/ordersSaga";
import { watchGetCountriesSaga } from "./sagas/get-countries-saga";
import { watchListPickupBranchSaga } from "./sagas/listPickupBranchSaga";
import { watchLogoutSaga } from "./sagas/logoutSaga";
import { watchGetCustomerInfoSaga } from "./sagas/customerInfoSaga";
import { watchGetBranchInfoSaga } from "./sagas/branchInfoSaga";
import { watchGetOrderDetailsSaga } from "./sagas/orderDetailsSaga";
import { watchGetStoreInfoSaga } from "./sagas/StoreInfoSaga";
import { watchOrderStatusSaga } from "./sagas/orderStatusSaga";
import { watchOrderSaga } from "./sagas/orderSaga";
import { watchCallLogsSaga } from "./sagas/callLogsSaga";
import { watchGetCashierSaga } from "./sagas/cashierSaga";
import { watchAcceptOrderSaga } from "./sagas/acceptOrderSaga";
import { watchCancelOrderSaga } from "./sagas/cancelOrderSaga";
import { watchNoShowSaga } from "./sagas/noShowSaga";
import { watchNoShowOrderStatusSaga } from "./sagas/noShowOrderStatusSaga";
import { watchReceivedContactSupportSaga } from "./sagas/receivedContactSupportSaga";
import { watchRejectOrderStatusSaga } from "./sagas/rejectOrderStatusSaga";
import { watchCheckOrdersUpdateSaga } from "./sagas/checkOrdersUpdateSaga";
import { watchNotifyNewOrderSaga } from "./sagas/notifyNewOrderSaga";
import { watchNotifyLateOrderSaga } from "./sagas/notifyLateOrderSaga";
import { watchRequestResolvedSaga } from "./sagas/requestResolvedSaga";
import { watchCancelledByBranchSaga } from "./sagas/cancelledByBranchSaga";
import { watchReceiveBranchStatusChange } from "./sagas/receiveBranchStatusChangeSaga";
import { watchReceiveContactSupport } from "./sagas/notifyHelpRequest";
import { watchRequestCashierTokenSaga } from "./sagas/requestCashierTokenSaga";
import { watchGetAddressSaga } from "./sagas/getAddressSaga";
import { watchOrderDeliveryTrackingSaga } from "./sagas/orderDeliveryTrackingSaga";
import { watchMarkOrderAsDelivered } from "./sagas/markOrderAsDeliveredSaga";
import { watchMarkOrderAsOutForDelivery } from "./sagas/markOrderAsOutForDeliverySaga";
import { watchMarkOrderAsGotPickedUp } from "./sagas/markOrderAsGotPickedUpSaga";
import { watchMarkOrderAsReadyForPickup } from "./sagas/markOrderAsReadyForPickupSaga";
import { watchBranchesStatusSaga } from "./sagas/branchesStatusSaga";
import { watchBrandFilterSaga } from "./sagas/brandFilterSaga";
import { watchBranchFilterSaga } from "./sagas/branchFilterSaga";
import { watchBrandsSaga } from "./sagas/getBrandsSaga";
import { watchAssignedCashierSaga } from "./sagas/assignedCashierSaga";
import { watchRefundOrderSaga } from "./sagas/refundOrderSaga";
import { watchGetPickupReasonsSaga } from "./sagas/getPickupReasonsSaga";
import { watchGetDeliveryReasonsSaga } from "./sagas/getDeliveryReasonsSaga";
import { watchEditOrderStatusReasonSaga } from "./sagas/editOrderStatusReasonSaga";
import { watchDispatchOrderAsReady } from "./sagas/dispatchOrderAsReadySaga";
import { watchGetPortalStoresSaga } from "./sagas/getPortalStoresSaga";
import { watchSupportPortalStoresSaga } from './sagas/supportPortalStoresSaga';
import { watchGetBranchAvailabilitySaga } from "./sagas/getBranchAvailabilitySaga";
import { watchChangeBranchAvailabilitySaga } from "./sagas/changeBranchAvailability";
import { watchGetUserProfileSaga } from './sagas/getUserProfileSaga';
import { watchRegrantPointsSaga } from "./sagas/regrantPointsSaga";
import { watchUnblockUserSaga } from "./sagas/unblockUserSaga";
import { watchReceivedNewOrderSaga } from "./sagas/recieveNewOrderSaga";
import { watchRefundPolicySaga } from "./sagas/refundPolicySaga";
import { watchRefundSummarySaga } from "./sagas/refundSummarySaga";
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    watchLoginSaga(),
                    watchGetUserProfileSaga(),
                    watchEditOrderStatusReasonSaga(),
                    watchGetDeliveryReasonsSaga(),
                    watchGetPickupReasonsSaga(),
                    watchAssignedCashierSaga(),
                    watchOrderTrackingSaga(),
                    watchStoreSaga(),
                    watchBranchesSaga(),
                    watchGetCountriesSaga(),
                    watchOrdersSaga(),
                    watchListPickupBranchSaga(),
                    watchLogoutSaga(),
                    watchGetOrderDetailsSaga(),
                    watchGetCustomerInfoSaga(),
                    watchGetBranchInfoSaga(),
                    watchGetStoreInfoSaga(),
                    watchOrderStatusSaga(),
                    watchRequestResolvedSaga(),
                    watchCancelledByBranchSaga(),
                    watchRegrantPointsSaga(),
                    watchUnblockUserSaga(),
                    watchOrderSaga(),
                    watchCallLogsSaga(),
                    watchGetCashierSaga(),
                    watchAcceptOrderSaga(),
                    watchCancelOrderSaga(),
                    watchNoShowSaga(),
                    watchNoShowOrderStatusSaga(),
                    watchReceivedContactSupportSaga(),
                    watchRejectOrderStatusSaga(),
                    watchCheckOrdersUpdateSaga(),
                    watchNotifyNewOrderSaga(),
                    watchNotifyLateOrderSaga(),
                    watchReceiveBranchStatusChange(),
                    watchReceiveContactSupport(),
                    watchRequestCashierTokenSaga(),
                    watchGetAddressSaga(),
                    watchOrderDeliveryTrackingSaga(),
                    watchMarkOrderAsDelivered(),
                    watchMarkOrderAsOutForDelivery(),
                    watchMarkOrderAsGotPickedUp(),
                    watchRefundOrderSaga(),
                    watchMarkOrderAsReadyForPickup(),
                    watchBrandsSaga(),
                    watchBranchesStatusSaga(),
                    watchBrandFilterSaga(),
                    watchBranchFilterSaga(),
                    watchDispatchOrderAsReady(),
                    watchGetPortalStoresSaga(),
                    watchSupportPortalStoresSaga(),
                    watchGetBranchAvailabilitySaga(),
                    watchChangeBranchAvailabilitySaga(),
                    watchRefundPolicySaga(),
                    watchRefundSummarySaga(),
                    watchReceivedNewOrderSaga(),
                    watchRefundPolicySaga()
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
