import React from "react";
import { OrderingStatus } from "../../../../../redux-store/actions/socket-actions";
export var PickupIcon = function (_a) {
    var pickup_status = _a.pickup_status;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 32 32" },
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("rect", { width: "32", height: "32", fill: pickup_status === OrderingStatus.enabled ? "#38c172" : "#db3030", rx: "16" }),
            React.createElement("g", null,
                React.createElement("path", { fill: "#fff", fillRule: "nonzero", d: "M12.3 7.025h-1.675v-.2c0-1.075-.875-1.95-1.95-1.95s-1.95.875-1.95 1.95v.2H4.9c-.276 0-.5.224-.5.5v3.35c0 .332.082.648.235.925H2c-.57 0-1-.398-1-.925V3.4h2.325v.5c0 .276.224.5.5.5s.5-.224.5-.5v-.5h1.9v.5c0 .276.224.5.5.5s.5-.224.5-.5v-.5h2.4v.5c0 .276.224.5.5.5s.5-.224.5-.5v-1c0-.276-.224-.5-.5-.5h-2.9v-.45C7.225.875 6.35 0 5.275 0s-1.95.875-1.95 1.95v.45H.5c-.276 0-.5.224-.5.5v7.975c0 .536.213 1.03.599 1.39.37.345.868.535 1.401.535h8.8c1.103 0 2-.897 2-2V7.525c0-.276-.224-.5-.5-.5zM4.325 1.95c0-.524.426-.95.95-.95s.95.426.95.95v.45h-1.9v-.45zm3.4 4.875c0-.524.426-.95.95-.95s.95.426.95.95v.2h-1.9v-.2zM11.8 10.8c0 .551-.449 1-1 1H6.4c-.57 0-1-.398-1-.925v-2.85h1.325v.525c0 .276.224.5.5.5s.5-.224.5-.5v-.525h1.9v.525c0 .276.224.5.5.5s.5-.224.5-.5v-.525H11.8V10.8z", transform: "translate(9.6 9.6)" })))));
};
