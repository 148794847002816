var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getBrandsAction } from "../../constants";
import { getBrandsSuccess } from "../actions/store-action";
import { selectBrandIdAction } from "../actions/branches-action";
export var brandsReducer = withLoadingReducer(reducer({
    selectBrandId: "",
    disconnectedStores: [],
    connectedStores: [],
    closedStores: [],
    disconnectedStoreById: {},
    connectedStoreById: {},
    closedStoreById: {},
}, on(getBrandsSuccess, function (state, _a) {
    var payload = _a.payload;
    if (payload.type === "inactive") {
        return __assign(__assign({}, state), { disconnectedStores: __spreadArrays(state.disconnectedStores, payload.brands.map(function (brand) { return brand.id; })), disconnectedStoreById: __assign(__assign({}, state.disconnectedStoreById), payload.brands.reduce(function (acc, next) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
            }, {})) });
    }
    else if (payload.type === "active") {
        return __assign(__assign({}, state), { connectedStores: __spreadArrays(state.connectedStores, payload.brands.map(function (brand) { return brand.id; })), connectedStoreById: __assign(__assign({}, state.connectedStoreById), payload.brands.reduce(function (acc, next) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
            }, {})) });
    }
    else {
        return __assign(__assign({}, state), { closedStores: __spreadArrays(state.closedStores, payload.brands.map(function (brand) { return brand.id; })), closedStoreById: __assign(__assign({}, state.closedStoreById), payload.brands.reduce(function (acc, next) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
            }, {})) });
    }
}), on(selectBrandIdAction, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { selectBrandId: payload }));
})), getBrandsAction);
