import * as Requests from "./requests";
import axios from "./axios";
export var RoutingMethods;
(function (RoutingMethods) {
    RoutingMethods["standalone"] = "standalone";
    RoutingMethods["call_center"] = "call_center";
    RoutingMethods["pos"] = "pos";
    RoutingMethods["all"] = "all";
})(RoutingMethods || (RoutingMethods = {}));
export var branchesStatusAPI = function (token, id) {
    return axios.get(Requests.branches(id), {
        headers: {
            token: token
        }
    });
};
