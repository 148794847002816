var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "./axios";
import * as Requests from "./requests";
export var listCallLogs = function (token, page, per_page, filters) {
    return axios.get(Requests.listCallLogs, {
        headers: {
            token: token
        },
        params: __assign({ page: page,
            per_page: per_page }, filters),
        baseURL: process.env.PICKUP_ENDPOINTS
    });
};
