import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import CustomerInformation from "./CustomerInformation";
import BrandInfo from "./BrandInfo";
import { DeliveryMethods, } from "../../../constants/types";
import CourierInformation from "./CourierInformation";
var OrderInfo = function (props) {
    var orderType = props.orderType, BranchInfo = props.BranchInfo, CustomerInfo = props.CustomerInfo, area = props.area, country = props.country, government = props.government, store = props.store, district_ar_name = props.district_ar_name, district_en_name = props.district_en_name, sub_district_ar_name = props.sub_district_ar_name, currency = props.currency, customer_country = props.customer_country, sub_district_en_name = props.sub_district_en_name, address = props.address, comment = props.comment, delivery_fees = props.delivery_fees, city_en_name = props.city_en_name, delivery_time = props.delivery_time, courier_info = props.courier_info, delivery_method = props.delivery_method, tracking_id = props.tracking_id, tracking_url = props.tracking_url, order = props.order, isMobile = props.isMobile;
    // const dispatch = useDispatch
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.wrapper) },
        React.createElement(CustomerInformation, { lat: address.delivery_address.lat, lng: address.delivery_address.lng, isMobile: isMobile, order: order, city_en_name: city_en_name, customer_country: customer_country, CustomerInfo: CustomerInfo, district_ar_name: district_ar_name, district_en_name: district_en_name, sub_district_ar_name: sub_district_ar_name, sub_district_en_name: sub_district_en_name, area: area, country: country, orderType: orderType, address: address, government: government, comment: comment }),
        delivery_method === DeliveryMethods.third_party_delivery &&
            courier_info && (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { margin: "1rem 0" } }),
            React.createElement(CourierInformation, { courier_info: courier_info, delivery_time: delivery_time, tracking_url: tracking_url, tracking_id: tracking_id }))),
        React.createElement("div", { style: { margin: "1rem 0" } }),
        React.createElement(BrandInfo, { isMobile: isMobile, address: address, currency: currency, brandInfo: BranchInfo, store: store, orderType: orderType, delivery_time: delivery_time, delivery_fees: delivery_fees })));
};
export default hot(module)(OrderInfo);
