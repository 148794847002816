import P from "pusher-js";
export var initSocket = function (token) {
    return new P(process.env.PUSHER_API_KEY, {
        authEndpoint: process.env.PICKUP_ENDPOINT + "/api/pusher/watcher/auth",
        auth: {
            headers: {
                token: token
            },
            params: {}
        },
        cluster: "eu"
    });
};
