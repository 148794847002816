var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { NEWLY_CREATED_ORDER } from "../../constants/index";
import { newlyCreatedOrder } from "../actions/socket-actions";
import { union } from "ts-action";
import { checkOrdersUpdateSuccess, incomingOrderStatus, } from "../actions/orders-action";
var actionType = union(newlyCreatedOrder);
export function receivedNewOrderSaga(_a) {
    var orderById, orders;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.ordersReducer; })];
            case 1:
                orderById = (_b.sent()).orderById;
                orders = Object.values(orderById);
                return [4 /*yield*/, put(checkOrdersUpdateSuccess(__spreadArrays([
                        __assign(__assign({}, __assign(__assign({}, payload), { customer_name: payload.customer.name })), { incoming_order_status: true })
                    ], orders)))];
            case 2:
                _b.sent();
                return [4 /*yield*/, delay(1000)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(incomingOrderStatus(false))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function watchReceivedNewOrderSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(NEWLY_CREATED_ORDER, receivedNewOrderSaga)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
