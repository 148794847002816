import { action, payload } from "ts-action";
import { orderStatusAction, acceptOrderAction, cancelOrderAction, noShowAction, noShowOrderStatusAction, rejectOrderStatusAction, cancelledByBranchAction, markOrderAsDeliveredAction, markOrderAsOutForDeliveryAction, markOrderAsReadyForPickupAction, markOrderAsGotPickedUpAction, refundOrderAction, dispatchOrderAsReadyAction, ACTION_CLEAR_PREV_STATE, ACTION_CLEAR_PREV_ORDER_TRACKING_STATE, } from "../../constants";
export var orderStatus = action(orderStatusAction.requested, payload());
export var orderStatusSuccess = action(orderStatusAction.fulfilled, payload());
export var orderStatusFailure = action(orderStatusAction.rejected, payload());
export var clearPrevOrderTrackingState = action(ACTION_CLEAR_PREV_ORDER_TRACKING_STATE);
export var clearPrevState = action(ACTION_CLEAR_PREV_STATE);
export var cancelledByBranch = action(cancelledByBranchAction.requested, payload());
export var cancelledByBranchSuccess = action(cancelledByBranchAction.fulfilled, payload());
export var cancelledByBranchFailure = action(cancelledByBranchAction.rejected, payload());
export var acceptOrder = action(acceptOrderAction.requested, payload());
export var acceptOrderSuccess = action(acceptOrderAction.fulfilled, payload());
export var acceptOrderFailure = action(acceptOrderAction.rejected, payload());
export var cancelOrder = action(cancelOrderAction.requested, payload());
export var cancelOrderSuccess = action(cancelOrderAction.fulfilled, payload());
export var cancelOrderFailure = action(cancelOrderAction.rejected, payload());
export var noShow = action(noShowAction.requested, payload());
export var noShowSuccess = action(noShowAction.fulfilled, payload());
export var noShowFailure = action(noShowAction.rejected, payload());
export var noShowOrderStatus = action(noShowOrderStatusAction.requested, payload());
export var noShowOrderStatusSuccess = action(noShowOrderStatusAction.fulfilled, payload());
export var noShowOrderStatusFailure = action(noShowOrderStatusAction.rejected, payload());
export var rejectOrderStatus = action(rejectOrderStatusAction.requested, payload());
export var rejectOrderStatusSuccess = action(rejectOrderStatusAction.fulfilled, payload());
export var rejectOrderStatusFailure = action(rejectOrderStatusAction.rejected, payload());
var changeStatusPayload = payload();
export var dispatchOrderAsReady = action(dispatchOrderAsReadyAction.requested, payload());
export var dispatchOrderAsReadySuccess = action(dispatchOrderAsReadyAction.fulfilled, payload());
export var dispatchOrderAsReadyFailure = action(dispatchOrderAsReadyAction.rejected, payload());
export var markOrderAsDelivered = action(markOrderAsDeliveredAction.requested, changeStatusPayload);
export var markOrderAsDeliveredSuccess = action(markOrderAsDeliveredAction.fulfilled, payload());
export var markOrderAsDeliveredFailure = action(markOrderAsDeliveredAction.rejected, payload());
export var markOrderAsOutForDelivery = action(markOrderAsOutForDeliveryAction.requested, changeStatusPayload);
export var markOrderAsOutForDeliverySuccess = action(markOrderAsOutForDeliveryAction.fulfilled, payload());
export var markOrderAsOutForDeliveryFailure = action(markOrderAsOutForDeliveryAction.rejected, payload());
export var markOrderAsReadyForPickup = action(markOrderAsReadyForPickupAction.requested, changeStatusPayload);
export var markOrderAsReadyForPickupSuccess = action(markOrderAsReadyForPickupAction.fulfilled, payload());
export var markOrderAsReadyForPickupFailure = action(markOrderAsReadyForPickupAction.rejected, payload());
export var markOrderAsGotPickedUp = action(markOrderAsGotPickedUpAction.requested, changeStatusPayload);
export var markOrderAsGotPickedUpSuccess = action(markOrderAsGotPickedUpAction.fulfilled, payload());
export var markOrderAsGotPickedUpFailure = action(markOrderAsGotPickedUpAction.rejected, payload());
export var refundOrder = action(refundOrderAction.requested, payload());
export var refundOrderSuccess = action(refundOrderAction.fulfilled, payload());
export var refundOrderFailure = action(refundOrderAction.rejected, payload());
