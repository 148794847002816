var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPickupReasonsActions } from "../../constants";
import { getPickupReasonsSuccess } from "../actions/reasons-action";
export var getPickupReasonsReducer = withLoadingReducer(reducer({
    pickupReasons: [],
    pickupReasonsById: {},
}, on(getPickupReasonsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { pickupReasons: payload
            .sort(function (a, b) {
            return (a.en_text && a.ar_text).localeCompare(b.en_text && b.ar_text);
        })
            .map(function (a) { return a.id; }), pickupReasonsById: payload.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
        }, {}) }));
})), getPickupReasonsActions);
