import React, { useEffect, useMemo } from "react";
import styles from "../../styles.module.sass";
import AlertIcon from "../../../../../assets/ic_alert_triangle.svg";
import { DotLoader } from "react-spinners";
import { useSelect } from "../../../../../helper/use-select";
import { getRefundSummary } from "../../../../../redux-store/actions/refundSummary-action";
import { useDispatch } from "react-redux";
import { LoadingStatus } from "../../../../../redux-store/reducers/withLoadingState";
var RefundSummary = function (_a) {
    var order_id = _a.order_id, currency = _a.currency, status = _a.status;
    var _b, _c, _d, _e, _f;
    var dispatch = useDispatch();
    var refundSummary = useSelect(function (state) { return ({
        refundSummary: state.refundSummaryReducer,
    }); }).refundSummary;
    var wronglyPenalizedOrderMessage = useMemo(function () {
        var _a;
        return ((_a = refundSummary) === null || _a === void 0 ? void 0 : _a.cancellation_type) === "without_penalty"
            ? "This order should be penalized. However, it is cancelled without a penalty."
            : "This order shouldn't be penalized. However, it is cancelled with a penalty.";
    }, [refundSummary]);
    var merchantRefundValue = useMemo(function () {
        var _a, _b;
        return ((((_a = refundSummary) === null || _a === void 0 ? void 0 : _a.order_total_invoices) * ((_b = refundSummary) === null || _b === void 0 ? void 0 : _b.merchant_refund_percentage)) /
            100).toFixed(2);
    }, [refundSummary]);
    var customerRefundValue = useMemo(function () {
        var _a, _b;
        return ((((_a = refundSummary) === null || _a === void 0 ? void 0 : _a.order_total_invoices) * ((_b = refundSummary) === null || _b === void 0 ? void 0 : _b.customer_refund_percentage)) /
            100).toFixed(2);
    }, [refundSummary]);
    useEffect(function () {
        dispatch(getRefundSummary({ order_id: order_id }));
    }, [status.length, order_id]);
    var refundDestinationValue = {
        card_and_wallet_refund: "Wallet and bank account",
        card_refund: "Bank Account",
        wallet_refund: "Wallet",
    };
    return refundSummary.loadingStatus === LoadingStatus.loading ? (React.createElement(DotLoader, { color: "#f09440" })) : refundSummary.order_total_invoices > 0 ? (React.createElement("div", { className: styles.method, style: { width: "100%" } },
        ((_b = refundSummary) === null || _b === void 0 ? void 0 : _b.refund_destination) !== "" && (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Refund Destination"),
            React.createElement("div", { className: styles.refundSummary }, ((_c = refundSummary) === null || _c === void 0 ? void 0 : _c.refund_destination) && (React.createElement("p", null, refundDestinationValue[(_d = refundSummary) === null || _d === void 0 ? void 0 : _d.refund_destination]))))),
        React.createElement("span", null, "Refund Details"),
        React.createElement("div", { className: styles.refundSummary },
            React.createElement("p", null,
                "Customer refunded ",
                customerRefundValue,
                " ",
                currency),
            React.createElement("p", null,
                "Merchant refunded ",
                merchantRefundValue,
                " ",
                currency)),
        ((_e = refundSummary) === null || _e === void 0 ? void 0 : _e.wrongly_penalized) &&
            ((_f = refundSummary) === null || _f === void 0 ? void 0 : _f.wrongly_penalized) === true && (React.createElement("div", { className: styles.alertBox },
            React.createElement("p", null,
                React.createElement("img", { src: AlertIcon, alt: "clock icon", style: {
                        display: "inline-block",
                        marginTop: "5px",
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                    } }),
                wronglyPenalizedOrderMessage))))) : null;
};
export default RefundSummary;
