var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import { GET_REFUND_SUMMARY_ACTION } from "../../constants";
import { getRefundSummarySuccess } from "../actions/refundSummary-action";
var initialState = {
    cancellation_type: "with_penalty",
    customer_refund_percentage: 0,
    merchant_refund_percentage: 0,
    minutes_after_acceptance_threshold_for_full_refund: 0,
    refund_destination: "",
    watcher_name: "",
    order_total_invoices: 0,
    wrongly_penalized: false,
    loadingStatus: LoadingStatus.failed,
};
export var refundSummaryReducer = withLoadingReducer(reducer(initialState, on(getRefundSummarySuccess, function (state, _a) {
    var payload = _a.payload;
    return Object.keys(payload).length
        ? __assign(__assign(__assign({}, state), payload), { loadingStatus: LoadingStatus.success }) : initialState;
})), GET_REFUND_SUMMARY_ACTION);
