import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import form from "redux-form/lib/reducer";
import { authReducer } from "./reducers/authReducer";
import { orderTrackingReducer } from "./reducers/orderTrackingReducer";
import { storeReducer } from "./reducers/storeReduce";
import { branchesReducer } from "./reducers/branchReducer";
import { countriesReducer } from "./reducers/countriesReducer";
import { ordersReducer } from "./reducers/ordersReducer";
import { StoreInfoReducer } from "./reducers/storeInfoReducer";
import { listPickupBranchReducer } from "./reducers/listPickupBranchReducer";
import { orderDetailsReducer } from "./reducers/orderDetailsReducer";
import { CustomerInfoReducer } from "./reducers/customerInfoReducer";
import { BranchInfoReducer } from "./reducers/branchInfoReducer";
import { orderStatusReducer } from "./reducers/orderStatusReducer";
import { orderReducer } from "./reducers/orderReducer";
import { CallLogsReducer } from "./reducers/callLogsReducer";
import { cashierReducer } from "./reducers/cashierReducer";
import { noShowReducer } from "./reducers/noShowReducer";
import { cashierRemoteLoginReducer } from "./reducers/cashierRemoteLoginReducer";
import { getAddressReducer } from "./reducers/getAddressReducer";
import { changeOrderStatusReducer } from "./reducers/changeOrderStatusReducer";
import { assignedCashierReducer } from "./reducers/assignedCashierReducer";
import { branchesStatusReducer } from "./reducers/branchesStatusReducer";
import { brandsReducer } from "./reducers/brandsReducer";
import { brandFilterReducer } from "./reducers/brandFiltersReducer";
import { branchFilterReducer } from "./reducers/branchFiltersReducer";
import { brandGetNextPageReducer } from "./reducers/brandGetNextPageReducer";
import { getDeliveryReasonsReducer } from "./reducers/getDeliveryReasonsReducer";
import { getPickupReasonsReducer } from "./reducers/getPickupReasonsReducer";
import { branchAvailabilityReducer } from "./reducers/branchAvailabilityReducer";
import { changeBranchAvailabilityReducer } from "./reducers/changeBrachAvailabilityReducer";
import { getUserProfileReducer } from "./reducers/getUserProfileReducer";
import { getPortalStoresReducer } from "./reducers/getPortalStoresReducer";
import { unblockUserReducer } from "./reducers/unblockUserReducer";
import { regrantPointsReducer } from "./reducers/regrantPointsReducer";
import { refundPolicyReducer } from "./reducers/refundPolicyReducer";
import { refundSummaryReducer } from "./reducers/refundSummaryReducer";
var persistAuth = {
    key: "authReducer",
    storage: storage,
    blacklist: ["loadingStatus"],
};
var rootReducer = combineReducers({
    form: form,
    authReducer: persistReducer(persistAuth, authReducer),
    orderTrackingReducer: orderTrackingReducer,
    storeReducer: storeReducer,
    branchesReducer: branchesReducer,
    brandsReducer: brandsReducer,
    countriesReducer: countriesReducer,
    ordersReducer: ordersReducer,
    listPickupBranchReducer: listPickupBranchReducer,
    getPickupReasonsReducer: getPickupReasonsReducer,
    getDeliveryReasonsReducer: getDeliveryReasonsReducer,
    CustomerInfoReducer: CustomerInfoReducer,
    BranchInfoReducer: BranchInfoReducer,
    StoreInfoReducer: StoreInfoReducer,
    orderDetailsReducer: orderDetailsReducer,
    assignedCashierReducer: assignedCashierReducer,
    orderStatusReducer: orderStatusReducer,
    orderReducer: orderReducer,
    CallLogsReducer: CallLogsReducer,
    branchesStatusReducer: branchesStatusReducer,
    cashierReducer: cashierReducer,
    noShowReducer: noShowReducer,
    cashierRemoteLoginReducer: cashierRemoteLoginReducer,
    getAddressReducer: getAddressReducer,
    changeOrderStatusReducer: changeOrderStatusReducer,
    brandFilterReducer: brandFilterReducer,
    branchFilterReducer: branchFilterReducer,
    brandGetNextPageReducer: brandGetNextPageReducer,
    getPortalStoresReducer: getPortalStoresReducer,
    branchAvailabilityReducer: branchAvailabilityReducer,
    changeBranchAvailabilityReducer: changeBranchAvailabilityReducer,
    getUserProfileReducer: getUserProfileReducer,
    unblockUserReducer: unblockUserReducer,
    regrantPointsReducer: regrantPointsReducer,
    refundPolicyReducer: refundPolicyReducer,
    refundSummaryReducer: refundSummaryReducer,
});
export default rootReducer;
