import * as React from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import { Form, reduxForm, Field } from "redux-form";
import { RenderGenericField } from "./RenderGenericField";
import GenericButtonWithIcon from "../../../Shared-Components/GenericButtonWithIcon";
var EditReceipt = function (props) {
    var closeModal = props.closeModal;
    return (React.createElement(Modal, { style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }, open: true, onClose: closeModal },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("span", { className: styles.title }, "Edit receipt"),
            React.createElement(Form, null,
                React.createElement(Field, { component: RenderGenericField, label: "Order Total Invoice", placeholder: "39.00", className: styles.inputWrapper }),
                React.createElement("div", { className: styles.separator }),
                React.createElement("div", { className: styles.btnWrapper },
                    React.createElement(GenericButtonWithIcon, { label: "Cancel", className: styles.cancel, onClick: closeModal }),
                    React.createElement(GenericButtonWithIcon, { label: "Edit receipt", className: styles.edit }))))));
};
export default reduxForm({
    form: "editReceiptForm"
})(hot(module)(EditReceipt));
