import { Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Query } from "../../../../hooks";
import { editOrderStatusReason } from "../../../../redux-store/actions/reasons-action";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { CustomDropDownSelect } from "./CustomDropDownSelect";
import * as styles from "./styles.module.sass";
import { ClipLoader } from "react-spinners";
import { clearPrevState } from "../../../../redux-store/actions/order-status-action";
import { useSelect } from "../../../../helper/use-select";
var ReasonsModal = function (_a) {
    var onClose = _a.onClose, open = _a.open, order_id = _a.order_id, order_type = _a.order_type, actionType = _a.actionType;
    var _b;
    var dispatch = useDispatch();
    var data = Query.useGetCancellationReasons(order_id, "editing").data;
    var reasons = (_b = data) === null || _b === void 0 ? void 0 : _b.reasons;
    var _c = useState({
        label: "",
        value: 0,
        ar_label: "",
    }), selectReason = _c[0], setSelectReason = _c[1];
    var loading = useSelect(function (state) { return state.orderStatusReducer; }).loading;
    var reasonOptions = React.useMemo(function () {
        return ((reasons &&
            Array.isArray(reasons) &&
            reasons.map(function (reason) {
                return {
                    value: reason.reason_id,
                    label: reason.en_text,
                    ar_label: reason.ar_text,
                };
            })) ||
            []);
    }, [reasons]);
    var renderReasonsSelected = React.useCallback(function (item) {
        var _a, _b;
        return (React.createElement("div", { className: styles.selectItem },
            React.createElement("span", { className: styles.dropdownTitle }, item.value
                ? item.label.length > 45
                    ? (_a = item) === null || _a === void 0 ? void 0 : _a.label.substring(0, 45).concat("...") : (_b = item) === null || _b === void 0 ? void 0 : _b.label.substring(0, 45)
                : "Select cancellation reason..."),
            React.createElement("img", { className: styles.icon, src: require("../../../../assets/dropdown.svg") })));
    }, []);
    var renderItemSelected = function (item) {
        var _a;
        return (React.createElement("div", { className: styles.itemSelected },
            React.createElement("span", null, ((_a = item) === null || _a === void 0 ? void 0 : _a.label) || "Select cancellation reason...")));
    };
    var handleSelectReason = useCallback(function (item) {
        setSelectReason({
            ar_label: item.ar_label ? item.ar_label : "",
            label: item.label,
            value: item.value,
        });
    }, []);
    var handleSetReason = useCallback(function () {
        var label = selectReason.label, value = selectReason.value, ar_label = selectReason.ar_label;
        if (value) {
            dispatch(editOrderStatusReason({
                reason: {
                    id: value,
                    ar_text: ar_label ? ar_label : "",
                    en_text: label,
                },
                orderId: order_id,
                reason_id: value,
            }));
        }
    }, [selectReason]);
    useEffect(function () {
        if (loading === LoadingStatus.success) {
            onClose();
            dispatch(clearPrevState());
        }
    }, [loading]);
    return (React.createElement(Modal, { open: open, onClose: onClose, className: styles.main },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.contentWrapper },
                React.createElement("span", { className: styles.title }, "Edit Rejection Reason"),
                React.createElement("div", { className: styles.dropdownWrapper },
                    React.createElement("span", { className: styles.label }, " Rejection reasons: "),
                    React.createElement(CustomDropDownSelect, { onChange: handleSelectReason, renderSelectToggle: renderReasonsSelected, renderSelectItem: renderItemSelected, options: reasonOptions, initialValue: {
                            label: "Select cancellation reason...",
                            value: undefined,
                            ar_label: "",
                        } }))),
            React.createElement("div", { className: styles.footer },
                React.createElement("button", { onClick: onClose, className: styles.cancelBtn }, "Cancel"),
                React.createElement("button", { onClick: handleSetReason, className: selectReason.label ? styles.editReasonBtn : styles.disabled },
                    " ",
                    loading === LoadingStatus.loading ? (React.createElement(ClipLoader, { color: "#fff", size: 25 })) : actionType === "edit" ? ("Edit Reason") : ("Add Reason"),
                    " ")))));
};
export default ReasonsModal;
