var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { listPickupBranchesAction } from "../../constants";
import { listPickupBranchesSuccess } from "../actions/list-pickup-branches-action";
import { receiveBranchStatusChange } from "../actions/socket-actions";
export var listPickupBranchReducer = withLoadingReducer(reducer({
    branches: [],
    branchById: {},
    branchesHasMore: false,
    keptFilters: {
        branch_name: "",
        branch_pickup_status: [],
        order_status: []
    },
    page: 0,
    totalBranches: ""
}, on(listPickupBranchesSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { branches: Array.from(new Set(__spreadArrays((payload.page !== 0 ? state.branches : []), payload.branches.map(function (a) { return a.branch_id; })))), keptFilters: payload.filters, branchesHasMore: payload.hasMore, page: payload.page, totalBranches: payload.total_branches, branchById: __assign(__assign({}, (payload.page !== 0 ? state.branchById : [])), payload.branches.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.branch_id] = next, _a)));
        }, {})) }));
}), on(receiveBranchStatusChange, function (state, _a) {
    var _b;
    var payload = _a.payload;
    return __assign(__assign({}, state), { branchById: __assign(__assign({}, state.branchById), (_b = {}, _b[payload.id] = __assign(__assign({}, state.branchById[payload.id]), { branch_pickup_status: payload.pickup_status }), _b)) });
})), listPickupBranchesAction);
