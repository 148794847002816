import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { RoutingMethodPOS } from "../../../constants/types";
var RoutingMethod = function (_a) {
    var _b;
    var cashierCode = _a.cashierCode, routingMethod = _a.routingMethod, pos_type = _a.pos_type;
    var RMethod = (_b = {},
        _b[RoutingMethodPOS.call_center] = "Call Center",
        _b[RoutingMethodPOS.standalone] = "Standalone",
        _b[RoutingMethodPOS.pos] = "POS - " + pos_type,
        _b);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.imgWrapper },
            React.createElement("img", { className: styles.icon, src: require("../../../assets/cross.svg") })),
        React.createElement("div", { className: styles.contentWrapper },
            React.createElement("span", { className: styles.title }, "Routing method"),
            React.createElement("div", { className: styles.cashierIdWrapper },
                React.createElement("span", { className: styles.subTitle }, RMethod[routingMethod]),
                routingMethod === RoutingMethodPOS.call_center &&
                    typeof cashierCode === "number" && (React.createElement("span", { className: styles.cashierId },
                    " ",
                    "-Agent ID: ",
                    cashierCode))))));
};
export default hot(module)(RoutingMethod);
