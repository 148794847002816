import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import GenericButtonWithIcon from "../../../../../Shared-Components/GenericButtonWithIcon";
var ZonesModal = function (_a) {
    var onClose = _a.onClose, open = _a.open, delivery_zones = _a.delivery_zones;
    var deliveryZonesCount = useMemo(function () { return delivery_zones.length; }, [
        delivery_zones
    ]);
    return (React.createElement(Modal, { open: open, onClose: onClose, className: styles.modal },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("span", { className: styles.title },
                "Delivery Zones (",
                deliveryZonesCount,
                ")"),
            (delivery_zones || []).map(function (zones) { return (React.createElement("div", { className: styles.zonesWrapper, key: zones.sub_district_id },
                React.createElement("div", { className: styles.zoneWrapper },
                    React.createElement("span", { className: styles.city }, zones.city_en_name),
                    React.createElement("span", { className: styles.area },
                        zones.district_en_name,
                        " - ",
                        zones.sub_district_en_name)))); }),
            React.createElement("div", { className: styles.btnWrapper },
                React.createElement(GenericButtonWithIcon, { label: "Cancel", className: styles.cancel, onClick: onClose })))));
};
export default hot(module)(ZonesModal);
