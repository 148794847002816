/* eslint-disable @typescript-eslint/member-delimiter-style */
import React from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import AlertIcon from "../../../../../assets/ic_alert_triangle.svg";
var CancelOrderModal = function (_a) {
    var onClose = _a.onClose, with_no_penalty = _a.with_no_penalty, open = _a.open, title = _a.title, message = _a.message, confirm = _a.confirm;
    return (React.createElement(Modal, { open: open, onClose: onClose, className: styles.main },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.contentWrapper },
                React.createElement("span", { className: styles.title }, (title !== null && title !== void 0 ? title : "Order Cancellation Reason")),
                React.createElement("div", { className: styles.AlertIconRoundWrapper },
                    React.createElement("img", { src: AlertIcon, alt: "clock icon", style: {
                            display: "inline-block",
                            marginRight: "10px",
                            width: "40px",
                            height: "40px",
                        } })),
                React.createElement("p", { className: styles.text }, (message !== null && message !== void 0 ? message : "Are you sure you want to cancel the order with " + (with_no_penalty ? "no penalty" : "penalty") + " ?"))),
            React.createElement("div", { className: styles.footer },
                React.createElement("button", { onClick: confirm, className: styles.deleteBtn }, "Cancel order")))));
};
export default CancelOrderModal;
