var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { selectToken } from "../selectors";
import { retry, select } from "redux-saga/effects";
import { getOrder as getOrderApi } from "../../axios/getOrder";
import { OrderStatus } from "../../types";
import { listCallLogs } from "../../axios/list-call-logs";
import { TransactionState } from "../../constants/types";
import { orderDetailsAPI } from "../../axios/orderDetailsAPI";
// import {union, ActionCreator, Typed} from 'ts-action'
// import {cancelledByBranch} from "../../axios/cancelledByBranch";
// import {default as actions, cancelledByBranchSuccess, cancelledByBranchFailure} from "../actions/order-status-action";
// import {getOrderDetails} from "../actions/get-order-details-action";
// import {getOrder} from "../actions/order-action";
export function getOrderStatus(orderId) {
    var token, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _a.sent();
                return [4 /*yield*/, retry(Infinity, 1000, getOrderApi, token, orderId)];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data.status];
        }
    });
}
export function getOrderDetailsFn(orderId) {
    var token, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _a.sent();
                return [4 /*yield*/, retry(Infinity, 1000, orderDetailsAPI, token, orderId)];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data.transaction_state];
        }
    });
}
export function getCallLogs(page, per_page) {
    var token, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(selectToken)];
            case 1:
                token = _a.sent();
                return [4 /*yield*/, retry(Infinity, 1000, listCallLogs, token, page, per_page)];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data.assistance_requests.status];
        }
    });
}
export var isStatusChangeable = function (status) {
    return (status === OrderStatus.accepted ||
        status === OrderStatus.ready_for_pickup ||
        status === OrderStatus.out_for_delivery);
};
// unfinished work leave as is
// type GenAction<T extends string = string, P = {cashier_id:string; id: string}> = ActionCreator<T, (payload: P) => Typed<{
//   payload: P;
// }, T>> & {type: string}
//
// export const changeAfterAccepted = <R extends GenAction,S extends GenAction,F extends GenAction>(requested: R, success: S, failure: F) => {
//   const actionType = union(requested);
//   return function* (action: typeof actionType) {
//     const token = yield select(selectToken);
//     const { cashier_id, id } = action.payload;
//     try {
//       const status: OrderStatus = yield call(getOrderStatus, id);
//       if (isStatusChangeable(status)) {
//         const res = yield call(cancelledByBranch, token, cashier_id, id);
//         yield put(cancelledByBranchSuccess(res));
//       } else {
//         yield put(getOrderDetails({ id }));
//         yield put(getOrder(id));
//         yield put(actions.orderStatus(id));
//       }
//     } catch (e) {
//       yield put(cancelledByBranchFailure(e));
//     }
//   }
// }
export var canAcceptOrRejectOrder = function (status) {
    return (status === OrderStatus.seen ||
        status === OrderStatus.received ||
        status === OrderStatus.sent);
};
export var canRefundOrder = function (status, transaction_state) {
    return (transaction_state === TransactionState.captured &&
        (status === OrderStatus.delivered ||
            status === OrderStatus.got_picked_up ||
            status === OrderStatus.canceled_by_agent ||
            status === OrderStatus.customer_no_show));
};
