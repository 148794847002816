import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
var BranchHeader = function (_a) {
    var title = _a.title, length = _a.length;
    return (React.createElement("div", { className: styles.headerWrapper },
        React.createElement("span", { className: styles.headerTitle },
            title,
            " (",
            length,
            ")")));
};
export default hot(module)(BranchHeader);
