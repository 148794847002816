var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useSelect } from "../../../helper/use-select";
import { onlineOrderAtom } from "../../../hooks/useGetOnlineOrder";
import { useSendCreditsToWallet } from "../../../queries/wallet /refund-to-card.query";
import { useRefundToCard } from "../../../queries/wallet /send-compensation.query";
import { editOrderDetails, getOrderDetails, } from "../../../redux-store/actions/get-order-details-action";
import { editUserProfile } from "../../../redux-store/actions/user-profile-actions";
import { store } from "../../../redux-store/configureStore";
import { customNotify } from "../../Shared-Components/Notification";
export var useRefundingHook = function (_a) {
    var currency = _a.currency, order_id = _a.order_id, total_invoices = _a.total_invoices;
    // online order details
    var _b = useRecoilState(onlineOrderAtom), onlineOrderDetails = _b[0], setOnlineOrderDetails = _b[1];
    var wallet_balance = useSelect(function (state) { var _a; return (_a = state.getUserProfileReducer.user_info) === null || _a === void 0 ? void 0 : _a.wallet_balance; });
    var token = useSelect(function (state) { return state.authReducer.token; });
    var _c = useState(false), openRefundingCreditsToWalletPopup = _c[0], setOpenRefundingCreditsToWalletPopup = _c[1];
    var _d = useState(false), openRefundingCashToBankAccountPopup = _d[0], setOpenRefundingCashToBankAccountPopup = _d[1];
    // refunding to wallet
    var _e = useSendCreditsToWallet(token, order_id), mutateRefundingCreditsToWallet = _e.mutate, refundingCreditsToWalletStatus = _e.status, refundingCreditsToWalletError = _e.error, refundingCreditsToWalletData = _e.data;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (refundingCreditsToWalletStatus === "success") {
            setOnlineOrderDetails(__assign(__assign({}, onlineOrderDetails), { transaction_state: [
                    "koinz_wallet_online_apple_pay",
                    "koinz_wallet_online_general",
                ].includes(refundingCreditsToWalletData.data.payment_method)
                    ? "refunded_to_wallet"
                    : undefined, wallet_Transaction_status: "refunded" }));
            store.dispatch(editOrderDetails({
                order: __assign(__assign({}, refundingCreditsToWalletData.data), { transaction_state: [
                        "koinz_wallet_online_apple_pay",
                        "koinz_wallet_online_general",
                    ].includes(refundingCreditsToWalletData.data.payment_method)
                        ? "refunded_to_wallet"
                        : undefined, wallet_Transaction_status: "refunded" }),
            }));
            if ((_b = (_a = refundingCreditsToWalletData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.refunded_amount) {
                customNotify.success("Credits (" + ((_d = (_c = refundingCreditsToWalletData) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.refunded_amount) + " " + currency + ") sent to wallet successfully!");
                store.dispatch(getOrderDetails({
                    id: order_id,
                }));
                store.dispatch(editUserProfile({
                    wallet_balance: {
                        balance: wallet_balance.balance + ((_f = (_e = refundingCreditsToWalletData) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.refunded_amount),
                        currency: currency || "",
                    },
                }));
            }
            setOpenRefundingCreditsToWalletPopup(false);
        }
        else if (refundingCreditsToWalletStatus === "error") {
            customNotify.error((_g = refundingCreditsToWalletError) === null || _g === void 0 ? void 0 : _g.response.data.message);
        }
    }, [refundingCreditsToWalletStatus, refundingCreditsToWalletData]);
    // refunding to bank account
    var _f = useRefundToCard(token, order_id), mutateRefundingCashToBankAccount = _f.mutate, refundingCashToBankAccountStatus = _f.status, refundingCashToBankAccountError = _f.error, refundingCashToBankAccountData = _f.data;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (refundingCashToBankAccountStatus === "success") {
            setOnlineOrderDetails(__assign(__assign({}, onlineOrderDetails), { transaction_state: "refunded" }));
            if ((_b = (_a = refundingCashToBankAccountData) === null || _a === void 0 ? void 0 : _a.order) === null || _b === void 0 ? void 0 : _b.refunded_amount) {
                customNotify.success("Order total amount (" + ((_d = (_c = refundingCashToBankAccountData) === null || _c === void 0 ? void 0 : _c.order) === null || _d === void 0 ? void 0 : _d.refunded_amount) + " " + currency + ") refunded to bank account successfully!");
                store.dispatch(editOrderDetails({
                    order: __assign(__assign({}, (_e = refundingCashToBankAccountData) === null || _e === void 0 ? void 0 : _e.order), { transaction_state: "refunded" }),
                }));
                store.dispatch(editUserProfile({
                    wallet_balance: {
                        balance: wallet_balance.balance - ((_g = (_f = refundingCashToBankAccountData) === null || _f === void 0 ? void 0 : _f.order) === null || _g === void 0 ? void 0 : _g.refunded_amount),
                        currency: currency || "",
                    },
                }));
            }
            setOpenRefundingCashToBankAccountPopup(false);
        }
        else if (refundingCashToBankAccountStatus === "error") {
            customNotify.error((_h = refundingCashToBankAccountError) === null || _h === void 0 ? void 0 : _h.response.data.message);
        }
    }, [refundingCashToBankAccountStatus]);
    return {
        refundingCashToBankAccount: {
            setOpenRefundingCashToBankAccountPopup: setOpenRefundingCashToBankAccountPopup,
            refundingCashToBankAccountStatus: refundingCashToBankAccountStatus,
            refundingCashToBankAccountError: refundingCashToBankAccountError,
            mutateRefundingCashToBankAccount: mutateRefundingCashToBankAccount,
            openRefundingCashToBankAccountPopup: openRefundingCashToBankAccountPopup,
        },
        refundingCreditsToWallet: {
            mutateRefundingCreditsToWallet: mutateRefundingCreditsToWallet,
            setOpenRefundingCreditsToWalletPopup: setOpenRefundingCreditsToWalletPopup,
            refundingCreditsToWalletError: refundingCreditsToWalletError,
            refundingCreditsToWalletStatus: refundingCreditsToWalletStatus,
            openRefundingCreditsToWalletPopup: openRefundingCreditsToWalletPopup,
        },
    };
};
