var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from "redux-saga/effects";
import { loginAction } from "../../constants/index";
import { authFailure, authSuccess } from "../actions/auth-action";
import { login } from "../../axios/login";
import { selectForm } from "../selectors";
import { customNotify } from "../../components/Shared-Components/Notification";
function loginSaga() {
    var form, _a, email, password, res, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(selectForm)];
            case 1:
                form = _b.sent();
                _a = form.login.values, email = _a.email, password = _a.password;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(login, email, password)];
            case 3:
                res = _b.sent();
                if (res.status !== 200) {
                    customNotify.error("Incorrect Email or password");
                    throw res;
                }
                return [4 /*yield*/, put(authSuccess(res.data))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _b.sent();
                return [4 /*yield*/, put(authFailure(e_1))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function watchLoginSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(loginAction.requested, loginSaga)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
