import { action } from "ts-action";
export var genericAction = function (action) { return ({
    fulfilled: action + "_FULFILLED",
    rejected: action + "_REJECTED",
    requested: action + "_REQUESTED",
}); };
export var SOCKET_EVENT_RECEIVE_NEW_ORDER = "watcher_orders";
var ACTION_GET_ASSIGNED_CASHIER = "ACTION_GET_ASSIGNED_CASHIER";
export var getAssignedCashierAction = genericAction(ACTION_GET_ASSIGNED_CASHIER);
var ACTION_LOGIN = "ACTION_LOGIN";
export var loginAction = genericAction(ACTION_LOGIN);
var ACTION_LIST_PICKUP_BRANCHES = "ACTION_LIST_PICKUP_BRANCHES";
export var listPickupBranchesAction = genericAction(ACTION_LIST_PICKUP_BRANCHES);
var ACTION_ORDER_TRACKING = "ACTION_ORDER_TRACKING";
export var orderTrackingAction = genericAction(ACTION_ORDER_TRACKING);
var ACTION_DELIVERY_ORDER_TRACKING = "ACTION_DELIVERY_ORDER_TRACKING";
export var orderDeliveryTrackingAction = genericAction(ACTION_DELIVERY_ORDER_TRACKING);
var ACTION_GET_STORE = "ACTION_GET_STORE";
export var getStoreAction = genericAction(ACTION_GET_STORE);
var ACTION_GET_BRANCHES = "ACTION_GET_BRANCHES";
export var branchesAction = genericAction(ACTION_GET_BRANCHES);
var ACTION_GET_COUNTRIES = "ACTION_GET_COUNTRIES";
export var getCountriesAction = genericAction(ACTION_GET_COUNTRIES);
var ACTION_GET_ORDERS = "ACTION_GET_ORDERS";
export var getOrdersAction = genericAction(ACTION_GET_ORDERS);
var ACTION_GET_BRANCH_INFO = "ACTION_GET_BRANCH_INFO";
export var getBranchInfoAction = genericAction(ACTION_GET_BRANCH_INFO);
var ACTION_GET_CUSTOMER_INFO = "ACTION_GET_CUSTOMER_INFO";
export var getCustomerInfoAction = genericAction(ACTION_GET_CUSTOMER_INFO);
var ACTION_GET_ORDER_DETAILS = "ACTION_GET_ORDER_DETAILS";
export var getOrderDetailsAction = genericAction(ACTION_GET_ORDER_DETAILS);
export var ACTION_EDIT_ORDER_DETAILS = "ACTION_EDIT_ORDER_DETAILS";
var ACTION_GET_STORE_INFO = "ACTION_GET_STORE_INFO";
export var getStoreInfoAction = genericAction(ACTION_GET_STORE_INFO);
var ACTION_GET_CALL_LOGS = "ACTION_GET_CALL_LOGS";
export var getCallLogsAction = genericAction(ACTION_GET_CALL_LOGS);
var ACTION_ENABLE_NOTIFICATION_BUTTON = "ACTION_ENABLE_NOTIFICATION_BUTTON";
export var enableNotificationAction = genericAction(ACTION_ENABLE_NOTIFICATION_BUTTON);
var ACTION_DISABLE_ANIMATION_BUTTON = "ACTION_DISABLE_ANIMATION_BUTTON";
export var disableButtonAnimationAction = genericAction(ACTION_DISABLE_ANIMATION_BUTTON);
var ACTION_DISABLE_NOTIFICATION_BUTTON = "ACTION_DISABLE_NOTIFICATION_BUTTON";
export var disableNotificationAction = genericAction(ACTION_DISABLE_NOTIFICATION_BUTTON);
var ACTION_LOGOUT = "ACTION_LOGOUT";
export var logoutAction = genericAction(ACTION_LOGOUT);
var ACTION_ORDER_STATUS = "ACTION_ORDER_STATUS";
export var orderStatusAction = genericAction(ACTION_ORDER_STATUS);
export var ACTION_RECEIVE_CASHIER_SUPPORT_REQUEST = "ACTION_RECEIVE_CASHIER_SUPPORT_REQUEST";
export var receiveCashierSupportRequestAction = genericAction(ACTION_RECEIVE_CASHIER_SUPPORT_REQUEST);
export var ACTION_AUTHENTICATE_SOCKET = "ACTION_AUTHENTICATE_SOCKET";
export var ACTION_DISCONNECT_SOCKET = "ACTION_DISCONNECT_SOCKET";
export var SUPPORT_PUSHER_CHANNEL_NAME = "presence-assistance-requests";
export var BRANCH_STATUS_PUSHER_CHANNEL_NAME = "presence-branches-status";
export var NEWLY_CREATED_ORDER_CHANNEL_NAME = "presence-recieve-new-order";
export var NEWLY_CREATED_ORDER = "NEWLY_CREATED_ORDER";
export var ACTION_ORDER = "ACTION_ORDER";
export var orderAction = genericAction(ACTION_ORDER);
export var ACTION_CASHIERS = "ACTION_CASHIERS";
export var cashiersAction = genericAction(ACTION_CASHIERS);
var ACTION_ACCEPT_ORDER = "ACTION_ACCEPT_ORDER";
export var acceptOrderAction = genericAction(ACTION_ACCEPT_ORDER);
var ACTION_CANCELLED_BY_BRANCH = "ACTION_CANCELLED_BY_BRANCH";
export var cancelledByBranchAction = genericAction(ACTION_CANCELLED_BY_BRANCH);
var ACTION_CANCEL_ORDER = "ACTION_CANCEL_ORDER";
export var cancelOrderAction = genericAction(ACTION_CANCEL_ORDER);
var ACTION_NO_SHOW = "ACTION_NO_SHOW";
export var noShowAction = genericAction(ACTION_NO_SHOW);
var ACTION_NO_SHOW_ORDER_STATUS = "ACTION_NO_SHOW_ORDER_STATUS";
export var noShowOrderStatusAction = genericAction(ACTION_NO_SHOW_ORDER_STATUS);
var ACTION_REJECT_ORDER_STATUS = "ACTION_REJECT_ORDER_STATUS";
export var rejectOrderStatusAction = genericAction(ACTION_REJECT_ORDER_STATUS);
var ACTION_CHECK_ORDERS_UPDATE = "ACTION_CHECK_ORDERS_UPDATE";
export var checkOrdersUpdateAction = genericAction(ACTION_CHECK_ORDERS_UPDATE);
export var ACTION_INCOMING_ORDER_STATUS = "ACTION_INCOMING_ORDER_STATUS";
export var ORDERS_PAGE_SIZE = 15;
var ACTION_REQUEST_RESOLVED = "ACTION_REQUEST_RESOLVED";
export var requestResolvedAction = genericAction(ACTION_REQUEST_RESOLVED);
export var ACTION_GET_BRANCHES_STATUS = "ACTION_GET_BRANCHES_STATUS";
export var getBranchesStatusAction = genericAction(ACTION_GET_BRANCHES_STATUS);
export var requestCashierTokenAction = genericAction("ACTION_REQUEST_CASHIER_TOKEN");
export var ACTION_SELECT_BRAND_ID = "ACTION_SELECT_BRAND_ID,";
export var ACTION_SEARCH_FOR_BRANCHES = "ACTION_SEARCH_FOR_BRANCHES";
export var ACTION_SELECT_BRANCH_STATUS = "ACTION_SELECT_BRANCH_STATUS";
var ACTION_GET_ORDER_ADDRESS = "ACTION_GET_ORDER_ADDRESS";
export var getOrderAddressAction = genericAction(ACTION_GET_ORDER_ADDRESS);
var ACTION_MARK_ORDER_AS_DELIVERED = "ACTION_MARK_ORDER_AS_DELIVERED";
export var markOrderAsDeliveredAction = genericAction(ACTION_MARK_ORDER_AS_DELIVERED);
var DISPATCH_ORDER_AS_READY = "DISPATCH_ORDER_AS_READY";
export var dispatchOrderAsReadyAction = genericAction(DISPATCH_ORDER_AS_READY);
var ACTION_MARK_ORDER_AS_OUT_FOR_DELIVERY = "ACTION_MARK_ORDER_AS_OUT_FOR_DELIVERY";
export var markOrderAsOutForDeliveryAction = genericAction(ACTION_MARK_ORDER_AS_OUT_FOR_DELIVERY);
var ACTION_MARK_ORDER_AS_READY_FOR_PICKUP = "ACTION_MARK_ORDER_AS_READY_FOR_PICKUP";
export var markOrderAsReadyForPickupAction = genericAction(ACTION_MARK_ORDER_AS_READY_FOR_PICKUP);
var ACTION_MARK_ORDER_AS_GOT_PICKED_UP = "ACTION_MARK_ORDER_AS_GOT_PICKED_UP";
export var markOrderAsGotPickedUpAction = genericAction(ACTION_MARK_ORDER_AS_GOT_PICKED_UP);
var ACTION_GET_BRANDS = "ACTION_GET_BRANDS";
export var getBrandsAction = genericAction(ACTION_GET_BRANDS);
var ACTION_BRAND_FILTER = "ACTION_BRAND_FILTER";
export var getBrandFilterAction = genericAction(ACTION_BRAND_FILTER);
var ACTION_BRANCH_FILTER = "ACTION_BRANCH_FILTER";
export var getBranchFilterAction = genericAction(ACTION_BRANCH_FILTER);
var ACTION_GET_ACTIVE_BRANDS = "ACTION_GET_ACTIVE_BRANDS";
export var getActiveBrandsAction = genericAction(ACTION_GET_ACTIVE_BRANDS);
var ACTION_GET_INACTIVE_BRANDS = "ACTION_GET_INACTIVE_BRANDS";
export var getInactiveBrandsAction = genericAction(ACTION_GET_INACTIVE_BRANDS);
var ACTION_GET_CLOSED_BRANDS = "ACTION_GET_CLOSED_BRANDS";
export var getClosedBrandsAction = genericAction(ACTION_GET_CLOSED_BRANDS);
var ACTION_COUNTRY_FILTER = "ACTION_COUNTRY_FILTER";
export var countryFilterAction = genericAction(ACTION_COUNTRY_FILTER);
var ACTION_BRANDS_GET_NEXT_PAGE = "ACTION_BRANDS_GET_NEXT_PAGE";
export var brandsGetNextPageAction = genericAction(ACTION_BRANDS_GET_NEXT_PAGE);
var ACTION_REFUND_ORDER = "ACTION_REFUND_ORDER";
export var refundOrderAction = genericAction(ACTION_REFUND_ORDER);
var ACTION_GET_DELIVERY_REASONS = "ACTION_GET_DELIVERY_REASONS";
export var getDeliveryReasonsActions = genericAction(ACTION_GET_DELIVERY_REASONS);
var ACTION_GET_PICKUP_REASONS = "ACTION_GET_PICKUP_REASONS";
export var getPickupReasonsActions = genericAction(ACTION_GET_PICKUP_REASONS);
var ACTION_EDIT_ORDER_STATUS_REASON = "EDIT_ORDER_STATUS_REASON";
export var editOrderStatusReasonAction = genericAction(ACTION_EDIT_ORDER_STATUS_REASON);
var ACTION_GET_PORTAL_STORES = "ACTION_GET_PORTAL_STORES";
export var getPortalStoresAction = genericAction(ACTION_GET_PORTAL_STORES);
var ACTION_SUPPORT_PORTAL_STORES = "ACTION_SUPPORT_PORTAL_STORES";
export var supportPortalStoresAction = genericAction(ACTION_SUPPORT_PORTAL_STORES);
var ACTION_GET_BRANCH_AVAILABILITY_STATUS = "ACTION_GET_BRANCH_AVAILABILITY_STATUS";
export var getBranchAvailabilityStatusAction = genericAction(ACTION_GET_BRANCH_AVAILABILITY_STATUS);
var ACTION_CHANGE_BRANCH_AVAILABILITY_STATUS = "ACTION_CHANGE_BRANCH_AVAILABILITY_STATUS";
export var changeBranchAvailabilityStatusAction = genericAction(ACTION_CHANGE_BRANCH_AVAILABILITY_STATUS);
export var resetStateAction = "ACTION_RESET_STATUS";
var ACTION_UNBLOCK_USER = "ACTION_UNBLOCK_USER";
export var unblockUserAction = genericAction(ACTION_UNBLOCK_USER);
var ACTION_REGRANT_POINTS = "ACTION_REGRANT_POINTS";
export var regrantPointsAction = genericAction(ACTION_REGRANT_POINTS);
export var ACTION_CLEAR_PREV_STATE = "ACTION_CLEAR_PREV_STATE";
var ACTION_GET_USER_PROFILE = "ACTION_GET_USER_PROFILE";
export var getUserProfileAction = genericAction(ACTION_GET_USER_PROFILE);
var ACTION_CLEAR_PREV_ORDER_DETAILS = "ACTION_CLEAR_PREV_ORDER_DETAILS";
export var clearPrevOrderDetailsAction = action(ACTION_CLEAR_PREV_ORDER_DETAILS);
// set wallet balance
export var ACTION_EDIT_USER_PROFILE = "ACTION_EDIT_USER_PROFILE";
export var ACTION_CLEAR_PREV_ORDER_TRACKING_STATE = "ACTION_CLEAR_PREV_ORDER_TRACKING_STATE";
export var GET_REFUND_POLICY_ACTION = genericAction("GET_REFUND_POLICY_ACTION");
export var GET_REFUND_SUMMARY_ACTION = genericAction("GET_REFUND_SUMMARY_ACTION");
