var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { reducer, on, union } from "ts-action";
import { acceptOrder, cancelOrder, rejectOrderStatus, noShowOrderStatus, cancelledByBranch, acceptOrderSuccess, cancelOrderSuccess, rejectOrderStatusSuccess, noShowOrderStatusSuccess, cancelledByBranchSuccess, acceptOrderFailure, cancelOrderFailure, rejectOrderStatusFailure, noShowOrderStatusFailure, cancelledByBranchFailure, markOrderAsDelivered, markOrderAsGotPickedUpFailure, markOrderAsGotPickedUpSuccess, markOrderAsDeliveredSuccess, markOrderAsDeliveredFailure, markOrderAsOutForDelivery, markOrderAsOutForDeliverySuccess, refundOrderFailure, markOrderAsOutForDeliveryFailure, markOrderAsGotPickedUp, markOrderAsReadyForPickup, markOrderAsReadyForPickupSuccess, markOrderAsReadyForPickupFailure, refundOrder, refundOrderSuccess } from "../actions/order-status-action";
export var changeOrderStatusReducer = reducer({
    canChangeOrderStatus: true
}, on.apply(void 0, __spreadArrays(union(acceptOrder, cancelOrder, refundOrder, rejectOrderStatus, noShowOrderStatus, cancelledByBranch, markOrderAsDelivered, markOrderAsOutForDelivery, markOrderAsGotPickedUp, markOrderAsReadyForPickup), [function (state) { return (__assign(__assign({}, state), { canChangeOrderStatus: false })); }])), on.apply(void 0, __spreadArrays(union(acceptOrderSuccess, cancelOrderSuccess, refundOrderSuccess, refundOrderFailure, rejectOrderStatusSuccess, noShowOrderStatusSuccess, cancelledByBranchSuccess, acceptOrderFailure, cancelOrderFailure, rejectOrderStatusFailure, noShowOrderStatusFailure, markOrderAsGotPickedUpFailure, markOrderAsGotPickedUpSuccess, cancelledByBranchFailure, markOrderAsDeliveredSuccess, markOrderAsDeliveredFailure, markOrderAsOutForDeliverySuccess, markOrderAsOutForDeliveryFailure, markOrderAsReadyForPickupSuccess, markOrderAsReadyForPickupFailure, markOrderAsReadyForPickupSuccess, markOrderAsReadyForPickupFailure), [function (state) { return (__assign(__assign({}, state), { canChangeOrderStatus: true })); }])));
