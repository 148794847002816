import { resetStateAction } from './../../constants/index';
import { action, payload } from "ts-action";
import { changeBranchAvailabilityStatusAction, getBranchAvailabilityStatusAction, } from "../../constants";
export var getBranchAvailabilityStatus = action(getBranchAvailabilityStatusAction.requested, payload());
export var getBranchAvailabilityStatusSuccess = action(getBranchAvailabilityStatusAction.fulfilled, payload());
export var getBranchAvailabilityStatusFailure = action(getBranchAvailabilityStatusAction.rejected, payload());
export var changeBranchAvailabilityStatus = action(changeBranchAvailabilityStatusAction.requested, payload());
export var changeBranchAvailabilityStatusSuccess = action(changeBranchAvailabilityStatusAction.fulfilled, payload());
export var changeBranchAvailabilityStatusFailure = action(changeBranchAvailabilityStatusAction.rejected, payload());
export var resetState = action(resetStateAction);
