var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import * as styles from "./styles.module.sass";
export var RenderGenericField = function (props) {
    var input = props.input, viewOnly = props.viewOnly, label = props.label, type = props.type, placeholder = props.placeholder, _a = props.meta, touched = _a.touched, error = _a.error, valid = _a.valid, asyncValidating = _a.asyncValidating, invalid = _a.invalid, active = _a.active, noValidationMessages = props.noValidationMessages, className = props.className, disabled = props.disabled, loading = props.loading;
    var shouldShowLoading = asyncValidating || loading;
    var isInvalid = !shouldShowLoading && touched && invalid;
    var isValid = !shouldShowLoading && touched && valid;
    return (React.createElement("div", { className: styles.inputWrapper + " " + (className || "") },
        React.createElement("label", { htmlFor: input.name, className: styles.label },
            React.createElement("span", null, label)),
        React.createElement("div", { style: __assign(__assign(__assign({}, (active ? { borderColor: "#fdbb11" } : {})), (isValid ? { borderColor: "#38c172" } : {})), (isInvalid ? { borderColor: "#db3030" } : {})), className: "" + styles.inputAndLoadingWrapper },
            React.createElement("input", __assign({ disabled: viewOnly || disabled, autoComplete: "off", placeholder: placeholder, className: styles.input, id: input.name }, input, { type: type })),
            React.createElement("div", { className: styles.priceWrapper },
                React.createElement("span", { className: styles.price }, "EGP"))),
        !noValidationMessages && touched && error && (React.createElement("p", { style: {
                margin: 0,
                padding: "1rem",
                color: "#db3030",
                fontSize: "1.2rem"
            } },
            "*",
            error))));
};
