import axios from './axios';
import * as Requests from './requests';
export var unblockingUserApi = function (token, data) {
    return axios.post(Requests.unblockingUserURL, data, {
        headers: {
            token: token
        },
        baseURL: process.env.ENDPOINT
    });
};
