var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { withLoadingReducer } from "./withLoadingState";
import { reducer, on } from "ts-action";
import { getCallLogsAction } from "../../constants";
import { getCallLogsSuccess, EnableCallNotification, DisableCallNotification, DisableButtonAnimation, requestResolved, requestResolvedFailure } from "../actions/get-call-logs";
import { receivedCashierSupportRequest } from "../actions/socket-actions";
import { RequestStatus } from "../../axios/requestResolved";
export var CallLogsReducer = withLoadingReducer(reducer({
    callLogs: [],
    callLogsById: {},
    callLogsHasMore: false,
    keptFilters: {
        branch_id: "",
        cashier_phone_number: "",
        store_id: ""
    },
    page: 0,
    unseenCallLogs: new Set(),
    isNotified: false,
    isReceivedRequest: false,
    resolvedStatus: RequestStatus.unresolved
}, on(EnableCallNotification, function (state, _a) { return (__assign(__assign({}, state), { isNotified: false })); }), on(DisableCallNotification, function (state, _a) { return (__assign(__assign({}, state), { isNotified: true })); }), on(DisableButtonAnimation, function (state, _a) { return (__assign(__assign({}, state), { isReceivedRequest: false })); }), on(requestResolved, requestResolvedFailure, function (state, _a) {
    var _b;
    var payload = _a.payload;
    return __assign(__assign({}, state), { callLogsById: __assign(__assign({}, state.callLogsById), (_b = {}, _b[payload.id] = __assign(__assign({}, state.callLogsById[payload.id]), { status: payload.status }), _b)) });
}), on(receivedCashierSupportRequest, function (state, _a) {
    var _b;
    var payload = _a.payload;
    state.unseenCallLogs.add(payload.id.toString());
    return __assign(__assign({}, state), { unseenCallLogs: new Set(state.unseenCallLogs), callLogs: __spreadArrays([payload.id.toString()], state.callLogs), callLogsById: __assign(__assign({}, state.callLogsById), (_b = {}, _b[String(payload.id)] = payload, _b)), isReceivedRequest: true });
}), on(getCallLogsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { callLogs: Array.from(new Set(__spreadArrays((payload.page !== 0 ? state.callLogs : []), payload.assistance_requests.map(function (a) { return a.id.toString(); })))), callLogsById: __assign(__assign({}, (payload.page !== 0 ? state.callLogsById : [])), payload.assistance_requests.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
        }, {})), unseenCallLogs: new Set(), callLogsHasMore: payload.hasMore, keptFilters: payload.filters, page: payload.page }));
})), getCallLogsAction);
