import { getPickupReasonsActions, getDeliveryReasonsActions, editOrderStatusReasonAction, } from "../../constants/index";
import { action, payload } from "ts-action";
// pickup reasons actions
export var getPickupReasons = action(getPickupReasonsActions.requested, payload());
export var getPickupReasonsSuccess = action(getPickupReasonsActions.fulfilled, payload());
export var getPickupReasonsFailure = action(getPickupReasonsActions.rejected, payload());
// delivery reasons actions
export var getDeliveryReasons = action(getDeliveryReasonsActions.requested, payload());
export var getDeliveryReasonsSuccess = action(getDeliveryReasonsActions.fulfilled, payload());
export var getDeliveryReasonsFailure = action(getDeliveryReasonsActions.rejected, payload());
export var editOrderStatusReason = action(editOrderStatusReasonAction.requested, payload());
export var editOrderStatusReasonSuccess = action(editOrderStatusReasonAction.fulfilled, payload());
export var editOrderStatusReasonFailure = action(editOrderStatusReasonAction.rejected, payload());
