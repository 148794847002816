var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { clearPrevOrderDetailsAction, getAssignedCashierAction, } from "../../constants";
import { getAssignedCashierSuccess } from "../actions/get-order-details-action";
var initialState = {
    assignedCashier: "",
};
export var assignedCashierReducer = withLoadingReducer(reducer(initialState, on(getAssignedCashierSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { assignedCashier: payload }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { assignedCashier: "" })); })), getAssignedCashierAction);
