/* eslint-disable @typescript-eslint/camelcase */
import { atom } from "recoil";
import { compensationTicketType, } from "../../constants/types";
import { PointsValueType } from "../UserProfile/components/Compensation/components/AssignedBrandInputs";
export var posAddressState = atom({
    key: "posAddressState",
    default: "",
});
export var activeCompensationTicketAtom = atom({
    key: "activeCompensationTicket",
    default: compensationTicketType.koinzPromos,
});
export var initSelectedCompensationPromo = function () { return ({
    id: "",
    promo_code: "",
    title: "",
    desc: "",
    stores: [],
}); };
export var compensationMessageAtom = atom({
    key: "compensationMessage",
    default: {
        push_ar_message: "",
        push_en_message: "",
    },
});
export var selectedCompensationPromoAtom = atom({
    key: "selectedCompensationPromo",
    default: initSelectedCompensationPromo(),
});
var initCompensationPromoSearchAtom = function () {
    return {
        input: "",
        results: [],
    };
};
export var compensationPromoSearchAtom = atom({
    key: "compensationPromoSearch",
    default: initCompensationPromoSearchAtom(),
});
export var compensationPointsAtom = atom({
    key: "compensationPointsAtom",
    default: {
        store: {
            country_iso_code: "",
            ar_name: "",
            en_name: "",
            id: "",
        },
        type: PointsValueType.points,
    },
});
export var compensationStatusAtom = atom({
    key: "compensationStatusAtom",
    default: "idle",
});
export var navDrawerAtom = atom({
    key: "navDrawerAtom",
    default: {
        isOpen: false,
    },
});
export var ordersFilterAtom = atom({
    key: "ordersFilterAtom",
    default: {
        isOpen: false,
    },
});
