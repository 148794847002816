import { initSocket } from "./socket";
import { SUPPORT_PUSHER_CHANNEL_NAME, BRANCH_STATUS_PUSHER_CHANNEL_NAME, } from "../constants";
import { union } from "ts-action";
import * as socketActions from "../redux-store/actions/socket-actions";
import pickupSupportChannelEventsActions from "./pickupSupportChannelEventsActions";
import branchStatusChannelEventsActions from "./branchStatusChannelEventsActions";
import newOrderChannelEventsActions from "./newCreatedOrderActions";
var configureSocket = function (socket, storeAPI) {
    var pickupSupportChannel = socket.subscribe(SUPPORT_PUSHER_CHANNEL_NAME);
    var branchStatusChannel = socket.subscribe(BRANCH_STATUS_PUSHER_CHANNEL_NAME);
    var newOrderChannel = socket.subscribe("presence-watcher_orders");
    pickupSupportChannelEventsActions.forEach(function (eventMeta) {
        pickupSupportChannel.bind(eventMeta.label, function (data) {
            storeAPI.dispatch(eventMeta.actionCreator(data));
        });
    });
    branchStatusChannelEventsActions.forEach(function (eventMeta) {
        branchStatusChannel.bind(eventMeta.label, function (data) {
            storeAPI.dispatch(eventMeta.actionCreator(data));
        });
    });
    newOrderChannelEventsActions.forEach(function (eventMeta) {
        newOrderChannel.bind(eventMeta.label, function (data) {
            storeAPI.dispatch(eventMeta.actionCreator(data));
        });
    });
};
var disconnectSocket = function (socket) {
    socket.unsubscribe(SUPPORT_PUSHER_CHANNEL_NAME);
    socket.disconnect();
};
var actionTypes = union(socketActions.authenticateSocket, socketActions.receivedCashierSupportRequest, socketActions.disconnectSocket);
export var createMySocketMiddleware = function () {
    var socket;
    return function (storeAPI) {
        return function (next) { return function (action) {
            if (action.type === socketActions.authenticateSocket.type) {
                socket = initSocket(action.payload.token);
                configureSocket(socket, storeAPI);
                return;
            }
            if (action.type === socketActions.disconnectSocket.type) {
                disconnectSocket(socket);
                return;
            }
            return next(action);
        }; };
    };
};
