import { useMemo } from "react";
import { getLocalizedCurrency } from "../constants/lib";
import i18n from "../i18n/i18n";
export var useGetCurrency = function (countryIsoCode) {
    return useMemo(function () {
        if (countryIsoCode) {
            getLocalizedCurrency(i18n.language, countryIsoCode);
        }
        return getLocalizedCurrency(i18n.language, countryIsoCode);
    }, [i18n.language, countryIsoCode]);
};
