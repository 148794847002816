/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export var login = "/api/watcher/login";
export var orderPickupFunnel = "/api/watchers/pickup-orders/orders-funnel";
export var orderDeliveryFunnel = "/api/watchers/delivery-orders/orders-funnel";
export var uploadBlockedUsersCSV = "api/watcher/user-profile/block-csv";
export var store = "api/watchers/store";
export var storeURL = "api/watchers/store-availability";
export var getAssignedCashier = function (order_id) {
    return "/api/watchers/orders/assigned-cashier/" + order_id;
};
export var branches = function (id) { return "api/watchers/store/" + id + "/branch"; };
export var getCountries = "/api/getCountries";
export var pickupOrders = "/api/watchers/pickup-orders";
export var listPickupBranches = "/api/watchers/list-ordering-branches";
export var orderDetailsAPI = function (id) {
    return "/api/watchers/order-details/" + id;
};
export var customerInfoAPI = function (id) {
    return "/api/watchers/customer-info/" + id;
};
export var branchInfoAPI = function (id) { return "/api/watchers/branch/" + id; };
export var storeInfoAPI = function (id) { return "/api/watchers/store/" + id; };
export var orderTracking = function (id) {
    return "/api/watchers/pickup-orders/order-status-changes/" + id;
};
export var order = function (id) { return "/api/watchers/pickup-orders/order/" + id; };
export var listCallLogs = "/api/watchers/assistance-request";
export var getCashiers = function (id) {
    return "/api/watchers/pickup-orders/cashiers/" + id;
};
export var acceptOrderStatus = function (id) {
    return "/api/watchers/pickup-orders/accept-order-status/" + id;
};
export var cancelOrderStatus = function (id) {
    return "/api/watchers/pickup-orders/cancel-order-with-penalty/" + id;
};
export var cancelOrderWithoutPenalty = function (id) {
    return "/api/watchers/pickup-orders/cancel-order-without-penalty/" + id;
};
export var noShow = function (id) {
    return "/api/watchers/pickup-orders/check-customer-no-show/" + id;
};
export var noShowOrderStatus = function (id) {
    return "/api/watchers/pickup-orders/customer-no-show-order-status/" + id;
};
export var rejectOrderStatus = function (id) {
    return "/api/watchers/pickup-orders/reject-order-status/" + id;
};
export var requestResolved = function (id) {
    return "/api/watchers/assistance-request/" + id;
};
export var cancelledByBranch = function (id) {
    return "/api/watchers/pickup-orders/cancel-order-status-cashier/" + id;
};
export var getOrderAddressAPI = function (id) {
    return "/api/watchers/delivery-orders/extra-info/" + id;
};
export var refundOrderURL = function (id) {
    return "/api/watchers/refund-order/" + id;
};
export var pickupReasonsURL = "/api/watchers/order-status-reasons";
export var deliveryReasonsURL = "/api/watchers/order-status-reasons/delivery";
export var editOrderStatusReason = function (orderId) {
    return "/api/watchers/order-status-reasons/" + orderId + "/replace";
};
export var currentPortalStoresURL = "/api/watchers/stores";
export var supportStoreURL = "/api/watchers/stores/support";
// branch availability
export var branchAvailabilityURL = function (branch_id) {
    return "/api/watchers/branch/" + branch_id + "/availability";
};
// user fraud
export var regrantingPointsURL = "/api/watchers/fraud/regrant-removed-points";
export var unblockingUserURL = "/api/watchers/fraud/unblock";
// stories
export var getStoresURL = "/api/watchers/system-stores";
// order cancellation reasons
export var getOrderCancellationReasonURL = function (order_id) {
    return "/api/watchers/orders/" + order_id + "/cancellation-reasons";
};
// order rejection reasons
export var getOrderRejectionReasonsURL = function (order_id) {
    return "/api/watchers/orders/" + order_id + "/rejection-reasons";
};
// compensation promo
export var assignPromoCodeURL = "/api/watchers/user-profile/assign-compensational-promo";
export var getCompensationPromoURL = function (customer_id) {
    return "/api/watchers/user-profile/eligible-compensational-promos/" + customer_id;
};
export var userProfileURL = "/api/watchers/user-profile/search";
export var offlinePointOperationsURL = function (operation_id) {
    return "/api/watchers/user-profile/offline-visit-points/" + operation_id;
};
// offline redemption
export var offlineRedemptionURL = function (redemption_id) {
    return "/api/watchers/user-profile/offline-redemption/" + redemption_id;
};
// online order
export var onlineOrderURL = function (order_id) {
    return "/api/watchers/user-profile/online-order/" + order_id;
};
// User visits
export var userVisitsURL = function (customerId) {
    return "/api/watchers/user-profile/visits/" + customerId;
};
// customer stores
export var getCustomerStoresURL = function (customerId) {
    return "/api/watchers/user-profile/country-stores/" + customerId;
};
// block customer
export var blockCustomerURL = function (customer_id) {
    return "/api/watcher/user-profile/block/" + customer_id;
};
// compensation points
export var sendingCompensationalPointsURL = "/api/watchers/user-profile/send-compensational-points";
// Promo checker
export var promoCheckerURL = function (customerId, promoId, storeId) {
    return "/api/watchers/user-profile/" + customerId + "/store/" + storeId + "/promo/" + promoId + "/eligibility";
};
// Compensation history
export var compensationHistoryURL = function (customerId) {
    return "/api/watchers/user-profile/" + customerId + "/compensation-history";
};
// wallet
export var sendCreditsToWallet = function (order_id) {
    return "/api/watchers/refund-order-to-wallet/" + order_id;
};
export var refundToBankAccount = function (orderId) {
    return "/api/watchers/refund-order-to-card/" + orderId;
};
export var deductFromWallet = "/api/watchers/wallet-penalty";
export var walletTransactionsURL = function (user_id) {
    return "/api/watchers/" + user_id + "/transactions";
};
// user segments
export var getUserSegmentsURL = function (customer_id) {
    return "/api/watchers/user-profile/" + customer_id + "/segment";
};
export var getRefundPolicy = "/api/watchers/refund-policy-config";
export var getOrderRefundSummary = function (orderId) {
    return "api/watchers/orders/" + orderId + "/refund-summary";
};
export var pincodeVerfication = "/api/watchers/refund-pin-code-verification";
