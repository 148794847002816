import { useMemo } from "react";
import { OrderingStatus } from "../../../redux-store/actions/socket-actions";
export var BranchStatus;
(function (BranchStatus) {
    BranchStatus[BranchStatus["active"] = 0] = "active";
    BranchStatus[BranchStatus["inactive"] = 1] = "inactive";
    BranchStatus[BranchStatus["closed"] = 2] = "closed";
    BranchStatus[BranchStatus["disabled"] = 3] = "disabled";
})(BranchStatus || (BranchStatus = {}));
var isInactive = function (status) {
    return status === OrderingStatus.pausedByCashier;
};
var isDisabled = function (status) {
    return status === OrderingStatus.disabledIndefinitely || status === undefined;
};
var isClosed = function (status) {
    return status === OrderingStatus.pausedForOutsideWorkingHours ||
        status === OrderingStatus.disabledIndefinitely;
};
export var branchIsClosed = function (delivery_status, pickup_status) {
    return ((pickup_status === OrderingStatus.pausedForOutsideWorkingHours ||
        pickup_status === OrderingStatus.disabledIndefinitely) &&
        (delivery_status === OrderingStatus.pausedForOutsideWorkingHours ||
            delivery_status === OrderingStatus.disabledIndefinitely));
};
export var allEq = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.reduce(function (acc, next, index) {
        if (index === args.length - 2) {
            return acc && next === args[index + 1];
        }
        return acc;
    }, true);
};
export var oneEq = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.reduce(function (acc, next, index) {
        if (index === args.length - 2) {
            return acc || next === args[index + 1];
        }
        return acc;
    }, false);
};
export var equalsOneState = function (delivery_status, pickup_status, status) {
    return delivery_status === status || pickup_status === status;
};
export var useBranchStatus = function (pickup_status, delivery_status, store_pickup_status, store_delivery_status) {
    var pickupStatus = BranchStatus.active;
    var deliveryStatus = BranchStatus.active;
    var branchHasPickupEnabled = !isDisabled(pickup_status);
    var branchHasDeliveryEnabled = !isDisabled(delivery_status);
    pickupStatus = branchHasPickupEnabled ? pickupStatus : BranchStatus.disabled;
    deliveryStatus = branchHasDeliveryEnabled
        ? deliveryStatus
        : BranchStatus.disabled;
    pickupStatus = isInactive(pickup_status)
        ? BranchStatus.inactive
        : pickupStatus;
    deliveryStatus = isInactive(delivery_status)
        ? BranchStatus.inactive
        : deliveryStatus;
    var brandPickupIsClosed = isClosed(store_pickup_status);
    var brandDeliveryIsClosed = isClosed(store_delivery_status);
    pickupStatus = brandPickupIsClosed ? BranchStatus.closed : pickupStatus;
    deliveryStatus = brandDeliveryIsClosed ? BranchStatus.closed : deliveryStatus;
    var branchStatus = useMemo(function () {
        if (allEq(deliveryStatus, pickupStatus, BranchStatus.closed)) {
            return BranchStatus.closed;
        }
        else if (oneEq(deliveryStatus, pickupStatus, BranchStatus.inactive)) {
            return BranchStatus.inactive;
        }
        else {
            return BranchStatus.active;
        }
    }, []);
    return {
        branchStatus: branchStatus,
        deliveryStatus: deliveryStatus,
        pickupStatus: pickupStatus,
    };
};
var disabledIndefinitely = OrderingStatus.disabledIndefinitely, pausedForOutsideWorkingHours = OrderingStatus.pausedForOutsideWorkingHours;
export var closedStatus = {
    disabledIndefinitely: disabledIndefinitely,
    pausedForOutsideWorkingHours: pausedForOutsideWorkingHours,
};
