import { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { hot } from "react-hot-loader";
var ScrollToTop = function (props) {
    var location = props.location, children = props.children;
    var ref = useRef(location);
    useEffect(function () {
        return function () {
            if (location !== ref.current) {
                window.scrollTo(0, 0);
            }
        };
    }, []);
    return children;
};
export default hot(module)(withRouter(ScrollToTop));
