var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { authSuccess } from "../actions/auth-action";
import { withLoadingReducer } from "./withLoadingState";
import { loginAction } from "../../constants";
import { logoutSuccess } from "../actions/logout-actions";
export var authReducer = withLoadingReducer(reducer({ isLoggedIn: false, token: "" }, on(authSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { isLoggedIn: true, token: payload.token }));
}), on(logoutSuccess, function (state, _a) { return (__assign(__assign({}, state), { token: '', isLoggedIn: false })); })), loginAction);
