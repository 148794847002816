import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getBranchFilterAction } from "../../constants";
import { getBranchFilterSuccess } from "../actions/branches-action";
export var branchFilterReducer = withLoadingReducer(reducer({
    filterType: 'status',
}, on(getBranchFilterSuccess, function (state, _a) {
    var payload = _a.payload;
    return ({
        filterType: payload.filterType,
    });
})), getBranchFilterAction);
