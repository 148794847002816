import axios from "./axios";
import * as Requests from "./requests";
export var getUserProfileApi = function (token, phone_number) {
    return axios.get(Requests.userProfileURL, {
        headers: {
            token: token,
            "app-version": 0.1,
            "app-language": "en",
        },
        params: {
            phone_number: phone_number,
        },
        baseURL: process.env.ENDPOINT,
    });
};
