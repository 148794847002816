import * as React from 'react';
import { notifyCustomContent, closeAllNotices } from '../Notification';
import * as styles from './styles.module.sass';
var CustomNotify = function (props) {
    var text = props.text, type = props.type;
    return React.createElement("div", { className: styles.wrapper + " " + styles[type] }, text);
};
var NOTIFY = function (content, type, duration) {
    if (duration === void 0) { duration = 120; }
    notifyCustomContent({
        duration: duration,
        maxCount: 1,
        content: React.createElement(CustomNotify, { text: content, type: type })
    });
};
export { NOTIFY, closeAllNotices };
export var notify = function (str, type, time) {
    NOTIFY(str, type, time);
};
