import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useSelect } from "../../../../../../helper/use-select";
import { changeBranchAvailabilityStatus, resetState } from "../../../../../../redux-store/actions/branch-availability-actions";
import { LoadingStatus } from "../../../../../../redux-store/reducers/withLoadingState";
import { CustomDropDownSelect } from "../../../../../OrderDetails/OrderTracking/ReasonsModal/CustomDropDownSelect";
import styles from "./styles.module.sass";
var ChangeBranchStatus = function (_a) {
    var onClose = _a.onClose, open = _a.open, branch_name = _a.branch_name, branch_id = _a.branch_id, initialState = _a.initialState;
    var dispatch = useDispatch();
    var _b = useSelect(function (state) { return state.changeBranchAvailabilityReducer; }), status = _b.status, changeBrachAvailabilityLoading = _b.loadingStatus;
    var _c = useState(), state = _c[0], setState = _c[1];
    useEffect(function () {
        if (initialState === "disconnected")
            return setState("disconnected");
        else
            return setState("manual");
    }, [initialState]);
    var renderBranchStatusSelected = React.useCallback(function (item) {
        var _a;
        return (React.createElement("div", { className: styles.selectItem },
            React.createElement("span", { className: styles.dropdownTitle }, (_a = item) === null || _a === void 0 ? void 0 : _a.label),
            React.createElement("img", { className: styles.icon, src: require("../../../../../../assets/dropdown.svg") })));
    }, []);
    var renderItemSelected = function (item) {
        var _a;
        return (React.createElement("div", { className: styles.itemSelected },
            React.createElement("span", null, (_a = item) === null || _a === void 0 ? void 0 : _a.label)));
    };
    var handleChange = function (item) {
        setState(item.value);
    };
    var setManualDispatching = function () {
        if (state) {
            dispatch(changeBranchAvailabilityStatus({ data: { status: state }, branch_id: branch_id }));
        }
    };
    useEffect(function () {
        if (status !== LoadingStatus.loading) {
            onClose();
            dispatch(resetState());
        }
    }, [status]);
    return (React.createElement(Modal, { className: styles.main, open: open, onClose: onClose },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("span", { className: styles.title },
                "Edit \u201C",
                branch_name,
                "\u201D settings"),
            React.createElement("div", { className: styles.unavailabilityStatusWrapper },
                React.createElement("span", { className: styles.unavailabilityStatusTitle }, "Unavailability status"),
                React.createElement(CustomDropDownSelect, { onChange: handleChange, initialValue: {
                        label: initialState === "disconnected"
                            ? "Disconnected"
                            : "Manual dispatched",
                        value: state,
                    }, options: [
                        {
                            label: "Disconnected",
                            value: "disconnected",
                        },
                        {
                            label: "Manual dispatched",
                            value: "manual",
                        },
                    ], renderSelectToggle: renderBranchStatusSelected, renderSelectItem: renderItemSelected })),
            React.createElement("div", { className: styles.btnWrapper },
                React.createElement("button", { className: styles.cancel, onClick: onClose }, "Cancel"),
                React.createElement("button", { className: styles.edit, onClick: setManualDispatching }, changeBrachAvailabilityLoading === LoadingStatus.loading ? (React.createElement(ClipLoader, { size: 25, color: "#ffffff" })) : ("Edit settings"))))));
};
export default ChangeBranchStatus;
