/* eslint-disable @typescript-eslint/member-delimiter-style */
import React, { useEffect, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { CustomDropDownSelect } from "../../../OrderTracking/ReasonsModal/CustomDropDownSelect";
import { Helpers, Query } from "../../../../../hooks";
import { useSelect } from "../../../../../helper/use-select";
import { ClipLoader } from "react-spinners";
import { LoadingStatus } from "../../../../../redux-store/reducers/withLoadingState";
import Icons from "../../../../../types/icons";
import CancelOrderModel from "../CancelOrderModal";
import { DotLoader } from "react-spinners";
import { PaymentMethods } from "../..";
import OrderCancellingRefundInfo from "../OrderCancellingRefundInfo";
import { useDeepCompareMemo } from "use-deep-compare";
var OrderCancellationReasons = function (_a) {
    var onClose = _a.onClose, open = _a.open, with_no_penalty = _a.with_no_penalty, order_id = _a.order_id, confirm = _a.confirm, onChange = _a.onChange, onChangeRedundMethod = _a.onChangeRedundMethod, refundValue = _a.refundValue, value = _a.value, type = _a.type, title = _a.title, payment_method = _a.payment_method, currency = _a.currency;
    var _b, _c, _d;
    // variables
    var isRejectEmpty = !Helpers.useHasTruthyValue(value);
    var isCancelEmpty = !Helpers.useHasTruthyValue(refundValue);
    // is card payment
    var isCardPayment = useMemo(function () {
        return PaymentMethods.online_payment_general === payment_method ||
            PaymentMethods.online_payment_apple_pay === payment_method;
    }, [payment_method]);
    // custom refund options
    var customRefundMethodsOptions = function () {
        return isCardPayment
            ? RefundOptions
            : RefundOptions.filter(function (option) { return option.value == "wallet_refund"; });
    };
    // states
    var _e = useState(false), showCancelOrder = _e[0], setShowCancelOrder = _e[1];
    // store values
    var _f = useSelect(function (state) { return ({
        orderStatusReducer: state.orderStatusReducer,
        refund_policy: state.refundPolicyReducer,
    }); }), loading = _f.orderStatusReducer.loading, refund_policy = _f.refund_policy;
    // fetching data
    var data = Query.useGetCancellationReasons(order_id, type).data;
    // memoizied values
    var isMandatoryCancelledWithNoPenalty = useDeepCompareMemo(function () {
        var _a, _b;
        return ((_a = refund_policy) === null || _a === void 0 ? void 0 : _a.customer_refund_percentage) === 100 &&
            !with_no_penalty &&
            ((_b = refund_policy) === null || _b === void 0 ? void 0 : _b.recommended_cancellation_type) === "without_penalty";
    }, [refund_policy.recommended_cancellation_type, refund_policy.customer_refund_percentage, with_no_penalty]);
    var isOrderCanceledWithNoPenalty = useDeepCompareMemo(function () {
        var _a;
        return (with_no_penalty &&
            ((_a = refund_policy) === null || _a === void 0 ? void 0 : _a.recommended_cancellation_type) === "without_penalty");
    }, [refund_policy, with_no_penalty]);
    var isRightChoice = useDeepCompareMemo(function () {
        var _a, _b;
        return ((with_no_penalty &&
            ((_a = refund_policy) === null || _a === void 0 ? void 0 : _a.recommended_cancellation_type) === "without_penalty") ||
            (!with_no_penalty &&
                ((_b = refund_policy) === null || _b === void 0 ? void 0 : _b.recommended_cancellation_type) === "with_penalty"));
    }, [refund_policy, with_no_penalty]);
    var isCancelledTheRightWay = useMemo(function () { return payment_method === PaymentMethods.cash || isRightChoice; }, [isRightChoice, payment_method]);
    var details = useDeepCompareMemo(function () {
        var _a, _b, _c, _d;
        return [
            {
                order_total_invoices: (_a = refund_policy) === null || _a === void 0 ? void 0 : _a.order_total_invoices,
                refunded_target: "Customer",
                refund_percentage: (_b = refund_policy) === null || _b === void 0 ? void 0 : _b.customer_refund_percentage,
                icon: Icons.UserIcon,
                currency: currency,
            },
            {
                order_total_invoices: (_c = refund_policy) === null || _c === void 0 ? void 0 : _c.order_total_invoices,
                refunded_target: "Merchant",
                refund_percentage: (_d = refund_policy) === null || _d === void 0 ? void 0 : _d.merchant_refund_percentage,
                icon: Icons.UserIcon,
                currency: currency,
            },
        ];
    }, [refund_policy, currency]);
    useEffect(function () {
        if (!isCardPayment && onChangeRedundMethod) {
            onChangeRedundMethod(RefundOptions[1]);
        }
    }, [isCardPayment]);
    useEffect(function () {
        onChangeRedundMethod &&
            onChangeRedundMethod({
                label: "",
                value: "",
            });
    }, []);
    var reasons = useMemo(function () {
        var _a, _b, _c, _d;
        return type === "rejecting"
            ? (data || [])
            : with_no_penalty
                ? ((_b = (_a = data) === null || _a === void 0 ? void 0 : _a.reasons) === null || _b === void 0 ? void 0 : _b.without_penalty) || []
                : ((_d = (_c = data) === null || _c === void 0 ? void 0 : _c.reasons) === null || _d === void 0 ? void 0 : _d.with_penalty) || [];
    }, [(_c = (_b = data) === null || _b === void 0 ? void 0 : _b.reasons) === null || _c === void 0 ? void 0 : _c.without_penalty, type]);
    var reasonsOptions = useMemo(function () {
        return ((reasons &&
            Array.isArray(reasons) &&
            reasons
                .filter(function (item) { return item.is_deleted === 0; })
                .map(function (reason) {
                var _a;
                return ({
                    label: reason.en_text,
                    value: reason.reason_id || ((_a = reason) === null || _a === void 0 ? void 0 : _a.id),
                    ar_label: reason.ar_text,
                });
            })) ||
            []);
    }, [reasons]);
    var isEmpty = useMemo(function () {
        var _a;
        if (type === "canceling" &&
            !isMandatoryCancelledWithNoPenalty &&
            payment_method != "cash" &&
            !with_no_penalty &&
            ((_a = refund_policy) === null || _a === void 0 ? void 0 : _a.customer_refund_percentage) > 0) {
            return isCancelEmpty || isRejectEmpty;
        }
        else {
            return isRejectEmpty;
        }
    }, [type, isRejectEmpty, isCancelEmpty, refund_policy]);
    var RefundOptions = [
        {
            label: "Card",
            value: "card_refund",
            ar_label: "card_refund",
        },
        {
            label: "Wallet",
            value: "wallet_refund",
            ar_label: "wallet_refund",
        },
    ];
    var renderItemSelected = function (item) {
        var _a;
        return (React.createElement("div", { className: styles.itemSelected },
            React.createElement("span", { style: { wordBreak: "break-word", wordWrap: "break-word" } }, (_a = item) === null || _a === void 0 ? void 0 : _a.label)));
    };
    var showRefundPolicy = useDeepCompareMemo(function () {
        return refund_policy.loadingStatus === LoadingStatus.loading ? (React.createElement("div", { className: styles.loaderWrapper },
            React.createElement(DotLoader, { color: "#f09440" }))) : refund_policy.loadingStatus === "success" &&
            type != "rejecting" &&
            payment_method !== PaymentMethods.cash ? (React.createElement(OrderCancellingRefundInfo, { details: details, currency: currency, refund_policy: refund_policy, show: isRightChoice })) : null;
    }, [details, isRightChoice, refund_policy, currency]);
    // memoizied functionality
    var renderReasonsSelected = function (item) {
        var _a, _b;
        return (React.createElement("div", { className: styles.selectItem },
            React.createElement("span", { className: styles.dropdownTitle }, item.value
                ? item.label.length > 45
                    ? (_a = item) === null || _a === void 0 ? void 0 : _a.label.substring(0, 45).concat("...") : (_b = item) === null || _b === void 0 ? void 0 : _b.label.substring(0, 45)
                : type === "rejecting"
                    ? "Select rejection reason..."
                    : "Select cancellation reason..."),
            React.createElement("img", { className: styles.icon, src: require("../../../../../assets/dropdown.svg") })));
    };
    var handleCancellationEvent = function () {
        if (isCancelledTheRightWay ||
            isMandatoryCancelledWithNoPenalty ||
            type == "rejecting") {
            if (isMandatoryCancelledWithNoPenalty ||
                // payment_method === PaymentMethods.cash ||
                isOrderCanceledWithNoPenalty || with_no_penalty) {
                return confirm(true);
            }
            else {
                return confirm(false);
            }
        }
        else {
            setShowCancelOrder(!showCancelOrder);
        }
    };
    var renderRefundSelected = function (item) {
        var _a, _b;
        return (React.createElement("div", { className: styles.selectItem },
            React.createElement("span", { className: styles.dropdownTitle }, item.value
                ? item.label.length > 45
                    ? (_a = item) === null || _a === void 0 ? void 0 : _a.label.substring(0, 45).concat("...") : (_b = item) === null || _b === void 0 ? void 0 : _b.label.substring(0, 45)
                : type === "canceling"
                    ? "Select refund method..."
                    : "Select refund method..."),
            React.createElement("img", { className: styles.icon, src: require("../../../../../assets/dropdown.svg") })));
    };
    return (React.createElement(Modal, { open: open, onClose: onClose, className: styles.main },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.closeWrapper },
                React.createElement("span", { className: styles.title }, (title !== null && title !== void 0 ? title : "Order Cancellation Reason")),
                React.createElement("img", { onClick: onClose, src: require("../../../../../assets/icon-close.svg") })),
            React.createElement("div", { className: styles.contentWrapper },
                React.createElement("div", { className: styles.dropdownWrapper },
                    React.createElement("span", { className: styles.label }, " Cancellation reasons: "),
                    React.createElement(CustomDropDownSelect, { onChange: onChange, initialValue: {
                            label: "",
                            value: "",
                        }, renderSelectToggle: renderReasonsSelected, renderSelectItem: renderItemSelected, options: reasonsOptions })),
                showRefundPolicy,
                type === "canceling" &&
                    with_no_penalty === false &&
                    !isMandatoryCancelledWithNoPenalty &&
                    ((_d = refund_policy) === null || _d === void 0 ? void 0 : _d.customer_refund_percentage) > 0 &&
                    payment_method != "cash" && (React.createElement("div", { className: styles.dropdownWrapper },
                    React.createElement("span", { className: styles.label }, " Refund methods: "),
                    React.createElement(CustomDropDownSelect, { onChange: function (e) {
                            return onChangeRedundMethod
                                ? onChangeRedundMethod(e)
                                : function () { return undefined; };
                        }, initialValue: refundValue
                            ? {
                                value: refundValue.refund_id,
                                label: refundValue.refund_id === "card_refund"
                                    ? "Card"
                                    : "Wallet",
                            }
                            : {
                                label: "",
                                value: "",
                            }, renderSelectToggle: renderRefundSelected, renderSelectItem: renderItemSelected, options: customRefundMethodsOptions() }))),
                showCancelOrder && (React.createElement(CancelOrderModel, { open: showCancelOrder, confirm: function () { return confirm(with_no_penalty); }, onClose: function () { return setShowCancelOrder(!showCancelOrder); }, order_id: order_id, type: "canceling", with_no_penalty: with_no_penalty }))),
            React.createElement("div", { className: styles.footer },
                React.createElement("button", { onClick: handleCancellationEvent, disabled: isEmpty || Boolean(loading === LoadingStatus.loading), className: isEmpty || Boolean(loading === LoadingStatus.loading)
                        ? styles.disabled
                        : styles.deleteBtn }, loading === LoadingStatus.loading ? (React.createElement(ClipLoader, { size: 23, color: "#fff" })) : isMandatoryCancelledWithNoPenalty ? ("cancel without penalty") : isRightChoice || type === "rejecting" ? ("Cancel order") : ("Cancel anyway"))))));
};
export default OrderCancellationReasons;
