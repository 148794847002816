var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import { GET_REFUND_POLICY_ACTION } from "../../constants";
import { getRefundPolicySuccess } from "../actions/refundPolicy-action";
export var refundPolicyReducer = withLoadingReducer(reducer({
    recommended_cancellation_type: undefined,
    customer_refund_percentage: 0,
    merchant_refund_percentage: 0,
    minutes_elapsed_from_acceptance: 0,
    order_total_invoices: 0,
}, on(getRefundPolicySuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign(__assign({}, state), payload), { loadingStatus: LoadingStatus.success }));
})), GET_REFUND_POLICY_ACTION);
