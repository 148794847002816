import React from "react";
import styles from "../OrderCancellationReasons/styles.module.sass";
var WronglyPenalizedOrderAlert = function (_a) {
    var icon = _a.icon, customer_refund_percentage = _a.customer_refund_percentage, merchant_refund_percentage = _a.merchant_refund_percentage, order_total_invoices = _a.order_total_invoices, minutes_elapsed_from_acceptance = _a.minutes_elapsed_from_acceptance, currency = _a.currency, order_cancellation_type = _a.order_cancellation_type;
    return (React.createElement("div", { className: styles.alertBox },
        React.createElement("div", { className: styles.alertContent },
            React.createElement("div", { className: styles.alertImageContainer },
                React.createElement("img", { src: icon, alt: "alert icon", className: styles.icon })),
            React.createElement("div", null, order_cancellation_type === "without_penalty" ? React.createElement("p", null,
                "Note :  This order has been accepted from ",
                minutes_elapsed_from_acceptance,
                " minutes or less and should not be penalized") : React.createElement("p", null,
                "Note :  This order has been accepted ",
                minutes_elapsed_from_acceptance,
                " minutes ago and should be penalized"))),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("p", null,
                    "Customer should be refunded with \u00A0",
                    " ",
                    React.createElement("span", null, " " + customer_refund_percentage + "% "),
                    " \u00A0of order =",
                    " ",
                    "\u00A0",
                    " ",
                    React.createElement("span", null, "" + ((order_total_invoices * customer_refund_percentage) /
                        100).toFixed(2),
                        " ",
                        currency))),
            React.createElement("li", null,
                React.createElement("p", null,
                    "Merchant should be refunded with \u00A0",
                    " ",
                    React.createElement("span", null, " " + merchant_refund_percentage + "% "),
                    " \u00A0of order =",
                    " ",
                    "\u00A0",
                    " ",
                    React.createElement("span", null, "" + ((order_total_invoices * merchant_refund_percentage) /
                        100).toFixed(2),
                        " ",
                        currency))))));
};
export default WronglyPenalizedOrderAlert;
