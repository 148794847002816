import * as React from "react";
import * as styles from "./Login/styles.module.sass";
import loadable from "@loadable/component";
import { DefaultLoader } from "./LoadingPageInfo/index";
export function loadableWithBounce(component) {
    return loadable(component, {
        fallback: (React.createElement("div", { className: styles.wrapper },
            " ",
            React.createElement(DefaultLoader, null)))
    });
}
