var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { useRecoilState } from "recoil";
import { useSelect } from "../../../../../../helper/use-select";
import { Query, useGetCurrency } from "../../../../../../hooks";
import { compensationPointsAtom } from "../../../../../atoms";
import { DropdownSearchInput } from "../../../../../Shared-Components/DropdownSearchInput";
import * as styles from "./styles.module.sass";
export var PointsValueType;
(function (PointsValueType) {
    PointsValueType["points"] = "points";
    PointsValueType["invoice"] = "invoice";
})(PointsValueType || (PointsValueType = {}));
var SelectItemWrapper = function (props) {
    var onSelect = function () { return props.onSelect(props.value); };
    return React.createElement(DropdownItem, { onClick: onSelect }, props.children);
};
var AssignedBrandInputs = function () {
    var _a = useState(false), isTypeOpen = _a[0], setIsTypeOpen = _a[1];
    var _b = useState(PointsValueType.points), pointsValueType = _b[0], setPointsValueType = _b[1];
    var customer_id = useSelect(function (state) { return (__assign({}, state.getUserProfileReducer.user_info)); }).id;
    var _c = Query.useGetCustomerStores(customer_id), customer_stores = _c.data, status = _c.status;
    var _d = useRecoilState(compensationPointsAtom), _e = _d[0], points = _e.points, invoice = _e.invoice, store = _e.store, setFormValues = _d[1];
    useEffect(function () {
        setFormValues(function (prevState) { return (__assign(__assign({}, prevState), { type: pointsValueType })); });
    }, [pointsValueType]);
    var handleDropDownInputChange = function (selectValue) {
        var _a;
        var currentSelectedStore = (_a = customer_stores) === null || _a === void 0 ? void 0 : _a.filter(function (store) { return store.id === selectValue.value; });
        if (currentSelectedStore && currentSelectedStore.length > 0)
            setFormValues(function (prevState) { return (__assign(__assign({}, prevState), { store: currentSelectedStore[0] })); });
    };
    var handlePointsNumberChange = function (e) {
        setFormValues(function (prevState) { return (__assign(__assign({}, prevState), { points: Number(e.currentTarget.value) })); });
    };
    var handleInvoiceNumberChange = function (e) {
        setFormValues(function (prevState) { return (__assign(__assign({}, prevState), { invoice: Number(e.currentTarget.value) })); });
    };
    var options = useMemo(function () {
        var _a;
        if (!customer_stores)
            return [];
        return (customer_stores && ((_a = customer_stores) === null || _a === void 0 ? void 0 : _a.map(function (element) { return ({
            label: element.en_name,
            value: element.id,
        }); })));
    }, [customer_stores]);
    var currency = useGetCurrency(store.country_iso_code);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.inputWrapper },
            React.createElement("h2", { className: styles.inputLabel }, "Assigned brand"),
            React.createElement(DropdownSearchInput, { placeholder: "Search for Brands\u2026", onChange: handleDropDownInputChange, options: options, className: styles.dropDownSearchInput, renderSelectToggle: function () {
                    return (React.createElement("div", { className: styles.customSelectToggleWrapper, "data-test-id": "cy-custom-select-toggle-wrapper" },
                        React.createElement("span", null, store.en_name || "Select a store"),
                        React.createElement("div", { className: styles.triangleIcon })));
                } })),
        React.createElement("div", { className: styles.typeInput },
            React.createElement("h2", { className: styles.inputLabel }, "Type"),
            React.createElement(Dropdown, { isOpen: isTypeOpen, toggle: function () {
                    setIsTypeOpen(!isTypeOpen);
                } },
                React.createElement(DropdownToggle, { className: styles.switch },
                    React.createElement("div", { className: styles.customSelectToggleWrapper, "data-test-id": "cy-custom-select-toggle-wrapper" },
                        React.createElement("span", { style: { marginLeft: "1rem", textTransform: "capitalize" } }, pointsValueType || "Select type"),
                        React.createElement("div", { className: styles.triangleIcon }))),
                React.createElement(DropdownMenu, { className: styles.menuWrapper }, [PointsValueType.points, PointsValueType.invoice].map(function (option, index) { return (React.createElement(SelectItemWrapper, { key: option, value: option, onSelect: function (v) {
                        setPointsValueType(v);
                        setIsTypeOpen(false);
                    } },
                    React.createElement("div", { className: styles.item }, option))); })))),
        React.createElement("div", { className: styles.inputWrapper },
            React.createElement("div", { className: styles.inputLabelRow },
                React.createElement("h2", { style: invoice && invoice > 1000 ? { marginTop: "1.8rem" } : {}, className: styles.inputLabel },
                    pointsValueType === PointsValueType.points
                        ? "Points amount"
                        : "Money-points amount",
                    " ")),
            pointsValueType === PointsValueType.points && (React.createElement("input", { type: "number", onChange: handlePointsNumberChange, name: "points_amount", placeholder: "Enter points amount for ex: 169 points", className: !store.id ? styles.pointsInputDimmed : styles.pointsInput, disabled: !store.id, value: points || undefined, "data-test-id": "cy-points-input-field" })),
            pointsValueType === PointsValueType.invoice && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.withCurrencyRow + " " + (!store.id ? styles.pointsInputDimmed : styles.pointsInput) },
                    React.createElement("input", { type: "number", onChange: handleInvoiceNumberChange, name: "invoice_amount", placeholder: "Enter money amount  " + (currency ? "in " + currency + " " : ""), className: styles.input, disabled: !store.id, value: invoice || undefined, "data-test-id": "cy-invoice-input-field" }),
                    React.createElement("span", null, currency)),
                (invoice && invoice > 1000) ? React.createElement("span", { style: { color: "rgba(219, 48, 48)", marginTop: ".3rem" } }, "Invoice should be between 0 and 1000") : React.createElement(React.Fragment, null))))));
};
export default AssignedBrandInputs;
