var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useEffect, useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import BrandHeader from "./BrandHeader";
import BrandsHeader from "./BrandSharedComponent/BrandsHeader";
import Branches from "../Branches";
import { useSelect } from "../../../helper/use-select";
import { CustomDropDownSelect } from "../../Shared-Components/CustomDropDownSelect";
import { useDispatch } from "react-redux";
import SingleBrand from "./BrandSharedComponent/SingleBrand";
import InfiniteScroll from "react-infinite-scroll-component";
import { RoutingMethods } from "../../../axios/branchesStatus";
import { brandsGetNextPage, brandsGetNextPageRefresh, filterBrands, } from "../../../redux-store/actions/brand-get-next-page-action";
import { getBranchStatusBrandsCount } from "../../../axios/getBranchStatusBrandsCount";
import { selectToken } from "../../../redux-store/selectors";
export var BrandStatues;
(function (BrandStatues) {
    BrandStatues["active"] = "active";
    BrandStatues["inactive"] = "inactive";
    BrandStatues["closed"] = "closed";
    BrandStatues["manual"] = "manual";
    BrandStatues["all"] = "";
})(BrandStatues || (BrandStatues = {}));
var Brands = function () {
    var _a;
    var _b = useSelect(function (state) { return state.countriesReducer; }), countries = _b.countries, countriesById = _b.countriesById;
    var _c = useSelect(function (state) { return state.brandGetNextPageReducer; }), brandsById = _c.brandsById, brandsIds = _c.brandsIds, isLastPageEver = _c.isLastPageEver;
    var brands = React.useMemo(function () { return brandsIds.map(function (b) { return brandsById[b]; }); }, [
        brandsIds,
        brandsById,
    ]);
    useEffect(function () {
        dispatch(brandsGetNextPage());
        if (isLastPageEver) {
            var handle_1 = setTimeout(function () {
                dispatch(brandsGetNextPageRefresh());
            }, 30 * 1000);
            return function () { return clearTimeout(handle_1); };
        }
    }, [isLastPageEver]);
    var countriesOpts = React.useMemo(function () {
        return __spreadArrays([
            { value: undefined, label: "All Countries" }
        ], countries.map(function (country) { return ({
            value: countriesById[country].country_code,
            label: countriesById[country].EnName,
        }); }));
    }, [countries]);
    var renderCountriesSelected = React.useCallback(function (item) {
        return (React.createElement("div", { className: styles.selectItem },
            React.createElement("span", { className: styles.title }, item.label),
            React.createElement("img", { className: styles.icon, src: require("../../../assets/dropdown.svg") })));
    }, [countries]);
    var renderItemSelected = function (item) {
        return (React.createElement("div", { className: styles.itemSelected },
            React.createElement("span", null, item.label)));
    };
    var dispatch = useDispatch();
    var _d = useState(), brandStatus = _d[0], setBrandStatus = _d[1];
    var _e = useState(), countryFilter = _e[0], setCountryFilter = _e[1];
    var _f = useState(), searchTerm = _f[0], setSearchTerm = _f[1];
    useEffect(function () {
        var hasFilter = brandStatus || countryFilter || searchTerm;
        if (hasFilter) {
            dispatch(filterBrands({
                search: searchTerm,
                routing_method: brandStatus && brandStatus in RoutingMethods
                    ? brandStatus
                    : undefined,
                ordering_availability: brandStatus && brandStatus in BrandStatues
                    ? brandStatus
                    : undefined,
                store_country_code: countryFilter,
            }));
        }
        else {
            dispatch(filterBrands({ removeFilters: true }));
        }
    }, [brandStatus, countryFilter, searchTerm]);
    var handleSearch = useCallback(function (name) {
        setSearchTerm(name);
    }, []);
    var handleSelectCountry = useCallback(function (item) {
        setCountryFilter(item.value);
        if (item.value) {
            setSearchTerm(undefined);
        }
    }, [countryFilter]);
    var handleSelect = useCallback(function (status) {
        setBrandStatus(status);
        if (status) {
            setSearchTerm(undefined);
        }
    }, []);
    // const { filterType, filterValue } = useSelect(
    //   state => state.brandFilterReducer
    // );
    var handleGetNextPage = React.useCallback(function () {
        // dispatch(brandsGetNextPage());
    }, []);
    var statusTranslation = (_a = {},
        _a[BrandStatues.manual] = "Manual dispatched brands",
        _a[BrandStatues.inactive] = "Disconnected brands",
        _a[BrandStatues.active] = "Connected brands",
        _a[BrandStatues.closed] = "Closed brands",
        _a);
    var brandsByStatus = React.useMemo(function () {
        var _a;
        return brandsIds
            .filter(function (item) {
            var _a;
            return (_a = brandsById[item]) === null || _a === void 0 ? void 0 : _a.id;
        })
            .sort(function (a, b) {
            var storeA = brandsById[a];
            var storeB = brandsById[b];
            return storeA.name.localeCompare(storeB.name);
        })
            .reduce(function (acc, next) {
            var _a;
            var store = brandsById[next];
            return __assign(__assign({}, acc), (_a = {}, _a[store.status] = __spreadArrays(acc[store.status], [store]), _a));
        }, (_a = {},
            _a[BrandStatues.inactive] = [],
            _a[BrandStatues.active] = [],
            _a[BrandStatues.closed] = [],
            _a[BrandStatues.all] = [],
            _a[BrandStatues.manual] = [],
            _a.all = [],
            _a));
    }, [brandsIds, brandsById]);
    var countsByStatus = React.useMemo(function () {
        var _a;
        return brandsIds.reduce(function (acc, next) {
            var _a;
            var store = brandsById[next];
            var currentCount = acc[store.status];
            return __assign(__assign({}, acc), (_a = {}, _a[store.status] = currentCount + 1, _a.all = brandsIds.length, _a));
        }, (_a = {},
            _a[BrandStatues.inactive] = 0,
            _a[BrandStatues.active] = 0,
            _a[BrandStatues.closed] = 0,
            _a[BrandStatues.all] = 0,
            _a[BrandStatues.manual] = 0,
            _a.all = 0,
            _a));
    }, [brands]);
    var _g = useState(0), allBrandsCount = _g[0], setAllBrandsCount = _g[1];
    var token = useSelect(selectToken);
    useEffect(function () {
        getBranchStatusBrandsCount(token, {
            ordering_availability: brandStatus && brandStatus in BrandStatues ? brandStatus : "all",
            routing_method: brandStatus && brandStatus in RoutingMethods ? brandStatus : undefined,
            search: searchTerm,
            store_country_code: countryFilter,
        }).then(function (res) {
            var _a, _b;
            setAllBrandsCount(((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.stores_count) || 0);
        });
    }, [countryFilter, searchTerm, brandStatus]);
    var brandActivityFilter = brandStatus && brandStatus in BrandStatues
        ? brandStatus
        : undefined;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.dropDownWrapper },
            React.createElement("span", { className: styles.title }, "Country:"),
            React.createElement(CustomDropDownSelect, { onChange: handleSelectCountry, renderSelectToggle: renderCountriesSelected, renderSelectItem: renderItemSelected, options: countriesOpts })),
        React.createElement("div", { className: styles.main },
            React.createElement("div", { className: styles.wrapperMain, id: "brands-scroll-target" },
                React.createElement(BrandHeader, { onSelect: handleSelect, inputValue: searchTerm || "", handleSearch: handleSearch, brandsCounter: allBrandsCount || 0 }),
                React.createElement(InfiniteScroll, { dataLength: brands.length + 1, next: handleGetNextPage, scrollableTarget: "brands-scroll-target", hasMore: !isLastPageEver, loader: React.createElement(React.Fragment, null) },
                    brandActivityFilter && (React.createElement(BrandsHeader, { count: allBrandsCount, name: statusTranslation[brandActivityFilter] })),
                    " ",
                    [
                        BrandStatues.manual,
                        BrandStatues.inactive,
                        BrandStatues.active,
                        BrandStatues.closed,
                    ].map(function (stat) {
                        return (React.createElement(React.Fragment, { key: stat },
                            !brandActivityFilter && (React.createElement(BrandsHeader, { name: statusTranslation[stat], count: countsByStatus[stat] })),
                            brandsByStatus[stat].map(function (storeObject) {
                                return (React.createElement(SingleBrand, { key: storeObject.id, storeObject: storeObject, id: storeObject.id, brandImage: storeObject.store_image, branchNumbers: storeObject.total_branches_count, disconnectedBranchNumber: storeObject.paused_by_cashier_branches_count +
                                        storeObject.paused_for_disconnection_branches_count, brandName: storeObject.name, brandStatues: storeObject.status, routingMethod: storeObject.routing_method, manual_dispatching_branches_count: storeObject.manually_dispatched_branches_count }));
                            })));
                    }))),
            React.createElement(Branches, null))));
};
export default hot(module)(Brands);
