export var email = function (value) {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "Invalid email address"
        : undefined;
};
export var required = function (value) {
    return value && value.length ? undefined : "Required";
};
export var pinCode = function (value) {
    return value && /^[1-9][0-9]{4}$/.test(value) ? undefined : "Please enter a valid pincode";
};
