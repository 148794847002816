import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { RoutingMethods } from "../../../../../axios/branchesStatus";
import { BrandStatues } from "../..";
export var BrandStatus;
(function (BrandStatus) {
    BrandStatus["disconnected"] = "inactive";
    BrandStatus["closed"] = "closed";
    BrandStatus["connected"] = "active";
    BrandStatus["manual"] = "manual";
    BrandStatus["all"] = "";
})(BrandStatus || (BrandStatus = {}));
export var BrandStatusInNumberForm;
(function (BrandStatusInNumberForm) {
    BrandStatusInNumberForm[BrandStatusInNumberForm["disconnected"] = 0] = "disconnected";
    BrandStatusInNumberForm[BrandStatusInNumberForm["closed"] = 1] = "closed";
    BrandStatusInNumberForm[BrandStatusInNumberForm["connected"] = 2] = "connected";
    BrandStatusInNumberForm[BrandStatusInNumberForm["all"] = 3] = "all";
    BrandStatusInNumberForm[BrandStatusInNumberForm["manual"] = 4] = "manual";
})(BrandStatusInNumberForm || (BrandStatusInNumberForm = {}));
var CustomizedFilter = function (_a) {
    var handleSelect = _a.handleSelect;
    var _b = React.useState(BrandStatues.all), value = _b[0], setValue = _b[1];
    var _c = React.useState(false), clicked = _c[0], setClicked = _c[1];
    React.useEffect(function () {
        handleSelect(value || undefined);
    }, [value]);
    var handleAllClick = React.useCallback(function () {
        setValue(BrandStatues.all);
    }, []);
    var handleDisconnectedClick = React.useCallback(function () {
        setValue(BrandStatues.inactive);
    }, []);
    var handleManualDispatchedClick = React.useCallback(function () {
        setValue(BrandStatues.manual);
    }, []);
    var handleConnectedClick = React.useCallback(function () {
        setValue(BrandStatues.active);
    }, []);
    var handleClosedClick = React.useCallback(function () {
        setValue(BrandStatues.closed);
    }, []);
    var handleCallCenterClick = React.useCallback(function () {
        setValue(RoutingMethods.call_center);
    }, []);
    var handleStandaloneClick = React.useCallback(function () {
        setValue(RoutingMethods.standalone);
    }, []);
    var handlePOSClick = React.useCallback(function () {
        setValue(RoutingMethods.pos);
    }, []);
    var handleOpenFilter = React.useCallback(function () {
        setClicked(function (pt) { return !pt; });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.wrapper },
            React.createElement("span", { className: styles.title, style: { cursor: "pointer" }, onClick: handleOpenFilter },
                React.createElement("img", { style: { margin: "auto" }, src: require("../../../../../assets/filter.svg") }))),
        React.createElement("div", { className: styles.options + " " + (clicked ? styles.show : styles.hide), style: { paddingTop: "1.6rem" } },
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleAllClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BrandStatues.all
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BrandStatues.all
                        ? styles.activeText
                        : styles.inactiveText) }, "All")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleManualDispatchedClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BrandStatues.manual
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (BrandStatues.manual ? styles.activeText : styles.inactiveText) }, "Manual dispatched")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleDisconnectedClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BrandStatues.inactive
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (BrandStatues.inactive ? styles.activeText : styles.inactiveText) }, "Disconnected")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleClosedClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BrandStatues.closed
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BrandStatues.closed
                        ? styles.activeText
                        : styles.inactiveText) }, "Closed")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleConnectedClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BrandStatues.active
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BrandStatues.active
                        ? styles.activeText
                        : styles.inactiveText) }, "Connected")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleCallCenterClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === RoutingMethods.call_center
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === RoutingMethods.call_center
                        ? styles.activeText
                        : styles.inactiveText) }, "Call center")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleStandaloneClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === RoutingMethods.standalone
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === RoutingMethods.standalone
                        ? styles.activeText
                        : styles.inactiveText) }, "Standalone")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handlePOSClick },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === RoutingMethods.pos
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === RoutingMethods.pos
                        ? styles.activeText
                        : styles.inactiveText) }, "POS")))));
};
export default hot(module)(CustomizedFilter);
