import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
var CustomerAddress = function (_a) {
    var 
    // country,
    customer_country = _a.customer_country, district_en_name = _a.district_en_name, city_en_name = _a.city_en_name, sub_district_en_name = _a.sub_district_en_name, address = _a.address, comment = _a.comment;
    var copyLink = function () {
        if (address.delivery_address.lat && address.delivery_address.lng) {
            navigator.clipboard.writeText("http://maps.google.com/?q=" + address.delivery_address.lat + ',' + address.delivery_address.lng);
        }
    };
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("span", { className: styles.title }, "Customer Address"),
        React.createElement("div", { className: styles.addressWrapper },
            React.createElement("div", { className: styles.address },
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Country"),
                    React.createElement("span", { className: styles.countrySubTitle }, customer_country)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "City"),
                    React.createElement("span", { className: styles.countrySubTitle }, city_en_name)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "District"),
                    React.createElement("span", { className: styles.countrySubTitle }, district_en_name))),
            React.createElement("div", { className: styles.address }, address.delivery_address && (React.createElement("div", { className: styles.zone },
                React.createElement("span", { className: styles.zoneTitle }, "Zone/Street"),
                React.createElement("span", { className: styles.zoneSubTitle },
                    sub_district_en_name,
                    "/",
                    address.delivery_address.street)))),
            address.delivery_address && (React.createElement("div", { className: styles.address },
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Building no."),
                    React.createElement("span", { className: styles.countrySubTitle }, address.delivery_address.building)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Floor no."),
                    React.createElement("span", { className: styles.countrySubTitle }, address.delivery_address.floor)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Apartment no."),
                    React.createElement("span", { className: styles.countrySubTitle }, address.delivery_address.apartment)))),
            address.delivery_address.lat && address.delivery_address.lng && (React.createElement("div", { className: styles.customerLocationWrapper },
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Latitude"),
                    React.createElement("span", { className: styles.countrySubTitle }, address.delivery_address.lat)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Longitude"),
                    React.createElement("span", { className: styles.countrySubTitle }, address.delivery_address.lng)),
                React.createElement("div", { className: styles.country },
                    React.createElement("span", { className: styles.countryTitle }, "Location"),
                    React.createElement("span", { className: styles.countrySubTitle }),
                    React.createElement("span", { id: "copyLink", className: styles.copyLink, onClick: copyLink }, "Copy Link")))),
            comment && (React.createElement("div", { className: styles.address },
                React.createElement("div", { className: styles.zone },
                    React.createElement("span", { className: styles.zoneTitle }, "Comment"),
                    React.createElement("span", { className: styles.zoneSubTitle }, comment)))))));
};
export default hot(module)(CustomerAddress);
