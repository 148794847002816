import React, { useCallback, useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import * as styles from "./styles.module.sass";
import DisconnectedBranches from "./BranchesStatus/DisconnectedBranches";
import ActiveBranches from "./BranchesStatus/ActiveBranches";
import BranchHeader from "./BranchesStatus/BranchHeader/index";
import { useSelect } from "../../../helper/use-select";
import { useDispatch } from "react-redux";
import { OrderingStatus } from "../../../redux-store/actions/socket-actions";
import FilterByRoutingMethods from "../Brands/FilterByRoutingMethods";
import { searchForBranches } from "../../../redux-store/actions/branches-action";
import { BranchStatus } from "../lib";
import ManualDispatchedBranches from "./BranchesStatus/ManualDispatchedBranches";
import { branchIsClosed, equalsOneState } from "./lib";
var Branches = function () {
    var _a = useSelect(function (state) { return state.branchesStatusReducer; }), branches = _a.shownBranches, branchesById = _a.branchesById;
    var dispatch = useDispatch();
    var search = useCallback(function (term) {
        dispatch(searchForBranches(term));
    }, []);
    var filterType = useSelect(function (state) { return state.branchFilterReducer; }).filterType;
    var branchActive = function (branch) {
        var pickupActive = branch.pickup_status === OrderingStatus.enabled;
        var deliveryActive = branch.delivery_status === OrderingStatus.enabled;
        var bothEnabled = pickupActive && deliveryActive;
        var pickupDisabled = branch.pickup_status === OrderingStatus.disabledIndefinitely ||
            !branch.pickup_status ||
            branch.pickup_status === OrderingStatus.pausedForOutsideWorkingHours;
        var deliveryDisabled = branch.delivery_status === OrderingStatus.disabledIndefinitely ||
            !branch.delivery_status ||
            branch.delivery_status === OrderingStatus.pausedForOutsideWorkingHours;
        var oneActive = equalsOneState(branch.delivery_status, branch.pickup_status, OrderingStatus.enabled);
        return bothEnabled || (oneActive && (pickupDisabled || deliveryDisabled));
    };
    var unique = function (arr) { return Array.from(new Set(arr)); };
    var manualDispatchedBranches = unique(branches.filter(function (b) {
        return equalsOneState(branchesById[b].delivery_status, branchesById[b].pickup_status, OrderingStatus.manual);
    }));
    var disconnectedBranches = unique(branches.filter(function (b) {
        return equalsOneState(branchesById[b].delivery_status, branchesById[b].pickup_status, OrderingStatus.pausedForDisconnection);
    }));
    var branchesClosed = unique(branches.filter(function (b) {
        return branchIsClosed(branchesById[b].delivery_status, branchesById[b].pickup_status);
    }));
    var inactiveBranches = unique(branches.filter(function (b) {
        return (equalsOneState(branchesById[b].delivery_status, branchesById[b].pickup_status, OrderingStatus.pausedByCashier) ||
            equalsOneState(branchesById[b].delivery_status, branchesById[b].pickup_status, OrderingStatus.pausedByCashier));
    }));
    var branchesActive = unique(branches.filter(function (b) {
        return branchActive(branchesById[b]);
    }));
    var _b = useState(""), branchStatus = _b[0], setBranchStatus = _b[1];
    var handleSearch = useCallback(function (e) {
        search(e.target.value);
    }, [search]);
    var onSelect = useCallback(function (status) {
        setBranchStatus(status);
    }, []);
    var _c = useState(0), countLength = _c[0], setCount = _c[1];
    useEffect(function () {
        setCount(function () {
            if (filterType === BranchStatus.disconnected) {
                return disconnectedBranches.length;
            }
            else if (filterType === BranchStatus.closed) {
                return branchesClosed.length;
            }
            else if (filterType === BranchStatus.inactive) {
                return inactiveBranches.length;
            }
            else if (filterType === BranchStatus.active) {
                return branchesActive.length;
            }
            else if (filterType === BranchStatus.all) {
                return branches.length;
            }
            else if (filterType === BranchStatus.manual) {
                return branches.length;
            }
            return branches.length;
        });
    }, [filterType, countLength, branches]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.header },
            React.createElement("span", { className: styles.title },
                "Branches (",
                countLength,
                ")"),
            React.createElement("div", { className: styles.searchWrapper },
                React.createElement("div", { className: styles.search },
                    React.createElement("input", { type: "text", placeholder: "Search for Branches", className: styles.input, onChange: handleSearch }),
                    React.createElement("img", { src: require("../../../assets/search.svg"), className: styles.searchIcon })),
                React.createElement(FilterByRoutingMethods, { onSelect: onSelect }))),
        countLength === 0 ? (React.createElement("div", { className: styles.nothingHere },
            React.createElement("span", { className: styles.placeholderTitle }, "Couldn't find any branches"))) : (React.createElement("div", { className: styles.branchWrapper },
            manualDispatchedBranches.length !== 0 &&
                (filterType === BranchStatus.all ||
                    filterType === BranchStatus.manual) && (React.createElement(React.Fragment, null,
                React.createElement(BranchHeader, { length: manualDispatchedBranches.length, title: " Manual dispatched" }),
                manualDispatchedBranches
                    .sort(function (a, b) {
                    var latestDisconnectionA = Math.max(branchesById[a].last_pickup_status_time || 0, branchesById[a].last_delivery_status_time || 0);
                    var latestDisconnectionB = Math.max(branchesById[b].last_pickup_status_time || 0, branchesById[b].last_delivery_status_time || 0);
                    return latestDisconnectionA - latestDisconnectionB;
                })
                    .map(function (b) { return (React.createElement(ManualDispatchedBranches, { key: branchesById[b].id, branch: branchesById[b] })); }))),
            disconnectedBranches.length !== 0 &&
                (filterType === BranchStatus.all ||
                    filterType === BranchStatus.disconnected) && (React.createElement(React.Fragment, null,
                React.createElement(BranchHeader, { length: disconnectedBranches.length, title: "Disconnected" }),
                disconnectedBranches
                    .sort(function (a, b) {
                    var latestDisconnectionA = Math.max(branchesById[a].last_pickup_status_time || 0, branchesById[a].last_delivery_status_time || 0);
                    var latestDisconnectionB = Math.max(branchesById[b].last_pickup_status_time || 0, branchesById[b].last_delivery_status_time || 0);
                    return latestDisconnectionA - latestDisconnectionB;
                })
                    .map(function (b) { return (React.createElement(DisconnectedBranches, { branch: branchesById[b] })); }))),
            inactiveBranches.length !== 0 &&
                (filterType === BranchStatus.all ||
                    filterType === BranchStatus.inactive) && (React.createElement(React.Fragment, null,
                React.createElement(BranchHeader, { length: inactiveBranches.length, title: "Inactive" }),
                React.createElement(ActiveBranches, { inactive: true, closed: false, branches: inactiveBranches.map(function (b) { return branchesById[b]; }) }))),
            branchesActive.length !== 0 &&
                (filterType === BranchStatus.all ||
                    filterType === BranchStatus.active) && (React.createElement("div", null,
                React.createElement(BranchHeader, { length: branchesActive.length, title: "Active" }),
                React.createElement(ActiveBranches, { closed: false, inactive: false, branches: branchesActive.map(function (b) { return branchesById[b]; }) }))),
            branchesClosed.length !== 0 &&
                (filterType === BranchStatus.all ||
                    filterType === BranchStatus.closed) && (React.createElement(React.Fragment, null,
                React.createElement(BranchHeader, { length: branchesClosed.length, title: "Closed" }),
                React.createElement(ActiveBranches, { inactive: false, closed: true, branches: branchesClosed.map(function (b) { return branchesById[b]; }) })))))));
};
export default hot(module)(Branches);
