import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import GenericButtonWithIcon from "../../../../Shared-Components/GenericButtonWithIcon";
import { formatDistanceToNow } from "date-fns";
import { useToggleState } from "../../../../hooks/useToggleState";
import { Dialog, Paper } from "@material-ui/core";
import ChangeBranchStatus from "./ChangeBranchStatus";
var DisconnectBranches = function (_a) {
    var _b = _a.branch, app_version = _b.app_version, name = _b.name, id = _b.id, last_delivery_status_time = _b.last_delivery_status_time, last_pickup_status_time = _b.last_pickup_status_time, numbers = _b.numbers;
    var date = function (created_at) {
        return formatDistanceToNow(new Date(created_at), {
            addSuffix: true,
        });
    };
    var _c = useToggleState(), open = _c[0], toggle = _c[1];
    var _d = useState(false), state = _d[0], setState = _d[1];
    var onClose = function () { return setState(false); };
    var onOpen = function () { return setState(true); };
    var latestDisconnection = Math.max(last_pickup_status_time || 0, last_delivery_status_time || 0);
    return (React.createElement("div", { className: styles.main },
        React.createElement("div", { style: { padding: "0 2.4rem" } },
            React.createElement("div", { className: styles.wrapper, key: id },
                React.createElement("div", { className: styles.titleWrapper },
                    React.createElement("span", { className: styles.title },
                        " ",
                        name),
                    React.createElement("div", { className: styles.disconnectedWrapper },
                        React.createElement("span", { className: styles.disconnectedLabel }, "Disconnected since:"),
                        React.createElement("span", { className: styles.time }, date(latestDisconnection)))),
                React.createElement("div", { className: styles.statusWrapper },
                    React.createElement("div", { className: styles.statusLabelWrapper },
                        React.createElement("div", { className: styles.labelWrapper },
                            React.createElement("div", { className: styles.dot }),
                            React.createElement("span", { className: styles.statusLabel }, "Disconnected")),
                        React.createElement("span", { className: styles.version }, app_version || "unknown")),
                    React.createElement(GenericButtonWithIcon, { IconClass: styles.icon, onClick: toggle, className: styles.btnWrapper, iconPath: require("../../../../../assets/phone-call.svg") }),
                    React.createElement(GenericButtonWithIcon, { IconClass: styles.icon, onClick: onOpen, className: styles.btnWrapper, iconPath: require("../../../../../assets/edit.svg") })),
                state && (React.createElement(ChangeBranchStatus, { initialState: "disconnected", branch_id: id, branch_name: name, open: state, onClose: onClose })),
                open && (React.createElement(Dialog, { onClose: toggle, open: open },
                    React.createElement(Paper, { style: {
                            fontSize: "1.6rem",
                            width: "300px",
                            height: "200px",
                            padding: "20px",
                        } },
                        React.createElement("h3", { style: { fontSize: "1.6rem", fontWeight: "bold" } }, "Contact Branch through phone number:"),
                        React.createElement("p", { style: { fontSize: "3rem" } }, numbers[0]))))))));
};
export default hot(module)(DisconnectBranches);
