import * as React from "react";
import * as styles from "./styles.module.sass";
import Login from "./components/Login";
import AuthenticatedRoutes from "./AuthenticatedRoutes/index";
import NavBar from "./components/NavBar";
import { useSelect } from "./helper/use-select";
import Header from "./components/Header";
import { HashRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCountries } from "./redux-store/actions/get-countries-actions";
import { authenticateSocket, disconnectSocket, } from "./redux-store/actions/socket-actions";
import ContactSupport from "./components/CallsLogs/ContactSupport";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { DefaultLoader } from "./components/LoadingPageInfo/index";
import history from "./helper/history";
import { clearPrevOrderDetailsAction } from "./constants/index";
function App() {
    var dispatch = useDispatch();
    var _a = useSelect(function (state) { return state.authReducer; }), isLoggedIn = _a.isLoggedIn, token = _a.token;
    var loadingStatus = useSelect(function (state) { return state.storeReducer; }).loadingStatus;
    React.useEffect(function () {
        if (isLoggedIn) {
            dispatch(getCountries());
            dispatch(authenticateSocket({ token: token }));
            return function () {
                dispatch(disconnectSocket());
            };
        }
    }, [isLoggedIn]);
    React.useEffect(function () {
        if (history.location.pathname === "/wall") {
            dispatch(clearPrevOrderDetailsAction());
        }
    }, [history.location.pathname]);
    return (React.createElement("div", { className: styles.App }, loadingStatus === "loading" ? (React.createElement("div", { className: styles.loaderWrapper },
        React.createElement(DefaultLoader, null))) : isLoggedIn ? (React.createElement(HashRouter, null,
        React.createElement(ToastContainer, { position: "top-center", hideProgressBar: true, draggable: true, pauseOnHover: true, style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            } }),
        React.createElement(Header, null),
        React.createElement(NavBar, null),
        React.createElement("div", { style: { width: "100%", height: "7rem" } }),
        React.createElement(AuthenticatedRoutes, null),
        React.createElement(ContactSupport, null))) : (React.createElement(Login, null))));
}
export default App;
