var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { atom, useRecoilState } from "recoil";
import { Helpers } from ".";
import { getOnlineOrderApi, } from "../axios/onlineOrderApi";
import { COUNTRY_LIST } from "../constants/countries";
export var onlineOrderAtom = atom({
    key: "onlineOrderAtom",
    default: {
        id: "",
        items: [],
        order_code: "",
        store_country_iso_code: "",
        order_type: "",
        status: "",
        total_invoices: null,
        address: {
            area_name: "",
            comment: "",
            country_name: "",
            district_name: "",
            zone_name: "",
            apartment_number: "",
            building_number: "",
            floor_number: "",
        },
        comment: "",
        delivery_fees: null,
        payment_method: "",
        points_operation: {
            cashier_name: "",
            id: "",
            invoice: null,
            points: null,
            remaining: null,
            review: {
                categories: [],
                created_at: null,
                rate: null,
                id: "",
                tags: [],
                text: "",
            },
        },
        promotion: {
            ar_desc: "",
            ar_title: "",
            created_at: null,
            desc: "",
            discount_value: null,
            end_time: null,
            id: "",
            max_discount_value: null,
            max_usage_times: null,
            min_threshold: null,
            ordering_type: "",
            promo_code: "",
            start_time: null,
            suggested: null,
            targeting_method: "",
            title: "",
            type: "",
            updated_at: null,
            user_id: "",
        },
        redemption: [],
        rejection_reason: {
            ar_text: "",
            created_at: "",
            en_text: "",
            id: null,
            order_status: "",
            status_type: "",
            triggers: null,
            updated_at: "",
            user_type: "",
        },
        total_invoices_without_promo_code: null,
        payment_Transaction_amount: undefined,
        wallet_Transaction_amount: undefined,
    },
});
export var useGetOnlineOrder = function (token, order_id) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t = useRecoilState(onlineOrderAtom), state = _t[0], setState = _t[1];
    var _u = useQuery(["online-order", order_id], function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getOnlineOrderApi(token, order_id)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, {
        enabled: !!order_id,
    }), data = _u.data, rest = __rest(_u, ["data"]);
    useEffect(function () {
        if (rest.status === "success") {
            setState(data);
        }
    }, [rest.status, data]);
    var paymentDetailsIsEmpty = useMemo(function () {
        var _a, _b, _c, _d, _e;
        return !Helpers.useHasTruthyValue({
            total_invoices_without_promo_code: (_a = state) === null || _a === void 0 ? void 0 : _a.total_invoices_without_promo_code,
            total_invoices: (_b = state) === null || _b === void 0 ? void 0 : _b.total_invoices,
            delivery_fees: (_c = state) === null || _c === void 0 ? void 0 : _c.delivery_fees,
            payment_method: (_d = state) === null || _d === void 0 ? void 0 : _d.payment_method,
            promotions: (_e = state) === null || _e === void 0 ? void 0 : _e.promotion,
        });
    }, [data, rest.status]);
    var onlineOrderIsEmpty = !Helpers.useHasTruthyValue(state);
    var addressIsEmpty = !Helpers.useHasTruthyValue((_a = state) === null || _a === void 0 ? void 0 : _a.address);
    var redemptionIsEmpty = !Helpers.useHasTruthyValue((_b = state) === null || _b === void 0 ? void 0 : _b.redemption);
    var pointOperationsIsEmpty = !Helpers.useHasTruthyValue({
        invoice: (_d = (_c = state) === null || _c === void 0 ? void 0 : _c.points_operation) === null || _d === void 0 ? void 0 : _d.invoice,
        points: (_f = (_e = state) === null || _e === void 0 ? void 0 : _e.points_operation) === null || _f === void 0 ? void 0 : _f.points,
        cashier_name: (_h = (_g = state) === null || _g === void 0 ? void 0 : _g.points_operation) === null || _h === void 0 ? void 0 : _h.cashier_name,
        remaining: (_k = (_j = state) === null || _j === void 0 ? void 0 : _j.points_operation) === null || _k === void 0 ? void 0 : _k.remaining,
    });
    var orderStatusIsEmpty = !Helpers.useHasTruthyValue({
        status: (_l = state) === null || _l === void 0 ? void 0 : _l.status,
        order_code: (_m = state) === null || _m === void 0 ? void 0 : _m.order_code,
        rejection_reason: (_o = state) === null || _o === void 0 ? void 0 : _o.rejection_reason,
    });
    var currency = Helpers.useGetCurrency(((_p = state) === null || _p === void 0 ? void 0 : _p.store_country_iso_code) || "eg");
    var reviewIsEmpty = !Helpers.useHasTruthyValue((_r = (_q = state) === null || _q === void 0 ? void 0 : _q.points_operation) === null || _r === void 0 ? void 0 : _r.review);
    var country = useMemo(function () {
        return COUNTRY_LIST.find(function (country) { var _a; return country.ISO_code.includes(((_a = state) === null || _a === void 0 ? void 0 : _a.store_country_iso_code) || "eg"); });
    }, [(_s = state) === null || _s === void 0 ? void 0 : _s.store_country_iso_code]);
    return __assign({ reviewIsEmpty: reviewIsEmpty,
        currency: currency,
        orderStatusIsEmpty: orderStatusIsEmpty,
        pointOperationsIsEmpty: pointOperationsIsEmpty,
        redemptionIsEmpty: redemptionIsEmpty,
        addressIsEmpty: addressIsEmpty,
        onlineOrderIsEmpty: onlineOrderIsEmpty,
        paymentDetailsIsEmpty: paymentDetailsIsEmpty,
        country: country,
        state: state,
        data: data }, rest);
};
