import React from 'react';
import * as styles from './styles.module.sass';
import { hot } from 'react-hot-loader';
import { OrderingSource, OrderType } from '../../../constants/types';
var OrderSource = function (_a) {
    var _b;
    var ordering_source = _a.ordering_source, order_type = _a.order_type;
    var OrderingSourceMap = (_b = {},
        _b[OrderingSource.customer_app] = "Customer app",
        _b[OrderingSource.ordering_portal] = 'Web Portal',
        _b);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: order_type === OrderType.delivery
                ? styles.deliveryWrapper
                : styles.imgWrapper },
            React.createElement("img", { className: styles.icon, src: order_type === OrderType.pickup
                    ? require('../../../assets/pickup.svg')
                    : require('../../../assets/motorcycle.svg') })),
        React.createElement("div", { className: styles.contentWrapper },
            React.createElement("span", { className: styles.title }, "Order Source"),
            React.createElement("div", { className: styles.type },
                React.createElement("span", { className: styles.subTitle }, OrderingSourceMap[ordering_source])))));
};
export default hot(module)(OrderSource);
