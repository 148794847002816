var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { withLoadingReducer } from "./withLoadingState";
import { reducer, on } from "ts-action";
import * as actions from "../actions/order-tracking-action";
import { orderTrackingAction } from "../../constants";
export var orderTrackingReducer = withLoadingReducer(reducer({
    pickupStatus: {
        ready_for_pickup: 0,
        sent: 0,
        got_picked_up: 0,
        accepted: 0,
        received: 0,
        seen: 0,
        scheduled: 0
    },
    deliveryStatus: {
        out_for_delivery: 0,
        sent: 0,
        accepted: 0,
        received: 0,
        seen: 0,
        delivered: 0,
        scheduled: 0
    }
}, on(actions.orderTrackingSuccess, function (state, _a) {
    var payload = _a.payload;
    return __assign(__assign({}, state), { pickupStatus: __assign(__assign({}, state.pickupStatus), payload) });
}), on(actions.orderDeliveryTrackingSuccess, function (state, _a) {
    var payload = _a.payload;
    return __assign(__assign({}, state), { deliveryStatus: __assign(__assign({}, state.deliveryStatus), payload) });
})), orderTrackingAction);
