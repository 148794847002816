import { action, payload } from "ts-action";
import { branchesAction, getBranchesStatusAction, ACTION_SEARCH_FOR_BRANCHES, ACTION_SELECT_BRANCH_STATUS, ACTION_SELECT_BRAND_ID, getBranchFilterAction } from "../../constants/index";
export var getBranches = action(branchesAction.requested, payload());
export var getBranchesSuccess = action(branchesAction.fulfilled, payload());
export var getBranchesFailure = action(branchesAction.rejected, payload());
export var getBranchesStatus = action(getBranchesStatusAction.requested, payload());
export var searchForBranches = action(ACTION_SEARCH_FOR_BRANCHES, payload());
export var selectBrandIdAction = action(ACTION_SELECT_BRAND_ID, payload());
export var selectBranchStatus = action(ACTION_SELECT_BRANCH_STATUS, payload());
export var getBranchesStatusSuccess = action(getBranchesStatusAction.fulfilled, payload());
export var getBranchesStatusFailure = action(getBranchesStatusAction.rejected, payload());
export var getBranchFilter = action(getBranchFilterAction.requested, payload());
export var getBranchFilterSuccess = action(getBranchFilterAction.fulfilled, payload());
export var getBranchFilterFailure = action(getBranchFilterAction.rejected, payload());
