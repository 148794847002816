var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { cashiersAction, clearPrevOrderDetailsAction } from "../../constants";
import { cashierSuccess } from "../actions/cashiers-action";
export var cashierReducer = withLoadingReducer(reducer({
    cashier: [],
    cashierById: {},
}, on(cashierSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { cashier: payload.map(function (i) { return i.id; }), cashierById: payload.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
        }, {}) }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { cashier: [], cashierById: {} })); })), cashiersAction);
