import * as React from 'react';
import * as styles from './styles.module.sass';
import { hot } from 'react-hot-loader';
import { RoutingMethods } from '../../../../../axios/branchesStatus';
import { useDispatch } from 'react-redux';
import { selectBrandIdAction } from '../../../../../redux-store/actions/branches-action';
import { useProcessImageResource } from '../../../useProcessImageResource';
import { BrandStatues } from '../../index';
var SingleBrand = function (props) {
    var _a;
    var dispatch = useDispatch();
    var handleSelect = React.useCallback(function () {
        dispatch(selectBrandIdAction(props.id));
    }, [props.id]);
    var storeObject = props.storeObject;
    var disconnectedText = storeObject.paused_for_disconnection_branches_count
        ? 'disconnected'
        : '';
    var inactiveText = storeObject.paused_by_cashier_branches_count +
        storeObject.paused_for_inactivity_branches_count
        ? 'inactive'
        : '';
    var numberPostFix = [disconnectedText, inactiveText]
        .filter(function (a) { return a; })
        .join('/');
    var brandImage = useProcessImageResource(props.brandImage);
    var wrapper = (_a = {},
        _a[BrandStatues.active] = styles.connected,
        _a[BrandStatues.closed] = styles.closed,
        _a[BrandStatues.inactive] = styles.disconnected,
        _a[BrandStatues.manual] = styles.manualDispatched,
        _a);
    return (React.createElement("div", { onClick: handleSelect, className: styles.wrapper + " " + wrapper[props.brandStatues] },
        React.createElement("div", { className: styles.mainWrapper },
            React.createElement("div", { className: styles.brandImageWrapper },
                React.createElement("img", { className: styles.brandImage, src: brandImage !== undefined
                        ? brandImage
                        : require('../../../../../assets/logo.svg') })),
            React.createElement("div", { className: styles.brandInfoWrapper },
                React.createElement("span", { className: styles.brandName }, props.brandName),
                props.routingMethod === RoutingMethods.standalone ? (React.createElement("div", { className: styles.brandInfo },
                    React.createElement("span", { className: styles.brandTitle },
                        props.branchNumbers,
                        " Branches"),
                    props.brandStatues === BrandStatues.inactive ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.disconnectedWrapper },
                            "|",
                            React.createElement("span", { className: styles.disconnectedBranchNumbers },
                                storeObject.inactive_branches_count,
                                ' '),
                            ' ',
                            React.createElement("span", { className: styles.disconnectedBranchTitle }, numberPostFix)))) : props.brandStatues === BrandStatues.manual ? (React.createElement(React.Fragment, null, typeof storeObject.manually_dispatched_branches_count ===
                        'number' && (React.createElement("div", { className: styles.disconnectedWrapper },
                        "|",
                        React.createElement("span", { className: styles.disconnectedBranchNumbers },
                            storeObject.manually_dispatched_branches_count,
                            ' '),
                        ' ',
                        React.createElement("span", { className: styles.disconnectedBranchTitle }, "Manual dispatched"))))) : (''))) : (React.createElement(React.Fragment, null, props.routingMethod === RoutingMethods.pos ? (React.createElement("div", { className: styles.callCenter },
                    React.createElement("img", { className: styles.callCenterIcon, src: require('../../../../../assets/call-center.svg') }),
                    ' ',
                    React.createElement("span", { className: styles.callCenterTitle }, "POS"))) : (React.createElement("div", { className: styles.callCenter },
                    React.createElement("img", { className: styles.callCenterIcon, src: require('../../../../../assets/call-center.svg') }),
                    ' ',
                    React.createElement("span", { className: styles.callCenterTitle }, " Call center"))))))),
        props.brandStatues === BrandStatues.inactive ? (React.createElement("div", { className: styles.errorIconWrapper },
            React.createElement("img", { className: styles.brandImage, src: require('../../../../../assets/error.svg') }))) : ('')));
};
export default hot(module)(SingleBrand);
