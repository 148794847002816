import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { BranchStatus } from "../../lib";
import { useDispatch } from "react-redux";
import { getBranchFilter } from "../../../../redux-store/actions/branches-action";
var FilterByRoutingMethods = function (_a) {
    var onSelect = _a.onSelect;
    var _b = React.useState(BranchStatus.all), value = _b[0], setValue = _b[1];
    var _c = React.useState(false), clicked = _c[0], setClicked = _c[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(getBranchFilter({ filterType: value }));
    }, [value]);
    var handleAllBranchSelect = React.useCallback(function () {
        onSelect("");
        setValue(BranchStatus.all);
    }, []);
    var handleActiveBranchesSelect = React.useCallback(function () {
        onSelect(BranchStatus.active);
        setValue(BranchStatus.active);
    }, []);
    var handleInactiveBranchesSelect = React.useCallback(function () {
        onSelect(BranchStatus.inactive);
        setValue(BranchStatus.inactive);
    }, []);
    var handleDisconnectedBranchesSelect = React.useCallback(function () {
        onSelect(BranchStatus.disconnected);
        setValue(BranchStatus.disconnected);
    }, []);
    var handleClosedBranchesSelect = React.useCallback(function () {
        onSelect(BranchStatus.closed);
        setValue(BranchStatus.closed);
    }, []);
    var handleManualDispatchedBranchesSelect = React.useCallback(function () {
        onSelect(BranchStatus.manual);
        setValue(BranchStatus.manual);
    }, []);
    var handleOpenFilter = React.useCallback(function () {
        setClicked(function (pt) { return !pt; });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.wrapper },
            React.createElement("span", { className: styles.title, style: { cursor: "pointer" }, onClick: handleOpenFilter },
                React.createElement("img", { style: { margin: "auto" }, src: require("../../../../assets/filter.svg") }))),
        React.createElement("div", { className: styles.options + " " + (clicked ? styles.show : styles.hide), style: { paddingTop: "1.6rem" } },
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleAllBranchSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.all
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.all
                        ? styles.activeText
                        : styles.inactiveText) }, "All")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleActiveBranchesSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.active
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.active
                        ? styles.activeText
                        : styles.inactiveText) }, "Active")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleInactiveBranchesSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.inactive
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.inactive
                        ? styles.activeText
                        : styles.inactiveText) }, "Inactive")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleDisconnectedBranchesSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.disconnected
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.disconnected
                        ? styles.activeText
                        : styles.inactiveText) }, "Disconnected")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleManualDispatchedBranchesSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.manual
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.manual
                        ? styles.activeText
                        : styles.inactiveText) }, "Manual dispatched")),
            React.createElement("div", { className: "" + styles.optionWrapper, onClick: handleClosedBranchesSelect },
                React.createElement("div", { className: styles.optionInputWrapper },
                    React.createElement("div", { className: styles.optionInput + " " + (value === BranchStatus.closed
                            ? styles.activeOption
                            : styles.inactiveOption) })),
                React.createElement("span", { className: styles.optionText + " " + (value === BranchStatus.closed
                        ? styles.activeText
                        : styles.inactiveText) }, "Closed")))));
};
export default hot(module)(FilterByRoutingMethods);
