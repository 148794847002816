import * as React from "react";
import { Form, Field, reduxForm } from "redux-form";
import { ClipLoader } from "react-spinners";
import * as styles from "./styles.module.sass";
import { RenderGenericField } from "../Shared-Components/FormElements/RenderGenericField";
import { RenderPasswordField } from "../Shared-Components/FormElements/RenderPasswordField";
import { useDispatch } from "react-redux";
import { auth } from "../../redux-store/actions/auth-action";
import history from "../../helper/history";
import { useSelect } from "../../helper/use-select";
import { email, required } from "../../redux-store/validators";
var Login = function (props) {
    var submitting = props.submitting, handleSubmit = props.handleSubmit, valid = props.valid, submitSucceeded = props.submitSucceeded;
    var loadingStatus = useSelect(function (state) { return state.authReducer; }).loadingStatus;
    var dispatch = useDispatch();
    var submit = function () {
        if (!submitting) {
            dispatch(auth());
        }
        history.push("/wall");
    };
    return (React.createElement("div", { className: styles.roundWrapper },
        React.createElement("div", { className: styles.loginWrapper },
            React.createElement(React.Fragment, null,
                React.createElement("img", { src: require("../../assets/group-24.svg") }),
                React.createElement("div", { "data-test-id": "login-wrapper", className: styles.loginPage },
                    React.createElement(Form, { "data-test-id": "login-form", className: styles.form, onSubmit: handleSubmit(submit) },
                        React.createElement("div", { "data-test-id": "email", className: styles.usernameWrapper },
                            React.createElement(Field, { component: RenderGenericField, name: "email", label: "Email", placeholder: "name@example.com", validate: [required, email] })),
                        React.createElement("div", { "data-test-id": "password", className: styles.usernameWrapper },
                            React.createElement(Field, { name: "password", validate: [required], className: styles.passwordWrapper, type: "password", component: RenderPasswordField, label: "Password" })),
                        React.createElement("div", { style: { marginTop: "8px" } }),
                        React.createElement("div", { className: styles.usernameWrapper + " " + styles.submit },
                            React.createElement("button", { style: !valid ? { cursor: "not-allowed" } : {}, disabled: !valid, type: "submit" }, loadingStatus === "loading" && submitSucceeded ? (React.createElement(ClipLoader, { size: 30, color: "#fff" })) : ("Login")))))))));
};
export default reduxForm({
    form: "login",
})(Login);
