import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { OrderingStatus } from "../../../../../../redux-store/actions/socket-actions";
import { DeliveryIcon } from "../../Icons/deliveryIcon";
import { PickupIcon } from "../../Icons/pickupIcon";
import { useSelect } from "../../../../../../helper/use-select";
import { BrandStatues } from "../../../../Brands";
import { branchIsClosed } from "../../../lib";
var ActiveBranches = function (_a) {
    var delivery_status = _a.delivery_status, version = _a.version, name = _a.name, pickup_status = _a.pickup_status, id = _a.id, inactive = _a.inactive;
    var selectBrandId = useSelect(function (state) { return state.brandsReducer; }).selectBrandId;
    var brandsById = useSelect(function (state) { return state.brandGetNextPageReducer; }).brandsById;
    var status = brandsById[selectBrandId].status;
    var isClosed = React.useMemo(function () {
        return status === BrandStatues.closed ||
            branchIsClosed(delivery_status, pickup_status);
    }, [status, pickup_status, delivery_status]);
    return (React.createElement("div", { className: styles.main, key: id },
        React.createElement("div", { className: isClosed
                ? styles.inActiveWrapper
                : inactive
                    ? styles.inActiveWrapper
                    : styles.wrapper, key: id },
            React.createElement("div", { className: styles.titleWrapper },
                React.createElement("span", { className: styles.title },
                    " ",
                    name)),
            React.createElement("div", { className: styles.statusWrapper },
                React.createElement("div", { className: styles.statusLabelWrapper },
                    React.createElement("div", { className: styles.labelWrapper },
                        React.createElement("div", { className: styles.dot }),
                        React.createElement("span", { className: styles.statusLabel }, isClosed ? "Closed" : inactive ? "Inactive" : "Active")),
                    React.createElement("span", { className: styles.version }, version || "unknown")),
                React.createElement("div", { className: styles.line }),
                React.createElement("div", { className: styles.branchStatusWrapper },
                    React.createElement(DeliveryIcon, { delivery_status: isClosed
                            ? OrderingStatus.pausedForOutsideWorkingHours
                            : delivery_status }),
                    React.createElement("div", { className: styles.separator }),
                    React.createElement(PickupIcon, { pickup_status: isClosed
                            ? OrderingStatus.pausedForOutsideWorkingHours
                            : pickup_status }))))));
};
export default hot(module)(ActiveBranches);
