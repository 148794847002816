import React from "react";
import styles from "../OrderCancellationReasons/styles.module.sass";
var CanceledOrderTimeInfo = function (_a) {
    var icon = _a.icon, minutes_elapsed_from_acceptance = _a.minutes_elapsed_from_acceptance;
    return (React.createElement("div", { className: styles.acceptanceTime },
        React.createElement("img", { src: icon, alt: "clock icon" }),
        React.createElement("p", null,
            "Order has been accepted ",
            minutes_elapsed_from_acceptance,
            " minutes ago")));
};
export default CanceledOrderTimeInfo;
