var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { brandsGetNextPageAction } from "../../constants";
import { brandsGetNextPage, brandsGetNextPageRefresh, brandsGetNextPageSuccess, filterBrands, } from "../actions/brand-get-next-page-action";
import { BrandStatues } from "../../components/BranchStatus/Brands";
var initialState = {
    // distinction for the infinite scroll component as to not get it confused
    server_page: 0,
    infinite_scroll_page: 0,
    next_page_status: BrandStatues.inactive,
    brandsById: {},
    brandsIds: [],
    isLastPageEver: false,
    isFiltering: false,
};
export var brandGetNextPageReducer = withLoadingReducer(reducer(initialState, on(brandsGetNextPage, function (state) { return (__assign(__assign({}, state), { infinite_scroll_page: state.infinite_scroll_page + 1 })); }), on(filterBrands, function (state, _a) {
    var _b = _a.payload, ordering_availability = _b.ordering_availability, store_country_code = _b.store_country_code, routing_method = _b.routing_method, search = _b.search, removeFilters = _b.removeFilters;
    if (removeFilters) {
        return initialState;
    }
    var searchInAllStatuses = !!search && !routing_method && !ordering_availability;
    var shouldResetStatus = routing_method || store_country_code || searchInAllStatuses;
    return __assign(__assign({}, initialState), { brands: __spreadArrays((ordering_availability
            ? [{ status: ordering_availability }]
            : [
                {
                    status: BrandStatues.inactive,
                },
            ])), next_page_status: shouldResetStatus
            ? BrandStatues.inactive
            : state.next_page_status, ordering_availability: routing_method
            ? undefined
            : ordering_availability, search: search, routing_method: ordering_availability ? undefined : routing_method, store_country_code: store_country_code, isFiltering: true });
}), on(brandsGetNextPageRefresh, function (state) {
    return __assign(__assign({}, state), { server_page: 0, infinite_scroll_page: 0, isLastPageEver: false, next_page_status: state.ordering_availability || BrandStatues.inactive });
}), on(brandsGetNextPageSuccess, function (state, _a) {
    var payload = _a.payload;
    var brandsIds = payload.brands.map(function (brand) { return brand.id || brand.status; });
    var brandsById = payload.brands.reduce(function (acc, next) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[next.id || next.status] = next, _a)));
    }, {});
    if (payload.isLastPageEver) {
        return __assign(__assign({}, state), { brandsIds: Array.from(new Set(__spreadArrays(state.brandsIds, brandsIds))), brandsById: __assign(__assign({}, state.brandsById), brandsById), isLastPageEver: payload.isLastPageEver });
    }
    return __assign(__assign({}, state), { brandsIds: Array.from(new Set(__spreadArrays(state.brandsIds, brandsIds))), brandsById: __assign(__assign({}, state.brandsById), brandsById), next_page_status: payload.stateToGetNext, server_page: payload.page });
})), brandsGetNextPageAction);
