var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import * as styles from './styles.module.sass';
import { useToggleState } from '../../hooks/useToggleState';
import { useRouter } from '../../hooks/useRouter';
var CollapseSettings = function () {
    var location = useRouter().location;
    var isInSettings = useMemo(function () { return location.pathname.includes('/settings'); }, [
        location.pathname
    ]);
    var duration = 10;
    var defaultStyle = {
        transition: "all " + duration + "ms ease",
        opacity: 0
    };
    var transitionStyles = {
        false: { opacity: 0 },
        true: { opacity: 1 }
    };
    var _a = useToggleState(), collapse = _a[0], toggle = _a[1];
    var isOpen = collapse;
    return (React.createElement("div", { className: "" + styles.wrapper },
        React.createElement(NavLink, { to: '/settings/web-portal-support', className: isInSettings ? styles.btn : styles.btn },
            React.createElement(Button, { onClick: toggle, className: isInSettings ? styles.btnActive : styles.btn },
                React.createElement("div", { className: styles.bar },
                    React.createElement("img", { className: styles.icon, src: require('../../../assets/portal-settings.svg') }),
                    React.createElement("span", { className: styles.showNavItem }, "Settings")),
                React.createElement("span", { className: styles.showNavItem + " " + (isOpen ? styles.arrow : styles.arrowOpen) }))),
        React.createElement(Collapse, { style: __assign(__assign({}, defaultStyle), transitionStyles[isOpen ? 'true' : 'false']), in: isOpen || isInSettings, timeout: duration, className: styles.body, isOpen: isOpen },
            React.createElement(Card, { className: styles.card },
                React.createElement(CardBody, { style: { padding: '1.2rem 0' } },
                    React.createElement("li", { className: styles.NavigationItem },
                        React.createElement(NavLink, { to: '/settings/web-portal-support', exact: true, activeClassName: styles.active },
                            React.createElement("span", null, "Web portal support"))))))));
};
export default CollapseSettings;
