var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from 'ts-action';
import { changeBranchAvailabilityStatusAction } from '../../constants';
import { changeBranchAvailabilityStatus, changeBranchAvailabilityStatusFailure, changeBranchAvailabilityStatusSuccess, resetState } from '../actions/branch-availability-actions';
import { LoadingStatus, withLoadingReducer } from './withLoadingState';
var initialState = {
    status: LoadingStatus.loading
};
export var changeBranchAvailabilityReducer = withLoadingReducer(reducer(initialState, on(changeBranchAvailabilityStatusSuccess, function (state, _a) {
    return __assign(__assign({}, state), { status: LoadingStatus.success });
}), on(resetState, function () { return initialState; }), on(changeBranchAvailabilityStatus, function (state, _a) { return (__assign(__assign({}, state), { status: LoadingStatus.loading })); }), on(changeBranchAvailabilityStatusFailure, function (state, _a) { return (__assign(__assign({}, state), { status: LoadingStatus.failed })); })), changeBranchAvailabilityStatusAction);
