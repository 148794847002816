export var useHasTruthyValue = function (object) {
    var hasTruthyValue = false;
    if (object) {
        Object.values(object).forEach(function (element) {
            if (typeof element === "boolean" ||
                (Array.isArray(element) && element.length > 0) ||
                (typeof element !== "object" && element))
                hasTruthyValue = true;
            else if (typeof element === "object" &&
                element !== null &&
                useHasTruthyValue(element))
                hasTruthyValue = true;
        });
    }
    return hasTruthyValue;
};
