import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { formatDistanceToNow } from "date-fns";
import GenericButtonWithIcon from "../../../../Shared-Components/GenericButtonWithIcon";
import ChangeBranchStatus from "../DisconnectedBranches/ChangeBranchStatus";
var ManualDispatchedBranches = function (_a) {
    var _b = _a.branch, id = _b.id, name = _b.name, last_pickup_status_time = _b.last_pickup_status_time, last_delivery_status_time = _b.last_delivery_status_time, app_version = _b.app_version;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var onOpen = function () { return setOpen(true); };
    var onClose = function () { return setOpen(false); };
    var date = function (created_at) {
        return formatDistanceToNow(new Date(created_at), {
            addSuffix: true,
        });
    };
    var latestDisconnection = Math.max(last_pickup_status_time || 0, last_delivery_status_time || 0);
    return (React.createElement("div", { className: styles.main },
        React.createElement("div", { style: { padding: "0 2.4rem" } },
            React.createElement("div", { className: styles.wrapper, key: id },
                React.createElement("div", { className: styles.titleWrapper },
                    React.createElement("span", { className: styles.title },
                        " ",
                        name),
                    React.createElement("div", { className: styles.disconnectedWrapper },
                        React.createElement("span", { className: styles.disconnectedLabel }, "On manual dispatching from:"),
                        React.createElement("span", { className: styles.time }, date(latestDisconnection)))),
                React.createElement("div", { className: styles.statusWrapper },
                    React.createElement("div", { className: styles.statusLabelWrapper },
                        React.createElement("div", { className: styles.labelWrapper },
                            React.createElement("div", { className: styles.dot }),
                            React.createElement("span", { className: styles.statusLabel }, "Manual Dispatched")),
                        React.createElement("span", { className: styles.version }, app_version || "unknown")),
                    React.createElement(GenericButtonWithIcon, { IconClass: styles.icon, onClick: onOpen, className: styles.btnWrapper, iconPath: require("../../../../../assets/edit.svg") }),
                    open && (React.createElement(ChangeBranchStatus, { initialState: "manual", branch_name: name, branch_id: id, open: open, onClose: onClose })))))));
};
export default hot(module)(ManualDispatchedBranches);
