import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getBrandFilterAction } from "../../constants";
import { getBrandFilterSuccess } from "../actions/store-action";
import { BrandStatus } from "../../components/BranchStatus/Brands/BrandHeader/CustomizeFilter";
import { RoutingMethods } from "../../axios/branchesStatus";
export var brandFilterReducer = withLoadingReducer(reducer({
    filterType: "status",
    filterValue: BrandStatus.all || RoutingMethods.all
}, on(getBrandFilterSuccess, function (state, _a) {
    var payload = _a.payload;
    return ({
        filterType: payload.filterType,
        filterValue: payload.filterValue
    });
})), getBrandFilterAction);
