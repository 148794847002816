import * as React from "react";
import { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import GenericButtonWithIcon from "../../Shared-Components/GenericButtonWithIcon";
import SingleItem from "./SingleItem";
import { OrderingSource, RoutingMethodPOS, TransactionState, } from "../../../constants/types";
import EditReceipt from "./EditReceipt";
import { useDispatch } from "react-redux";
import { acceptOrder, cancelledByBranch, cancelOrder, clearPrevState, dispatchOrderAsReady, markOrderAsDelivered, markOrderAsGotPickedUp, markOrderAsOutForDelivery, markOrderAsReadyForPickup, 
// noShowOrderStatus,
rejectOrderStatus, } from "../../../redux-store/actions/order-status-action";
import { useSelect } from "../../../helper/use-select";
import { cashier as getCashier } from "../../../redux-store/actions/cashiers-action";
import { OrderStatus, FirstStates } from "../../../types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import { DotLoader } from "react-spinners";
import { OrderType } from "../OrderInfo/CustomerInformation";
import RefundModal from "./RefundModal";
import { getAssignedCashier } from "../../../redux-store/actions/get-order-details-action";
import ReactTooltip from "react-tooltip";
import { OrderCancellationReasons } from "../OrderDetails/components";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import GenericButton from "../../Shared-Components/GenericButton";
import { useRefundingHook } from "../useRefundingHook";
import OrderConfirmationPopup from "../../UserProfile/components/UserVisits/components/UserOrderDetails/components/OrderConfirmationPopup";
import PinCodeModal from "./components/PinCodeModal";
import RefundSummary from "./components/RefundSummary";
import { getRefundPolicy } from "../../../redux-store/actions/refundPolicy-action";
import { getRefundSummary } from "../../../redux-store/actions/refundSummary-action";
var sent = OrderStatus.sent, seen = OrderStatus.seen, received = OrderStatus.received, got_picked_up = OrderStatus.got_picked_up, delivered = OrderStatus.delivered, canceled_by_agent = OrderStatus.canceled_by_agent, ready_for_pickup = OrderStatus.ready_for_pickup, out_for_delivery = OrderStatus.out_for_delivery, accepted = OrderStatus.accepted, customer_no_show = OrderStatus.customer_no_show;
var acceptingStates = { sent: sent, seen: seen, received: received };
var refundingStates = {
    got_picked_up: got_picked_up,
    delivered: delivered,
    canceled_by_agent: canceled_by_agent,
    customer_no_show: customer_no_show,
};
var finalStates = { accepted: accepted, out_for_delivery: out_for_delivery, ready_for_pickup: ready_for_pickup };
var Help = withStyles(function () { return ({
    tooltip: {
        backgroundColor: "#000",
        color: "#fff",
        maxWidth: 220,
        fontSize: "1rem",
        border: "1px solid #dadde9",
        borderRadius: "4px",
        padding: "2rem",
    },
}); })(Tooltip);
export var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["cash"] = "cash";
    PaymentMethods["online_payment_general"] = "online_payment_general";
    PaymentMethods["online_payment_apple_pay"] = "online_payment_apple_pay";
    PaymentMethods["koinz_wallet"] = "koinz_wallet";
    PaymentMethods["koinz_wallet_online_general"] = "koinz_wallet_online_general";
    PaymentMethods["koinz_wallet_online_apple_pay"] = "koinz_wallet_online_apple_pay";
})(PaymentMethods || (PaymentMethods = {}));
var OrderInDetails = function (props) {
    var _a, _b;
    var _c, _d, _e, _f, _g, _h, _j, _k;
    var orderDetails = props.orderDetails, total_invoice = props.total_invoice, cashierCode = props.cashierCode, routing_method = props.routing_method, currency = props.currency, order_id = props.order_id, call_store_to_cancel_with_penalty = props.call_store_to_cancel_with_penalty, branch_id = props.branch_id, store_id = props.store_id, promotion = props.promotion, total_invoices_without_promo_code = props.total_invoices_without_promo_code, customer_name = props.customer_name, dispatched_to_third_party_delivery = props.dispatched_to_third_party_delivery, created_at = props.created_at, merchantVisibility = props.merchantVisibility, isCallCenter = props.isCallCenter, ordering_source = props.ordering_source, isMobile = props.isMobile;
    var _l = React.useState(false), showWalletPincodeModal = _l[0], setShowWalletPincodeModal = _l[1];
    var _m = React.useState(false), showBanckAccountPincodeModal = _m[0], setShowBanckAccountPincodeModal = _m[1];
    var _o = React.useState(false), showOrder = _o[0], setShowOrder = _o[1];
    var _p = React.useState(false), open = _p[0], setOpen = _p[1];
    var _q = React.useState(false), dispatchingLoading = _q[0], setDispatchingLoading = _q[1];
    var _r = React.useState(false), acceptOrderState = _r[0], setAcceptOrderState = _r[1];
    var openModalHandler = function () { return setOpen(true); };
    var closeModalHandler = function () { return setOpen(false); };
    var _s = useSelect(function (state) { return ({
        token: state.authReducer.token,
        cashier: state.cashierReducer,
        order: state.orderReducer.order,
        assignedCashier: state.assignedCashierReducer,
        user_info: state.getUserProfileReducer.user_info,
        orderStatus: state.orderStatusReducer,
        changeOrderStatus: state.changeOrderStatusReducer,
        refund_policy: state.refundPolicyReducer,
    }); }), token = _s.token, _t = _s.cashier, cashier = _t.cashier, cashierById = _t.cashierById, status = _s.order.status, assignedCashier = _s.assignedCashier.assignedCashier, customer_image = _s.user_info.image, _u = _s.orderStatus, loading = _u.loading, loadingStatus = _u.loadingStatus, canChangeOrderStatus = _s.changeOrderStatus.canChangeOrderStatus;
    var dispatch = useDispatch();
    var _v = React.useState({
        reason_id: "",
        ar_text: "",
        en_text: "",
    }), reasonSelected = _v[0], setReasonSelected = _v[1];
    var _w = React.useState({
        refund_id: "",
        ar_text: "",
        en_text: "",
    }), refundSelected = _w[0], setRefundSelected = _w[1];
    React.useEffect(function () {
        if (order_id) {
            dispatch(getCashier(order_id));
            dispatch(getRefundSummary({ order_id: order_id }));
        }
    }, [order_id]);
    React.useEffect(function () {
        if (dispatched_to_third_party_delivery === 1) {
            setDispatchingLoading(false);
        }
    }, [dispatched_to_third_party_delivery]);
    var dispatchCashierAction = function (action) {
        var firstCashier = cashier.find(function (id) { return cashierById[id].id; });
        if (isCallCenter && status in FirstStates && acceptOrderState) {
            dispatch(action({
                id: order_id,
                reason: reasonSelected,
                refund: refundSelected,
            }));
        }
        else {
            if (typeof cashierCode === "number") {
                dispatch(action({
                    cashier_id: assignedCashier,
                    id: order_id,
                    reason: reasonSelected,
                    refund: refundSelected,
                }));
            }
            else {
                if (status in FirstStates) {
                    if (firstCashier) {
                        dispatch(action({
                            cashier_id: firstCashier,
                            id: order_id,
                            reason: reasonSelected,
                            refund: refundSelected,
                        }));
                        dispatch(getAssignedCashier(order_id));
                    }
                }
                else {
                    dispatch(action({
                        cashier_id: assignedCashier,
                        id: order_id,
                        reason: reasonSelected,
                        refund: refundSelected,
                    }));
                }
            }
        }
    };
    var acceptOrderStatus = useCallback(function () {
        dispatchCashierAction(acceptOrder);
        setAcceptOrderState(false);
    }, [dispatchCashierAction]);
    React.useEffect(function () {
        if (acceptOrderState) {
            acceptOrderStatus();
        }
    }, [acceptOrderState]);
    var cancelOrderStatus = function () {
        dispatchCashierAction(cancelOrder);
    };
    var _x = React.useState(false), openModal = _x[0], setOpenModal = _x[1];
    var handleOpen = useCallback(function () {
        setOpenModal(true);
    }, []);
    var handleClose = useCallback(function () {
        setOpenModal(false);
    }, []);
    var rejectOrder = useCallback(function () {
        dispatchCashierAction(rejectOrderStatus);
    }, [dispatchCashierAction]);
    // const noShowHandler = useCallback(() => {
    //   dispatchCashierAction(noShowOrderStatus);
    // }, [dispatchCashierAction]);
    var cancelByBranch = function () {
        dispatchCashierAction(cancelledByBranch);
    };
    var handleCashierOperation = useCallback(function (action) {
        // const firstCashier = cashier.find(id => cashierById[id].id);
        dispatch(action({
            cashier_id: assignedCashier,
            order_id: order_id,
        }));
    }, [cashier, cashierById, order_id, branch_id, store_id, assignedCashier]);
    var handleMarkOrderAsDelivered = useCallback(function () {
        handleCashierOperation(markOrderAsDelivered);
    }, [handleCashierOperation]);
    //#region dispatching order for fleet(Courier)
    // send that .. order is ready
    var dispatchOrderAsReadyforCourier = useCallback(function () {
        dispatch(dispatchOrderAsReady({ order_id: order_id }));
        setDispatchingLoading(true);
    }, [dispatchOrderAsReady]);
    //#endregion
    var handleMarkOrderAsOutForDelivery = useCallback(function () {
        handleCashierOperation(markOrderAsOutForDelivery);
    }, [handleCashierOperation]);
    var handleMarkOrderAsReadyForPickup = useCallback(function () {
        handleCashierOperation(markOrderAsReadyForPickup);
    }, [handleCashierOperation]);
    var handleMarkOrderAsGotPickedUp = useCallback(function () {
        handleCashierOperation(markOrderAsGotPickedUp);
    }, [handleCashierOperation]);
    var paymentTitle = (_a = {},
        _a[PaymentMethods.cash] = "Cash",
        _a[PaymentMethods.online_payment_apple_pay] = "Apple pay",
        _a[PaymentMethods.online_payment_general] = "Card payment",
        _a[PaymentMethods.koinz_wallet] = "Wallet",
        _a[PaymentMethods.koinz_wallet_online_general] = "Wallet + Card payment",
        _a[PaymentMethods.koinz_wallet_online_apple_pay] = "Wallet + Apple pay",
        _a);
    var paymentStatusText = (_b = {},
        _b[TransactionState.created] = "Created",
        _b[TransactionState.authorized] = "Created",
        _b[TransactionState.captured] = "The online payment is captured.",
        _b[TransactionState.voided] = "Refund initiated",
        _b[TransactionState.pending] = "Pending",
        _b[TransactionState.failed] = "The online payment failed",
        _b[TransactionState.refunded] = "Refund initiated",
        _b[TransactionState.authenticated] = "Authenticated",
        _b[TransactionState.refunded_to_wallet] = "Refunded to wallet",
        _b);
    var isThreeHoursAgo = function (date) {
        if (!date) {
            return false;
        }
        // 3 hours
        var hours = 10800000;
        var threeHourAgo = Date.now() - hours;
        return date < threeHourAgo;
    };
    var handleSelectItemChange = function (item) {
        setReasonSelected({
            en_text: item.label,
            ar_text: item.ar_label ? item.ar_label : "",
            reason_id: item.value,
        });
    };
    var handleSelectRefundItemChange = function (item) {
        setRefundSelected({
            en_text: item.label,
            ar_text: item.ar_label ? item.ar_label : "",
            refund_id: item.value,
        });
    };
    var _y = React.useState(false), orderRejectionModal = _y[0], setOrderRejectionModal = _y[1];
    var handleOpenOrderRejectionModal = function () { return setOrderRejectionModal(true); };
    var handleCloseOrderRejectionModal = function () { return setOrderRejectionModal(false); };
    var _z = React.useState({
        openCancellationModal: false,
        with_no_penalty: false,
    }), _0 = _z[0], with_no_penalty = _0.with_no_penalty, openCancellationModal = _0.openCancellationModal, setOrderCancellationModal = _z[1];
    var handleOpenCancelWithoutPenaltyModal = function () {
        dispatch(getRefundPolicy({ order_id: order_id }));
        setOrderCancellationModal({
            with_no_penalty: true,
            openCancellationModal: true,
        });
    };
    var handleCloseOrderCancellationModal = function () {
        setOrderCancellationModal({
            with_no_penalty: false,
            openCancellationModal: false,
        });
    };
    var handleOpenCancelWithPenaltyModal = function () {
        dispatch(getRefundPolicy({ order_id: order_id }));
        setOrderCancellationModal({
            with_no_penalty: false,
            openCancellationModal: true,
        });
    };
    React.useEffect(function () {
        if (!openCancellationModal) {
            setReasonSelected({
                ar_text: "",
                en_text: "",
                reason_id: undefined,
            });
        }
    }, [openCancellationModal]);
    React.useEffect(function () {
        if (loading === LoadingStatus.success) {
            handleCloseOrderCancellationModal();
            handleCloseOrderRejectionModal();
            dispatch(clearPrevState());
        }
    }, [loading]);
    var cancelWithOrNoPenalty = function (no_penalty) {
        if (no_penalty) {
            cancelByBranch();
        }
        else {
            cancelOrderStatus();
        }
    };
    var _1 = useRefundingHook({
        currency: currency,
        order_id: order_id,
        total_invoices: total_invoice,
    }), _2 = _1.refundingCashToBankAccount, mutateRefundingCashToBankAccount = _2.mutateRefundingCashToBankAccount, openRefundingCashToBankAccountPopup = _2.openRefundingCashToBankAccountPopup, refundingCashToBankAccountStatus = _2.refundingCashToBankAccountStatus, setOpenRefundingCashToBankAccountPopup = _2.setOpenRefundingCashToBankAccountPopup, _3 = _1.refundingCreditsToWallet, mutateRefundingCreditsToWallet = _3.mutateRefundingCreditsToWallet, openRefundingCreditsToWalletPopup = _3.openRefundingCreditsToWalletPopup, refundingCreditsToWalletStatus = _3.refundingCreditsToWalletStatus, setOpenRefundingCreditsToWalletPopup = _3.setOpenRefundingCreditsToWalletPopup;
    var refundToBankAccountDisabled = React.useMemo(function () {
        var _a;
        return Boolean(orderDetails.payment_method === PaymentMethods.koinz_wallet ||
            orderDetails.payment_method ===
                PaymentMethods.koinz_wallet_online_general ||
            orderDetails.payment_method ===
                PaymentMethods.koinz_wallet_online_apple_pay ||
            orderDetails.transaction_state === TransactionState.refunded ||
            orderDetails.transaction_state === TransactionState.voided ||
            refundSelected.refund_id === "card_refund" ||
            (orderDetails.wallet_Transaction_status ===
                TransactionState.refunded &&
                !refundSelected.refund_id) ||
            (((_a = orderDetails) === null || _a === void 0 ? void 0 : _a.transaction_state) === TransactionState.captured &&
                status === OrderStatus.canceled_by_agent));
    }, [
        orderDetails.payment_method,
        orderDetails.transaction_state,
        refundSelected,
        status,
    ]);
    var refundToWalletDisabled = React.useMemo(function () {
        var _a;
        return Boolean((typeof orderDetails.transaction_state === "string" &&
            orderDetails.transaction_state !== TransactionState.captured) ||
            orderDetails.wallet_Transaction_status === TransactionState.voided ||
            refundSelected.refund_id === "wallet_refund" ||
            refundSelected.refund_id === "card_refund" ||
            (orderDetails.wallet_Transaction_status ===
                TransactionState.refunded &&
                !refundSelected.refund_id) ||
            (((_a = orderDetails) === null || _a === void 0 ? void 0 : _a.transaction_state) === TransactionState.captured &&
                status === OrderStatus.canceled_by_agent));
    }, [
        orderDetails.transaction_state,
        orderDetails.wallet_Transaction_status,
        refundSelected,
        status,
    ]);
    var getTotalInvoices = React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (total_invoice >= Number(((_a = orderDetails) === null || _a === void 0 ? void 0 : _a.payment_Transaction_amount) || 0) &&
            total_invoice >= Number(((_b = orderDetails) === null || _b === void 0 ? void 0 : _b.wallet_Transaction_amount) || 0)) {
            return Number(total_invoice || 0);
        }
        else if (total_invoice <= Number(((_c = orderDetails) === null || _c === void 0 ? void 0 : _c.payment_Transaction_amount) || 0) &&
            Number(((_d = orderDetails) === null || _d === void 0 ? void 0 : _d.wallet_Transaction_amount) || 0) <
                Number(((_e = orderDetails) === null || _e === void 0 ? void 0 : _e.payment_Transaction_amount) || 0)) {
            return Number(((_f = orderDetails) === null || _f === void 0 ? void 0 : _f.payment_Transaction_amount) || 0);
        }
        else
            return Number(((_g = orderDetails) === null || _g === void 0 ? void 0 : _g.wallet_Transaction_amount) || 0);
    }, [
        (_c = orderDetails) === null || _c === void 0 ? void 0 : _c.wallet_Transaction_amount,
        (_d = orderDetails) === null || _d === void 0 ? void 0 : _d.payment_Transaction_amount,
        total_invoice,
    ]);
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.wrapper) },
        React.createElement("div", { className: styles.header },
            React.createElement("span", { className: styles.title }, "Order Details"),
            open && React.createElement(EditReceipt, { closeModal: closeModalHandler }),
            React.createElement(GenericButtonWithIcon, { onClick: openModalHandler, label: "Edit receipt", iconPath: require("../../../assets/group-15.svg"), className: styles.btn, IconClass: styles.btnIcon })),
        React.createElement("div", { className: styles.itemsWrapper }, (orderDetails.items || []).map(function (it, index) {
            return (React.createElement(SingleItem, { key: index, isMobile: isMobile, currency: currency, itemName: it.en_name + " - " + it.ar_name, itemNum: it.quantity, itemPrice: it.price, modifiers: it.modifiers_group, category_ar_name: it.category_ar_name, category_en_name: it.category_en_name, comment: it.comment }));
        })),
        React.createElement("div", { className: styles.payment },
            React.createElement("div", { className: styles.promotionWrapper },
                React.createElement("span", { className: styles.promotionTitle }, "Promo Code"),
                promotion === null ? (React.createElement("span", { className: styles.promo }, "No code applied")) : (React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                    React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                        ((_e = promotion) === null || _e === void 0 ? void 0 : _e.masked_promo_code) && (React.createElement("span", { className: styles.userPromoTitle }, "User promo")),
                        React.createElement("div", { className: styles.promoCodeWrapper },
                            React.createElement("span", { className: styles.promoCode }, (_f = promotion) === null || _f === void 0 ? void 0 : _f.promo_code),
                            React.createElement("div", { style: { margin: "0 .3rem" } }),
                            React.createElement(Help, { placement: "top-end", title: React.createElement("div", null,
                                    React.createElement("span", { style: {
                                            fontSize: "1.2rem",
                                            textAlign: "right",
                                            color: "#ffffff",
                                        } }, (_g = promotion) === null || _g === void 0 ? void 0 : _g.desc)), arrow: true },
                                React.createElement("img", { className: styles.helpIcon, src: require("../../../assets/help.svg") })))),
                    promotion && ((_h = promotion) === null || _h === void 0 ? void 0 : _h.masked_promo_code) && (React.createElement("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 26px",
                        } },
                        React.createElement("span", { className: styles.userPromoTitle }, "Masked promo"),
                        React.createElement("span", { className: styles.maskedPromoCode }, promotion.masked_promo_code))))),
                merchantVisibility === "invisible" && (React.createElement("span", { className: styles.hiddenPromoWrapper },
                    React.createElement("span", { className: styles.hiddenPromo }, "Hidden promo")))),
            React.createElement("div", { className: styles.invoice },
                React.createElement("span", null, "Total invoice"),
                promotion === null || ((_j = promotion) === null || _j === void 0 ? void 0 : _j.type) === "cashback_percentage" ? (React.createElement("span", { className: styles.totalInvoiceTitle },
                    total_invoice.toFixed(2),
                    currency)) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: styles.beforePromo },
                        total_invoices_without_promo_code,
                        " ",
                        currency),
                    React.createElement("span", { className: styles.totalInvoiceTitle },
                        total_invoice.toFixed(2),
                        " ",
                        currency)))),
            React.createElement("div", { className: styles.method },
                React.createElement("span", null, "Payment method"),
                React.createElement("span", { className: styles.paymentTitle }, paymentTitle[orderDetails.payment_method]),
                React.createElement("span", { className: styles.paymentTitle }, orderDetails.payment_gateway)),
            (orderDetails.wallet_Transaction_status ||
                orderDetails.transaction_state) && (React.createElement("div", { className: styles.method, style: orderDetails.transaction_state ||
                    orderDetails.wallet_Transaction_status
                    ? { width: "100%" }
                    : {} },
                React.createElement("span", null, "Payment status"),
                React.createElement("div", null, orderDetails.transaction_state &&
                    orderDetails.wallet_Transaction_status ? (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                        " ",
                        "Card:",
                        " ",
                        React.createElement("span", { style: {
                                fontSize: "14px",
                                fontWeight: "normal",
                                marginLeft: "4px",
                            } }, paymentStatusText[orderDetails.transaction_state])),
                    React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                        "Wallet:",
                        React.createElement("span", { style: {
                                fontSize: "14px",
                                fontWeight: "normal",
                                marginLeft: "4px",
                            } }, paymentStatusText[orderDetails.wallet_Transaction_status])))) : (React.createElement("span", { className: styles.paymentTitle }, paymentStatusText[(_k = orderDetails.transaction_state, (_k !== null && _k !== void 0 ? _k : orderDetails.wallet_Transaction_status))]))))),
            React.createElement(RefundSummary, { currency: currency, order_id: order_id, token: token, loading: loadingStatus, status: status })),
        React.createElement(React.Fragment, null,
            orderRejectionModal && (React.createElement(OrderCancellationReasons, { order_id: order_id, type: "rejecting", with_no_penalty: false, onChange: handleSelectItemChange, open: orderRejectionModal, confirm: rejectOrder, onClose: handleCloseOrderRejectionModal, value: reasonSelected, store_id: store_id, payment_method: orderDetails.payment_method, currency: currency })),
            openCancellationModal && (React.createElement(OrderCancellationReasons, { order_id: order_id, type: "canceling", onChangeRedundMethod: handleSelectRefundItemChange, onChange: handleSelectItemChange, open: openCancellationModal, with_no_penalty: with_no_penalty, confirm: cancelWithOrNoPenalty, onClose: handleCloseOrderCancellationModal, value: reasonSelected, refundValue: refundSelected, store_id: store_id, payment_method: orderDetails.payment_method, currency: currency }))),
        status in acceptingStates && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null,
            React.createElement(GenericButtonWithIcon, { onClick: handleOpenOrderRejectionModal, className: styles.reject, label: "Reject" }),
            routing_method === RoutingMethodPOS.pos &&
                orderDetails.transaction_state !== TransactionState.pending ? (React.createElement(GenericButtonWithIcon, { onClick: acceptOrderStatus, className: isThreeHoursAgo(created_at)
                    ? styles.accept
                    : styles.btnDisabled, disabled: isThreeHoursAgo(created_at) ? false : true, label: "Accept" })) : (orderDetails.transaction_state !== TransactionState.pending && (React.createElement(GenericButtonWithIcon, { onClick: function () { return setAcceptOrderState(true); }, className: styles.accept, label: "Accept" }))))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        openModal && (React.createElement(RefundModal, { customer_name: customer_name, onClose: handleClose, open: openModal, order_id: order_id })),
        status in refundingStates &&
            orderDetails.payment_method !== PaymentMethods.cash && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null,
            ordering_source !== OrderingSource.ordering_portal && (React.createElement(GenericButton, { className: refundToWalletDisabled
                    ? styles.disabledRefundBtn
                    : styles.refundingBtn, disabled: refundToWalletDisabled, onClick: function () {
                    setShowWalletPincodeModal(!showWalletPincodeModal);
                } }, "Send credit to wallet")),
            React.createElement(GenericButton, { className: refundToBankAccountDisabled
                    ? styles.disabledRefundBtn
                    : styles.refundingBtn, disabled: refundToBankAccountDisabled, onClick: function () {
                    return setShowBanckAccountPincodeModal(!showBanckAccountPincodeModal);
                } }, "Send cash to bank account"),
            openRefundingCreditsToWalletPopup && (React.createElement(OrderConfirmationPopup, { status: refundingCreditsToWalletStatus, onConfirm: function () { return mutateRefundingCreditsToWallet(); }, avatar: customer_image, currency: currency, open: openRefundingCreditsToWalletPopup, onClose: function () { return setOpenRefundingCreditsToWalletPopup(false); }, disabledConfirmationButton: refundingCreditsToWalletStatus === "loading", user_name: customer_name, description: "Send credit to customer wallet", total_invoices: getTotalInvoices })),
            openRefundingCashToBankAccountPopup && (React.createElement(OrderConfirmationPopup, { status: refundingCashToBankAccountStatus, onConfirm: function () { return mutateRefundingCashToBankAccount(); }, avatar: customer_image, currency: currency, open: openRefundingCashToBankAccountPopup, onClose: function () {
                    return setOpenRefundingCashToBankAccountPopup(false);
                }, disabledConfirmationButton: refundingCashToBankAccountStatus === "loading" ||
                    orderDetails.transaction_state === "refunded", user_name: customer_name, description: "Send cash to bank account", total_invoices: getTotalInvoices })))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        status in finalStates && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null,
            React.createElement("div", null, call_store_to_cancel_with_penalty ||
                routing_method === RoutingMethodPOS.pos ? (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-tip": true, "data-for": "tt" + order_id },
                    React.createElement(GenericButtonWithIcon, { onClick: handleOpenCancelWithPenaltyModal, disabled: true, className: styles.btnDisabled, label: "Cancel with penalty" })),
                React.createElement(ReactTooltip, { id: "tt" + order_id, place: "top", effect: "solid" },
                    React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                        React.createElement("span", { style: { fontSize: "1.2ren" } },
                            " ",
                            "Call the branch to cancel this order"),
                        React.createElement("span", { style: { fontSize: "1.2ren" } },
                            " ",
                            "\u0627\u062A\u0635\u0644 \u0628\u0627\u0644\u0645\u0637\u0639\u0645 \u0644\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u0637\u0644\u0628"))))) : (React.createElement(GenericButtonWithIcon, { onClick: handleOpenCancelWithPenaltyModal, className: styles.reject, label: "Cancel with penalty" }))),
            React.createElement(React.Fragment, null, call_store_to_cancel_with_penalty ||
                routing_method === RoutingMethodPOS.pos ? (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-tip": true, "data-for": "tt" + order_id },
                    React.createElement(GenericButtonWithIcon, { onClick: handleOpenCancelWithoutPenaltyModal, disabled: true, className: styles.btnDisabled, label: "Cancel with no penalty" })),
                React.createElement(ReactTooltip, { id: "tt" + order_id, place: "top", effect: "solid" },
                    React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                        React.createElement("span", { style: { fontSize: "1.2ren" } },
                            " ",
                            "Call the branch to cancel this order"),
                        React.createElement("span", { style: { fontSize: "1.2ren" } },
                            " ",
                            "\u0627\u062A\u0635\u0644 \u0628\u0627\u0644\u0645\u0637\u0639\u0645 \u0644\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u0637\u0644\u0628"))))) : (React.createElement(GenericButtonWithIcon, { onClick: handleOpenCancelWithoutPenaltyModal, className: styles.reject, label: "Cancel with no penalty" }))))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        status === OrderStatus.out_for_delivery && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null, routing_method === RoutingMethodPOS.pos ? (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsDelivered, disabled: isThreeHoursAgo(created_at) ? false : true, className: isThreeHoursAgo(created_at)
                ? styles.accept
                : styles.btnDisabled, label: "Mark as delivered" })) : (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsDelivered, 
            // disabled={canChangeOrderStatus}
            className: styles.accept, label: "Mark as delivered" })))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        orderDetails.order_type === OrderType.delivery &&
            status === OrderStatus.accepted && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus && !dispatchingLoading ? (React.createElement(React.Fragment, null, routing_method === RoutingMethodPOS.pos ? (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsOutForDelivery, className: isThreeHoursAgo(created_at)
                ? styles.accept
                : styles.btnDisabled, label: "Mark as out for delivery", disabled: isThreeHoursAgo(created_at) ? false : true })) : (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsOutForDelivery, disabled: false, className: styles.accept, label: "Mark as out for delivery" })))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        orderDetails.order_type === OrderType.pickup &&
            status === OrderStatus.accepted && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null, routing_method === RoutingMethodPOS.pos ? (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsReadyForPickup, className: isThreeHoursAgo(created_at)
                ? styles.accept
                : styles.btnDisabled, disabled: isThreeHoursAgo(created_at) ? false : true, label: "Mark as ready for pickup" })) : (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsReadyForPickup, className: styles.accept, label: "Mark as ready for pickup" })))) : (React.createElement(DotLoader, { color: "#f09440" })))),
        showWalletPincodeModal && (React.createElement(PinCodeModal, { confirm: function () { return setOpenRefundingCreditsToWalletPopup(true); }, open: showWalletPincodeModal, onClose: function () { return setShowWalletPincodeModal(!showWalletPincodeModal); } })),
        showBanckAccountPincodeModal && (React.createElement(PinCodeModal, { confirm: function () { return setOpenRefundingCashToBankAccountPopup(true); }, open: showBanckAccountPincodeModal, onClose: function () {
                return setShowBanckAccountPincodeModal(!showBanckAccountPincodeModal);
            } })),
        status === OrderStatus.ready_for_pickup && (React.createElement("div", { className: styles.btnWrapper }, canChangeOrderStatus ? (React.createElement(React.Fragment, null, routing_method === RoutingMethodPOS.pos ? (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsGotPickedUp, className: isThreeHoursAgo(created_at)
                ? styles.accept
                : styles.btnDisabled, disabled: isThreeHoursAgo(created_at) ? false : true, label: "Mark as got picked up" })) : (React.createElement(GenericButtonWithIcon, { onClick: handleMarkOrderAsGotPickedUp, className: styles.accept, label: "Mark as got picked up" })))) : (React.createElement(DotLoader, { color: "#f09440" }))))));
};
export default hot(module)(OrderInDetails);
