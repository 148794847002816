var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { clearPrevOrderDetailsAction, getOrderDetailsAction, } from "../../constants/index";
import { editOrderDetails, getOrderDetailsSuccess, } from "../actions/get-order-details-action";
import { TransactionState } from "../../constants/types";
import { OrderType } from "../../components/OrderDetails/OrderInfo/CustomerInformation";
import { PaymentMethods } from "../../components/OrderDetails/OrderDetails";
import { markOrderAsDeliveredSuccess, markOrderAsGotPickedUpSuccess, refundOrderSuccess, } from "../actions/order-status-action";
var genTransactionStateFn = function (transaction_state) {
    return function (state) { return (__assign(__assign({}, state), { orderDetails: __assign(__assign({}, state.orderDetails), { transaction_state: transaction_state }) })); };
};
var initialState = {
    orderDetails: {
        items: [],
        payment_method: PaymentMethods.cash,
        payment_transaction_id: "",
        total_price: 0,
        promotion: {
            created_at: 0,
            customers_file_path: "",
            desc: "",
            discount_value: 0,
            end_time: 0,
            id: "",
            max_discount_value: 0,
            max_usage_times: 0,
            min_threshold: 0,
            promo_code: "",
            start_time: 0,
            type: "",
            updated_at: 0,
            user_id: "",
        },
        total_invoices_without_promo_code: 0,
        order_type: OrderType.pickup,
        district_ar_name: "",
        district_en_name: "",
        sub_district_ar_name: "",
        sub_district_en_name: "",
        comment: "",
    },
};
export var orderDetailsReducer = withLoadingReducer(reducer(__assign({}, initialState), on(getOrderDetailsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { orderDetails: payload }));
}), on(editOrderDetails, function (state, _a) {
    var payload = _a.payload;
    var _b, _c, _d, _e;
    return __assign(__assign({}, state), { orderDetails: __assign(__assign({}, state.orderDetails), { transaction_state: (_c = (_b = payload) === null || _b === void 0 ? void 0 : _b.order) === null || _c === void 0 ? void 0 : _c.transaction_state, wallet_Transaction_status: (_e = (_d = payload) === null || _d === void 0 ? void 0 : _d.order) === null || _e === void 0 ? void 0 : _e.wallet_transaction_status }) });
}), on(markOrderAsGotPickedUpSuccess, genTransactionStateFn(TransactionState.captured)), on(markOrderAsDeliveredSuccess, genTransactionStateFn(TransactionState.captured)), on(refundOrderSuccess, genTransactionStateFn(TransactionState.refunded)), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { orderDetails: initialState.orderDetails })); })), getOrderDetailsAction);
