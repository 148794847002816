var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { cancelledByBranch, cancelledByBranchFailure, cancelledByBranchSuccess, cancelOrder, cancelOrderFailure, cancelOrderSuccess, clearPrevState, orderStatusSuccess, rejectOrderStatus, rejectOrderStatusFailure, rejectOrderStatusSuccess, } from "../actions/order-status-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import { clearPrevOrderDetailsAction, orderStatusAction, } from "../../constants/index";
import { editOrderStatusReason, editOrderStatusReasonFailure, editOrderStatusReasonSuccess, } from "../actions/reasons-action";
export var orderStatusReducer = withLoadingReducer(reducer({
    status: {
        statuses: [],
    },
    loading: undefined,
    refunding_status: undefined,
}, on(rejectOrderStatus, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.loading })); }), on(rejectOrderStatusSuccess, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.success })); }), on(rejectOrderStatusFailure, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.failed })); }), on(cancelledByBranch, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.loading, refunding_status: LoadingStatus.loading })); }), on(cancelledByBranchSuccess, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.success, refunding_status: LoadingStatus.success })); }), on(cancelledByBranchFailure, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.failed, refunding_status: LoadingStatus.failed })); }), on(orderStatusSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { status: __assign(__assign({}, state.status), payload) }));
}), on(cancelOrder, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.loading, refunding_status: LoadingStatus.loading })); }), on(cancelOrderSuccess, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.success, refunding_status: LoadingStatus.success })); }), on(cancelOrderFailure, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.failed, refunding_status: LoadingStatus.failed })); }), on(editOrderStatusReason, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.loading })); }), on(editOrderStatusReasonFailure, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.failed })); }), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { status: {
        statuses: [],
    } })); }), on(clearPrevState, function (state, _a) { return (__assign(__assign({}, state), { loading: undefined })); }), on(rejectOrderStatusSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { loading: LoadingStatus.success, status: __assign(__assign({}, state.status.statuses), { statuses: state.status.statuses.map(function (element) {
                var _a, _b;
                if (element.order_status_reason &&
                    element.order_status_reason.id !== ((_a = payload.reason) === null || _a === void 0 ? void 0 : _a.reason_id)) {
                    return __assign(__assign({}, element), { order_status_reason: {
                            ar_text: element.order_status_reason.ar_text
                                ? payload.reason.ar_text
                                : "",
                            en_text: payload.reason.en_text,
                            id: (_b = payload.reason) === null || _b === void 0 ? void 0 : _b.reason_id,
                        } });
                }
                return element;
            }) }) }));
}), on(cancelOrderSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { loading: LoadingStatus.success, status: __assign(__assign({}, state.status.statuses), { statuses: state.status.statuses.map(function (element) {
                var _a, _b;
                if (element.order_status_reason &&
                    element.order_status_reason.id !== ((_a = payload) === null || _a === void 0 ? void 0 : _a.reason_id)) {
                    return __assign(__assign({}, element), { order_status_reason: {
                            ar_text: element.order_status_reason.ar_text
                                ? payload.ar_text
                                : "",
                            en_text: payload.en_text,
                            id: (_b = payload) === null || _b === void 0 ? void 0 : _b.reason_id,
                        } });
                }
                return element;
            }) }) }));
}), on(editOrderStatusReasonSuccess, function (state, _a) {
    var payload = _a.payload;
    return __assign(__assign({}, state), { loading: LoadingStatus.success, status: __assign(__assign({}, state.status.statuses), { statuses: state.status.statuses.map(function (element) {
                var _a, _b, _c;
                if (element.order_status_reason &&
                    element.order_status_reason.id !== ((_a = payload) === null || _a === void 0 ? void 0 : _a.id)) {
                    return __assign(__assign({}, element), { order_status_reason: {
                            ar_text: element.order_status_reason.ar_text
                                ? payload.ar_text
                                : "",
                            en_text: payload.en_text,
                            id: (_b = payload) === null || _b === void 0 ? void 0 : _b.id,
                        } });
                }
                else if (!element.order_status_reason) {
                    return __assign(__assign({}, element), { order_status_reason: {
                            ar_text: payload.ar_text,
                            en_text: payload.en_text,
                            id: (_c = payload) === null || _c === void 0 ? void 0 : _c.id,
                        } });
                }
                return element;
            }) }) });
})), orderStatusAction);
