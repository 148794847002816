import axios from "./axios";
import * as Requests from "./requests";
export var login = function (email, password) {
    return axios.post(Requests.login, {
        email: email,
        password: password
    }, {
        baseURL: process.env.ADMIN_ENDPOINT
    });
};
