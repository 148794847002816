var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Notification from 'rc-notification';
import * as React from 'react';
import * as styles from './styles.module.sass';
var notification;
Notification.newInstance({
    closeIcon: (React.createElement("div", { className: styles.closeWrapper }))
}, function (n) { return (notification = n); });
export var notifyCustomContent = function (options) {
    notification.notice(__assign({ duration: 5, style: {
            position: 'fixed',
            bottom: '6rem',
            left: '6rem',
            right: undefined,
            transition: 'all 1s',
            direction: 'ltr',
            textAlign: 'left',
            opacity: 1,
            display: 'flex',
            zIndex: 9999
        }, closable: true }, options));
};
var networkConnection = function (content, duration) {
    if (duration === void 0) { duration = 3000; }
    notifyCustomContent({
        duration: duration / 1000,
        content: (React.createElement("div", { className: styles.networkWrapper },
            React.createElement("div", { className: styles.contentWrapper },
                React.createElement("img", { src: require('../../../assets/icon-wifi.svg') }),
                React.createElement("span", { className: styles.networkError }, content)),
            React.createElement("span", { className: styles.refresh, onClick: function () { return window.location.reload(); } }, "Refresh")))
    });
};
var error = function (content, duration, padding) {
    if (duration === void 0) { duration = 3000; }
    notifyCustomContent({
        duration: duration / 1000,
        content: (React.createElement("div", { className: styles.wrongCode, style: {
                padding: padding ? 0 : undefined,
                paddingLeft: padding ? '1.6rem' : undefined,
                paddingRight: padding ? '19.4rem' : undefined,
                display: padding ? 'flex' : undefined,
                justifyContent: padding ? 'center' : undefined,
                alignItems: padding ? 'center' : undefined
            } },
            React.createElement("p", { className: styles.content, style: { margin: padding ? '0' : '0 2px' } }, content)))
    });
};
var success = function (content, duration, padding) {
    if (duration === void 0) { duration = 3000; }
    notifyCustomContent({
        duration: duration / 1000,
        content: (React.createElement("div", { className: styles.rightCode, style: {
                padding: padding ? 0 : undefined,
                paddingLeft: padding ? '1.6rem' : undefined,
                paddingRight: padding ? '19.4rem' : undefined,
                display: padding ? 'flex' : undefined,
                justifyContent: padding ? 'center' : undefined,
                alignItems: padding ? 'center' : undefined
            } },
            React.createElement("p", { className: styles.content, style: { margin: padding ? '0' : '0 2px' } }, content)))
    });
};
var info = function (content, duration) {
    if (duration === void 0) { duration = 3000; }
    notifyCustomContent({
        duration: duration / 1000,
        content: (React.createElement("div", { className: styles.rightCode },
            React.createElement("p", { className: styles.content }, content)))
    });
};
export var closeAllNotices = function () {
    return notification.component.state.notices.forEach(function (notice) {
        return notification.removeNotice(notice.key);
    });
};
export var customNotify = {
    error: error,
    success: success,
    info: info,
    networkConnection: networkConnection
};
