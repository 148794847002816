import * as React from "react";
import * as styles from "./styles.module.sass";
var SingleContactItem = function (props) {
    var phone_number = props.phone_number, branch_name = props.branch_name, id = props.id, store_name = props.store_name;
    return (React.createElement("div", { className: styles.content },
        React.createElement("div", { className: styles.phoneCall },
            React.createElement("img", { src: require("../../../../../assets/phone.svg") })),
        React.createElement("div", { className: styles.infoWrapper },
            React.createElement("div", { className: styles.brandWrapper },
                React.createElement("span", { className: styles.title },
                    store_name,
                    " - ",
                    branch_name)),
            React.createElement("span", { className: styles.phoneNumber }, phone_number))));
};
export default SingleContactItem;
