import { createMuiTheme } from "@material-ui/core";
export var MateriaTheme = createMuiTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc",
            },
            colorSecondary: {
                "&$checked": {
                    // Controls checked color for the thumb
                    color: "#f09440",
                },
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 1,
                height: "120%",
                backgroundColor: "#eee",
                "$checked$checked + &": {
                    // Controls checked color for the track
                    opacity: 1,
                    backgroundColor: "#eee",
                },
            },
        },
    },
});
