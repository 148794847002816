var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { RoutingMethodPOS } from "../../constants/types";
import { getStoreInfoAction } from "../../constants";
import { getStoreInfoSuccess } from "../actions/get-order-details-action";
import { clearPrevOrderDetailsAction } from "../../constants/index";
var initialState = {
    storeInfo: {
        routing_method: RoutingMethodPOS.standalone,
        pos: "",
        allow_reach: 0,
        allow_leaderboard: 0,
        activation_date: 0,
        timezone: "",
        payment_status: 0,
        created_at: 0,
        converting_ratio: 0,
        country_code: "",
        active_leaderboard: 0,
        country_iso_code: "",
        background_color: "",
        updated_at: 0,
        payment_notification: 0,
        manager_id: "",
        api_key: "",
        end_trial: 0,
        name: "",
        cover_image: "",
        id: "",
        payment_date: 0,
        store_image: "",
        trial_duration: 0,
        status: 0,
        delivery_time: 0,
        delivery_fees: 0,
    },
};
export var StoreInfoReducer = withLoadingReducer(reducer(__assign({}, initialState), on(getStoreInfoSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { storeInfo: payload }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { storeInfo: initialState.storeInfo })); })), getStoreInfoAction);
