import React from "react";
import OrderCancellationRefundDetails from "../OrderCancellationRefundDetails";
import WronglyPenalizedOrderAlert from "../WronglyPenalizedOrderAlert";
import Icons from "../../../../../types/icons";
import CanceledOrderTimeInfo from "../CanceledOrderTimeInfo";
var OrderCancellingRefundInfo = function (_a) {
    var refund_policy = _a.refund_policy, details = _a.details, currency = _a.currency, show = _a.show;
    var _b, _c, _d, _e, _f, _g;
    return (React.createElement(React.Fragment, null, show ? (React.createElement(React.Fragment, null,
        React.createElement(CanceledOrderTimeInfo, { icon: Icons.ClockIcon, minutes_elapsed_from_acceptance: (_b = refund_policy) === null || _b === void 0 ? void 0 : _b.minutes_elapsed_from_acceptance }),
        details.map(function (item) { return (React.createElement(OrderCancellationRefundDetails, { currency: item.currency, refunded_target: item.refunded_target, icon: item.icon, refund_percentage: item.refund_percentage, order_total_invoices: item.order_total_invoices })); }))) : (React.createElement(WronglyPenalizedOrderAlert, { order_cancellation_type: (_c = refund_policy) === null || _c === void 0 ? void 0 : _c.recommended_cancellation_type, currency: currency, icon: Icons.AlertIcon, customer_refund_percentage: (_d = refund_policy) === null || _d === void 0 ? void 0 : _d.customer_refund_percentage, merchant_refund_percentage: (_e = refund_policy) === null || _e === void 0 ? void 0 : _e.merchant_refund_percentage, order_total_invoices: (_f = refund_policy) === null || _f === void 0 ? void 0 : _f.order_total_invoices, minutes_elapsed_from_acceptance: (_g = refund_policy) === null || _g === void 0 ? void 0 : _g.minutes_elapsed_from_acceptance }))));
};
export default OrderCancellingRefundInfo;
