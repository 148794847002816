var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getBranchesStatusSuccess, searchForBranches, } from "../actions/branches-action";
import { getBranchesStatusAction } from "../../constants/index";
import { convertToIds } from "./lib";
export var branchesStatusReducer = withLoadingReducer(reducer({
    branches: [],
    shownBranches: [],
    branchesById: {},
}, on(getBranchesStatusSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { branches: convertToIds(payload), shownBranches: convertToIds(payload), branchesById: payload.reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
        }, {}) }));
}), on(searchForBranches, function (state, action) {
    var branches = state.branches, branchesById = state.branchesById;
    var term = action.payload.trim().toLowerCase();
    if (!term) {
        return __assign(__assign({}, state), { shownBranches: branches });
    }
    var searchedBranches = branches.filter(function (id) {
        var branch = branchesById[id];
        var branchName = branch.name.toLocaleLowerCase();
        var nameMatches = branchName.includes(term);
        return nameMatches;
    });
    var f = searchedBranches.filter(function (i) { return i.length; });
    return __assign(__assign({}, state), { shownBranches: f });
})), getBranchesStatusAction);
