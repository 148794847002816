import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { RoutingMethodPOS, } from "../../../../constants/types";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import DeliveryInformation from "./DeliveryInformation";
import { OrderType } from "../CustomerInformation";
import { useToggleState } from "../../../hooks/useToggleState";
import { Tooltip } from "reactstrap";
import Geocode from "react-geocode";
import { posAddressState } from "../../../atoms";
import { useRecoilState } from "recoil";
export var useTruncationFull = function (str, maxLength) {
    if (maxLength === void 0) { maxLength = 12; }
    return React.useMemo(function () {
        var truncatedName = str.slice(0, maxLength);
        return truncatedName.length < str.length
            ? truncatedName + "..."
            : truncatedName;
    }, [str]);
};
var uuid = function () {
    return Math.floor(Math.random() * 100000);
};
export var TextOverflowToolTip = function (_a) {
    var maxLength = _a.maxLength, text = _a.text, _b = _a.placement, placement = _b === void 0 ? "top" : _b;
    var id = React.useMemo(uuid, []);
    var _c = useToggleState(), tooltipOpen = _c[0], toggleToolTip = _c[1];
    return (React.createElement("div", { id: "dd" + id },
        (text || "").length > maxLength && (React.createElement(Tooltip, { style: { fontSize: "1.4rem" }, placement: placement, target: "#dd" + id, toggle: toggleToolTip, isOpen: tooltipOpen }, text)),
        React.createElement("span", null, useTruncationFull(text || "", maxLength))));
};
var BrandInfo = function (props) {
    var brandInfo = props.brandInfo, store = props.store, currency = props.currency, orderType = props.orderType, delivery_fees = props.delivery_fees, delivery_time = props.delivery_time, isMobile = props.isMobile, address = props.address;
    var _a = useRecoilState(posAddressState), posAddress = _a[0], setPosAddress = _a[1];
    React.useEffect(function () {
        Geocode.setApiKey("AIzaSyCz-fZ85mMDuLySzhSPHhci1SXi_i9-2_E");
        Geocode.fromLatLng(String(brandInfo.lat), String(brandInfo.lng)).then(function (response) {
            setPosAddress(response.results[0].formatted_address);
        });
    }, [posAddress]);
    var Av = React.useMemo(function () {
        return store.name !== undefined
            ? store.name
                .split(/\s/)
                .reduce(function (response, word) { return response + word.slice(0, 1); }, "")
            : "DF";
    }, [store]);
    var copyLink = function () {
        if (brandInfo.lng && brandInfo.lat) {
            navigator.clipboard.writeText("http://maps.google.com/?q=" + brandInfo.lat + "," + brandInfo.lng);
        }
    };
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.wrapper) },
        React.createElement("span", { className: styles.brandTitle }, "Brand information"),
        React.createElement("div", { className: styles.brandWrapper },
            React.createElement("div", { className: styles.brand },
                React.createElement("div", { className: styles.brandAvatarWrapper }, Av),
                React.createElement("div", { className: styles.contactWrapper },
                    React.createElement("img", { src: require("../../../../assets/phone-call.svg"), style: { marginTop: "1.8rem" } }))),
            React.createElement("div", { className: styles.brand },
                React.createElement("div", { className: styles.brandInfo },
                    React.createElement("span", { className: styles.title }, store.name),
                    React.createElement("span", { className: styles.address },
                        React.createElement(TextOverflowToolTip, { text: store.routing_method === RoutingMethodPOS.pos
                                ? posAddress
                                : brandInfo.address, maxLength: 50 }))),
                React.createElement("div", { className: styles.phoneWrapper },
                    React.createElement("span", { className: styles.phone },
                        store.country_code,
                        " ",
                        brandInfo.numbers ? brandInfo.numbers[0] : "")))),
        React.createElement("div", { className: styles.brandAddressWrapper },
            React.createElement("span", { className: styles.brandAddressTitle }, "Branch Name"),
            React.createElement("span", { className: styles.brandAddress }, brandInfo.name)),
        React.createElement("div", { className: styles.brandAddressWrapper },
            React.createElement("span", { className: styles.brandAddressTitle }, "Branch address"),
            React.createElement("span", { className: styles.brandAddress },
                " ",
                React.createElement(TextOverflowToolTip, { text: store.routing_method === RoutingMethodPOS.pos
                        ? posAddress
                        : brandInfo.address, maxLength: 50 }))),
        React.createElement("div", { className: styles.brandAddressWrapper },
            React.createElement("span", { className: styles.brandAddressTitle }, "Branch app version"),
            React.createElement("span", { className: styles.brandAddress }, brandInfo.app_version || "Unknown")),
        React.createElement("div", null),
        brandInfo.lat && brandInfo.lng && (React.createElement("div", null,
            React.createElement("div", { className: styles.country },
                React.createElement("span", { className: styles.countryTitle }, "Latitude"),
                React.createElement("span", { className: styles.countrySubTitle }, brandInfo.lat)),
            React.createElement("div", { className: styles.country },
                React.createElement("span", { className: styles.countryTitle }, "Longitude"),
                React.createElement("span", { className: styles.countrySubTitle }, brandInfo.lng)),
            React.createElement("div", { className: styles.country },
                React.createElement("span", { className: styles.countryTitle }, "Location"),
                React.createElement("span", { className: styles.countrySubTitle }),
                React.createElement("span", { id: "copyLink", className: styles.copyLink, onClick: copyLink }, "Copy Link")))),
        React.createElement("div", { className: styles.brandAddressWrapper },
            React.createElement("span", { className: styles.brandAddressTitle }, "Branch location on map"),
            React.createElement("div", { style: isMobile
                    ? {
                        position: "relative",
                        width: "100%",
                        maxHeight: "30rem",
                    }
                    : { position: "relative", width: "32.2rem", height: "18.4rem" } },
                React.createElement(Map
                // google={window.google}
                , { 
                    // google={window.google}
                    style: { position: "absolute" }, google: props.google, center: { lat: brandInfo.lat, lng: brandInfo.lng }, zoom: 14 },
                    React.createElement(Marker, { position: { lat: brandInfo.lat, lng: brandInfo.lng } })))),
        orderType === OrderType.delivery && (React.createElement("div", null,
            React.createElement("div", { className: styles.separator }),
            React.createElement(DeliveryInformation, { delivery_zones: brandInfo.delivery_zones, currency: currency, delivery_fees: delivery_fees, delivery_time: delivery_time })))));
};
export default GoogleApiWrapper({
    apiKey: process.env.GOOGLE_API_KEY,
})(hot(module)(BrandInfo));
