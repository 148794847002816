var _a;
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["ignored_ready_for_pickup"] = "ignored_ready_for_pickup";
    OrderStatus["ignored_got_picked_up"] = "ignored_got_picked_up";
    OrderStatus["scheduled"] = "scheduled";
    OrderStatus["sent"] = "sent";
    OrderStatus["received"] = "received";
    OrderStatus["seen"] = "seen";
    OrderStatus["accepted"] = "accepted";
    OrderStatus["ready_for_pickup"] = "ready_for_pickup";
    OrderStatus["rejected"] = "rejected";
    OrderStatus["out_for_delivery"] = "out_for_delivery";
    OrderStatus["canceled_by_courier"] = "canceled_by_courier";
    OrderStatus["canceled_by_customer"] = "canceled_by_customer";
    OrderStatus["canceled_by_agent"] = "canceled_by_agent";
    OrderStatus["canceled_by_cashier"] = "canceled_by_cashier";
    OrderStatus["customer_no_show"] = "customer_no_show";
    OrderStatus["got_picked_up"] = "got_picked_up";
    OrderStatus["delivered"] = "delivered";
    OrderStatus["refunded"] = "refunded";
    OrderStatus["rejected_for_payment_failure"] = "rejected_for_payment_failure";
})(OrderStatus || (OrderStatus = {}));
// it has sto be moved to "types" ;
export var OrderTimingStatus;
(function (OrderTimingStatus) {
    OrderTimingStatus["justIn"] = "justIn";
    OrderTimingStatus["normal"] = "normal";
    OrderTimingStatus["overdue"] = "overdue";
})(OrderTimingStatus || (OrderTimingStatus = {}));
// it has to be moved to other "helpers"
export var orderStatusTranslations = (_a = {},
    _a[OrderStatus.ignored_got_picked_up] = "Ignored order got picked up",
    _a[OrderStatus.ignored_ready_for_pickup] = "Ignored order ready for pickup",
    _a[OrderStatus.accepted] = "accepted",
    _a[OrderStatus.got_picked_up] = "got picked up",
    _a[OrderStatus.ready_for_pickup] = "ready for pickup",
    _a[OrderStatus.received] = "received",
    _a[OrderStatus.rejected] = "rejected",
    _a[OrderStatus.seen] = "seen",
    _a[OrderStatus.sent] = "sent",
    _a[OrderStatus.canceled_by_agent] = "cancelled with penalty",
    _a[OrderStatus.canceled_by_customer] = "cancelled by customer",
    _a[OrderStatus.canceled_by_cashier] = "cancelled without penalty",
    _a[OrderStatus.customer_no_show] = "no show",
    _a[OrderStatus.scheduled] = "scheduled",
    _a[OrderStatus.delivered] = "delivered",
    _a[OrderStatus.out_for_delivery] = "out for delivery",
    _a[OrderStatus.refunded] = "refunded",
    _a[OrderStatus.canceled_by_courier] = "canceled without penalty",
    _a[OrderStatus.rejected_for_payment_failure] = "Failed payment creation",
    _a);
export var FinalStates = {
    canceled_by_courier: "canceled_by_courier",
    canceled_by_customer: "canceled_by_customer",
    canceled_by_agent: "canceled_by_agent",
    canceled_by_cashier: "canceled_by_cashier",
    customer_no_show: "customer_no_show",
    out_for_delivery: "out_for_delivery",
    got_picked_up: "got_picked_up",
    delivered: "delivered",
    rejected_for_payment_failure: "rejected_for_payment_failure",
};
export var FirstStates = {
    seen: OrderStatus.seen,
    sent: OrderStatus.sent,
    received: OrderStatus.received,
};
