import axios from "./axios";
import * as Requests from "./requests";
export var OrderAvailability;
(function (OrderAvailability) {
    OrderAvailability["inactive"] = "inactive";
    OrderAvailability["active"] = "active";
    OrderAvailability["closed"] = "closed";
    OrderAvailability["manual"] = "manual";
})(OrderAvailability || (OrderAvailability = {}));
export var getBrandsAPI = function (token, per_page, page, ordering_availability, routing_method, store_country_code, search) {
    return axios.get(Requests.storeURL, {
        params: {
            page: page,
            per_page: per_page,
            ordering_availability: ordering_availability,
            routing_method: routing_method,
            store_country_code: store_country_code,
            search: search,
        },
        headers: {
            token: token,
        },
    });
};
