var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { getUserProfileAction } from "../../constants";
import { ISBlocked } from "../../constants/types";
import { editUserProfile, getUserProfileFailure, getUserProfileSuccess, } from "../actions/user-profile-actions";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    error_message: {
        code: "",
        message: "",
    },
    user_info: {
        birth_date: undefined,
        country_code: "",
        email: "",
        gender: "",
        id: "",
        image: "",
        name: "",
        is_blocked: ISBlocked.false,
        num_online_orders: 0,
        num_redemptions: 0,
        num_store_visits: 0,
        num_visited_brands: 0,
        phone_number: "",
        wallet_balance: { balance: 0, currency: "EGP" },
        app_version: "",
        stores_names_customer_blocked_at: [],
    },
};
export var getUserProfileReducer = withLoadingReducer(reducer(initialState, on(getUserProfileSuccess, function (state, _a) {
    var payload = _a.payload;
    return __assign(__assign({}, state), { user_info: payload, error_message: {
            code: "",
            message: "",
        } });
}), on(getUserProfileFailure, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { error_message: payload }));
}), on(editUserProfile, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { user_info: __assign(__assign({}, state.user_info), { wallet_balance: payload.wallet_balance }) }));
})), getUserProfileAction);
