var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from "react";
import { useHistory, useLocation, useRouteMatch, useParams, } from "react-router-dom";
import queryString from "query-string";
export var useRouter = function () {
    var history = useHistory();
    var match = useRouteMatch();
    var params = useParams();
    var location = useLocation();
    return useMemo(function () {
        return {
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            query: __assign(__assign({}, queryString.parse(location.search)), params),
            match: match,
            location: location,
            history: history,
        };
    }, [location, history, match, params]);
};
