/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/camelcase */
import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Icon } from "./icon";
import moment from "moment-timezone";
import { orderStatusTranslations, OrderStatus } from "../../../types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import ReasonsModal from "./ReasonsModal";
import { useSelect } from "../../../helper/use-select";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import ErrorIcon from "../../../assets/errors.svg";
var OrderTracking = function (_a) {
    var _b;
    var order_id = _a.order_id, status = _a.status, order_type = _a.order_type, courier_name = _a.courier_name, isMobile = _a.isMobile, routingMethod = _a.routingMethod;
    var _c = React.useState("edit"), actionType = _c[0], setActionType = _c[1];
    var loadingStatus = useSelect(function (state) { return state.orderStatusReducer; }).loadingStatus;
    var len = React.useMemo(function () { return status.statuses.length; }, [status]);
    var OrderStatusToolTip = withStyles(function () { return ({
        tooltip: {
            backgroundColor: "rgba(26, 26, 26, 0.95)",
            color: "#fff",
            // maxWidth: 200,
            borderRadius: "4px",
            padding: "2rem",
        },
    }); })(Tooltip);
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var ownerTypeReadableFormat = (_b = {},
        _b["pos"] = "(POS)",
        _b["cashier"] = "(Cashier)",
        _b["customer"] = "(Customer)",
        _b["watcher"] = "(Watcher)",
        _b["call center user"] = "(Call center user)",
        _b["courier"] = "(Courier)",
        _b["system"] = "(System)",
        _b);
    return (React.createElement("div", { className: "" + (isMobile ? styles.mobileWrapper : styles.wrapper) },
        React.createElement("span", { className: styles.title }, "Order Tracking"),
        open && (React.createElement(ReasonsModal, { order_type: order_type, order_id: order_id, onClose: handleClose, open: open, actionType: actionType })),
        loadingStatus === LoadingStatus.failed ? (React.createElement("div", { style: {
                height: "200px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            } },
            React.createElement("img", { src: require("../../../assets/alarm.svg"), style: { height: "60px", width: "60px" } }),
            React.createElement("span", { style: {
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#db3030",
                    marginTop: "8px",
                } }, "Something went wrong!"))) : (React.createElement(React.Fragment, null, status &&
            status.statuses.map(function (orderStatusLogItem, n) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                return (React.createElement(React.Fragment, { key: orderStatusLogItem.id },
                    React.createElement("li", { className: styles.progressItem },
                        React.createElement(React.Fragment, null,
                            React.createElement(OrderStatusToolTip, { placement: "top", title: React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "row",
                                    } },
                                    ((_b = (_a = orderStatusLogItem) === null || _a === void 0 ? void 0 : _a.owner) === null || _b === void 0 ? void 0 : _b.name) && (React.createElement(React.Fragment, null,
                                        React.createElement("span", { className: styles.userName }, (_d = (_c = orderStatusLogItem) === null || _c === void 0 ? void 0 : _c.owner) === null || _d === void 0 ? void 0 : _d.name),
                                        "-")),
                                    courier_name &&
                                        ((_e = orderStatusLogItem) === null || _e === void 0 ? void 0 : _e.user_type) === "courier" && (React.createElement(React.Fragment, null,
                                        React.createElement("span", { className: styles.userName }, courier_name),
                                        "-")),
                                    React.createElement("span", { className: styles.userName },
                                        ownerTypeReadableFormat[_h = (_g = (_f = orderStatusLogItem) === null || _f === void 0 ? void 0 : _f.owner) === null || _g === void 0 ? void 0 : _g.type, (_h !== null && _h !== void 0 ? _h : (_j = orderStatusLogItem) === null || _j === void 0 ? void 0 : _j.user_type)],
                                        ")")), arrow: true },
                                React.createElement("a", { className: styles.link, id: "id" },
                                    [
                                        OrderStatus.ignored_got_picked_up,
                                        OrderStatus.ignored_ready_for_pickup,
                                    ].includes(orderStatusLogItem.status) ? (React.createElement("img", { className: styles.errorsIcon, src: ErrorIcon })) : (React.createElement(Icon, { states: n === len - 1 })),
                                    React.createElement("div", { className: styles.orderStateWrapper },
                                        React.createElement("span", { className: styles.orderState },
                                            ![
                                                OrderStatus.ignored_got_picked_up,
                                                OrderStatus.ignored_ready_for_pickup,
                                            ].includes(orderStatusLogItem.status) && "Order",
                                            " ",
                                            orderStatusTranslations[orderStatusLogItem.status]),
                                        React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                                            React.createElement("span", { className: styles.time }, moment(orderStatusLogItem.store_time).format("lll")),
                                            React.createElement("span", { className: styles.country }, "EGY")),
                                        React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                                            React.createElement("span", { className: styles.time }, moment(orderStatusLogItem.store_time)
                                                .utc()
                                                .tz("Asia/Riyadh")
                                                .format("lll")),
                                            React.createElement("span", { className: styles.country }, "KSA")))))),
                        !isMobile && (React.createElement("div", { className: "" + styles.lineForState, style: {
                                backgroundColor: n === len - 1 ? "#d9d9d9" : "#41126c",
                            } })),
                        React.createElement(CancellationReasonComponent, { item: orderStatusLogItem, handleOpen: handleOpen, setActionType: setActionType, routingMethod: routingMethod }))));
            })))));
};
export default hot(module)(OrderTracking);
export var CancellationReasonComponent = function (_a) {
    var item = _a.item, handleOpen = _a.handleOpen, setActionType = _a.setActionType, routingMethod = _a.routingMethod;
    var isOrderCancelled = React.useMemo(function () {
        return (item.status === OrderStatus.rejected ||
            (item.status === OrderStatus.canceled_by_cashier &&
                routingMethod !== "pos") ||
            item.status === OrderStatus.canceled_by_courier ||
            item.status === OrderStatus.customer_no_show ||
            item.status === OrderStatus.canceled_by_agent);
    }, [item]);
    React.useEffect(function () {
        setActionType(isOrderCancelled
            ? item.order_status_reason !== null
                ? "edit"
                : "add"
            : "edit");
    }, [isOrderCancelled]);
    return (React.createElement(React.Fragment, null, isOrderCancelled && (React.createElement("div", { className: styles.rejectionReason },
        React.createElement("img", { src: require("../../../assets/error.svg") }),
        React.createElement("div", { className: styles.reasonWrapper },
            React.createElement("div", { className: styles.reasonsWrapper },
                React.createElement("span", { className: styles.reasonTitle }, "Rejection Reason"),
                item.order_status_reason !== null && (React.createElement("button", { onClick: handleOpen, className: styles.editReason }, "Edit")),
                !!!item.order_status_reason && (React.createElement("button", { onClick: handleOpen, className: styles.editReason }, "Add"))),
            isOrderCancelled && item.order_status_reason !== null && (React.createElement("span", { className: styles.reason }, ("" + (item.order_status_reason.en_text +
                ("" + (item.order_status_reason.ar_text &&
                    " " + ("- " + item.order_status_reason.ar_text)))))
                .substring(0, 200)
                .concat("..."))))))));
};
