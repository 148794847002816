var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../useDefaultAndInsertedClassName";
import { ClipLoader } from "react-spinners";
var GenericButton = function (props) {
    var iconPath = props.iconPath, IconClass = props.IconClass, disabled = props.disabled, isLoading = props.isLoading, type = props.type, rest = __rest(props, ["iconPath", "IconClass", "disabled", "isLoading", "type"]);
    var icon = iconPath ? (React.createElement("img", { className: uDIC(styles.icon, IconClass), src: iconPath })) : null;
    return (React.createElement("button", __assign({ type: type ? type : "button", disabled: disabled }, rest, { className: styles.genericButtonDefaults + " " + props.className }), isLoading ? (React.createElement(ClipLoader, { color: "#fffff", size: 16 })) : (React.createElement(React.Fragment, null,
        React.createElement("span", null, icon),
        " ",
        React.createElement("p", null, props.label)))));
};
export default GenericButton;
