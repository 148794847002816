import * as React from 'react';
import * as classes from './NavigationItems.module.sass';
import NavigationItem from './NavigationItem/NavigationItem';
import { hot } from 'react-hot-loader';
import CollapseSettings from '../CollapseSettings';
var NavigationItems = function () {
    return (React.createElement("ul", { className: classes.NavigationItems },
        React.createElement(NavigationItem, { link: '/wall' },
            React.createElement("div", { className: classes.bar },
                React.createElement("img", { src: require('../../../assets/tower.svg') }),
                React.createElement("span", { className: classes.showNavItem }, "Watch tower"))),
        React.createElement(NavigationItem, { link: '/watch-tower' },
            React.createElement("div", { className: classes.bar },
                React.createElement("img", { src: require('../../../assets/shopNav.svg') }),
                React.createElement("span", { className: classes.showNavItem }, "Branch status"))),
        React.createElement(NavigationItem, { link: '/calls-logs' },
            React.createElement("div", { className: classes.bar },
                React.createElement("img", { src: require('../../../assets/phone-callNav.svg') }),
                React.createElement("span", { className: classes.showNavItem }, "Support Request"))),
        React.createElement(CollapseSettings, null),
        React.createElement(NavigationItem, { link: "/fraud-center" },
            React.createElement("div", { className: classes.bar },
                React.createElement("img", { src: require("../../../assets/icon-nav-secure-payment.svg") }),
                React.createElement("span", { className: classes.showNavItem }, "Fraud center")))));
};
export default hot(module)(NavigationItems);
