import axios from "./axios";
import * as Requests from "./requests";
export var cancelOrder = function (token, cashier_id, reason_id, refund_id, id) {
    return axios.put(Requests.cancelOrderStatus(id), {
        cashier_id: cashier_id,
        reason_id: reason_id,
        refund_method: refund_id !== "" ? refund_id : undefined,
    }, {
        headers: {
            token: token,
        },
    });
};
