import React from "react";
var imagesURL = process.env.ENDPOINT;
var prefixImage = function (image) {
    if (image.indexOf("http") === -1) {
        return imagesURL + "/" + image;
    }
    return image;
};
export var useProcessImageResource = function (imageResource, baseUrl) {
    // I could've used a useMemo like such
    // return React.useMemo(() => {
    //   if (imageResource instanceof File) {
    //     return URL.createObjectURL(imageResource);
    //   } else {
    //     return imageResource;
    //   }
    // }, [imageResource]);
    // but this would leave room for memory leaks as the memo wouldn't track the change
    // from file to string therefore this won't work
    // there for a ref is more appropriate in this context
    // as a ref would have a single reference accross the application
    // and would be tracked if the `imageResource` changed to a string
    // and the `URL.createObjectURL` memory leak can be plugged through the same effect
    if (baseUrl === void 0) { baseUrl = ""; }
    var _a = React.useState(""), imageSource = _a[0], setImageSource = _a[1];
    React.useEffect(function () {
        if (imageResource instanceof File) {
            setImageSource(URL.createObjectURL(imageResource));
        }
        else {
            setImageSource(imageResource && prefixImage(baseUrl + "/" + imageResource));
        }
        return function () {
            if (imageResource instanceof File) {
                URL.revokeObjectURL(imageSource);
            }
        };
    }, [imageResource]);
    return imageSource;
};
