import { action, payload } from "ts-action";
import { getStoreAction, getBrandsAction, getBrandFilterAction, getActiveBrandsAction, getInactiveBrandsAction, getClosedBrandsAction, countryFilterAction, } from "../../constants/index";
export var getStore = action(getStoreAction.requested);
export var getStoreSuccess = action(getStoreAction.fulfilled, payload());
export var getStoreFailure = action(getStoreAction.rejected, payload());
export var getBrandFilter = action(getBrandFilterAction.requested, payload());
export var getBrandFilterSuccess = action(getBrandFilterAction.fulfilled, payload());
export var getBrandFilterFailure = action(getBrandFilterAction.rejected, payload());
export var getBrands = action(getBrandsAction.requested, payload());
export var getBrandsSuccess = action(getBrandsAction.fulfilled, payload());
export var getBrandsFailure = action(getBrandsAction.rejected, payload());
export var getActiveBrands = action(getActiveBrandsAction.requested, payload());
export var getActiveBrandsSuccess = action(getActiveBrandsAction.fulfilled, payload());
export var getActiveBrandsFailure = action(getActiveBrandsAction.rejected, payload());
export var getInactiveBrands = action(getInactiveBrandsAction.requested, payload());
export var getInactiveBrandsSuccess = action(getInactiveBrandsAction.fulfilled, payload());
export var getInactiveBrandsFailure = action(getInactiveBrandsAction.rejected, payload());
export var getClosedBrands = action(getClosedBrandsAction.requested, payload());
export var getClosedBrandsSuccess = action(getClosedBrandsAction.fulfilled, payload());
export var getClosedBrandsFailure = action(getClosedBrandsAction.rejected, payload());
export var fireCountryFilter = action(countryFilterAction.requested);
