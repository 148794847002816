var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { clearPrevOrderDetailsAction, orderAction } from "../../constants";
import { getOrderSuccess } from "../actions/order-action";
import { acceptOrderSuccess, cancelledByBranchSuccess, cancelOrderSuccess, markOrderAsDeliveredSuccess, markOrderAsGotPickedUpSuccess, markOrderAsOutForDeliverySuccess, markOrderAsReadyForPickupSuccess, rejectOrderStatusSuccess, noShowOrderStatusSuccess, dispatchOrderAsReadySuccess, } from "../actions/order-status-action";
import { OrderStatus } from "../../types";
import { DeliveryMethods, RoutingMethodPOS, OrderingSource, } from "../../constants/types";
import { OrderType } from "../../components/OrderDetails/OrderInfo/CustomerInformation";
var genStatusFn = function (status) {
    return function (state) { return (__assign(__assign({}, state), { order: __assign(__assign({}, state.order), { status: status }) })); };
};
var initialState = {
    order: {
        branch_id: "",
        cashier_code: 0,
        payment_transaction_id: "",
        created_at: 0,
        estimated_customer_arrival_interval: 0,
        incoming_order_status: false,
        id: "",
        order_code: "",
        payment_method: "",
        call_store_to_cancel_with_penalty: false,
        status: "",
        store_id: "",
        total_invoices: 0,
        delivery_fees: 0,
        updated_at: 0,
        comment: "",
        someone_else: 0,
        travel_mode: "",
        estimated_delivery_interval: 0,
        estimated_delivery_max_time: 0,
        estimated_delivery_min_time: 0,
        order_type: OrderType.delivery,
        routing_method: RoutingMethodPOS.standalone,
        branch_name: "",
        country_iso_code: "",
        customer_name: "",
        customer_total_orders: 0,
        pos_type: "",
        promo_code: "",
        promotion_description: "",
        promotion_title: "",
        store_name: "",
        total_invoices_without_promo_code: 0,
        ordering_source: OrderingSource.customer_app,
        delivery_method: DeliveryMethods.in_house_delivery,
        dispatched_to_third_party_delivery: 0,
    },
};
export var orderReducer = withLoadingReducer(reducer(initialState, on(getOrderSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order: payload }));
}), on(dispatchOrderAsReadySuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order: payload }));
}), on(rejectOrderStatusSuccess, genStatusFn(OrderStatus.rejected)), on(markOrderAsDeliveredSuccess, genStatusFn(OrderStatus.delivered)), on(acceptOrderSuccess, genStatusFn(OrderStatus.accepted)), on(cancelOrderSuccess, genStatusFn(OrderStatus.canceled_by_agent)), on(cancelledByBranchSuccess, genStatusFn(OrderStatus.canceled_by_cashier)), on(markOrderAsOutForDeliverySuccess, genStatusFn(OrderStatus.out_for_delivery)), on(markOrderAsGotPickedUpSuccess, genStatusFn(OrderStatus.got_picked_up)), on(markOrderAsReadyForPickupSuccess, genStatusFn(OrderStatus.ready_for_pickup)), on(noShowOrderStatusSuccess, genStatusFn(OrderStatus.customer_no_show)), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { order: initialState.order })); })), orderAction);
