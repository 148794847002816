var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { noShowSuccess } from "../actions/order-status-action";
import { withLoadingReducer } from "./withLoadingState";
import { clearPrevOrderDetailsAction, noShowAction } from "../../constants";
export var noShowReducer = withLoadingReducer(reducer({
    customer_no_show: false,
}, on(noShowSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { customer_no_show: payload.customer_no_show }));
}), on(clearPrevOrderDetailsAction, function (state, _a) { return (__assign(__assign({}, state), { customer_no_show: false })); })), noShowAction);
