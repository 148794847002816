import { action, payload } from "ts-action";
import * as actions from "../../constants/index";
export var getCallLogs = action(actions.getCallLogsAction.requested, payload());
export var getCallLogsSuccess = action(actions.getCallLogsAction.fulfilled, payload());
export var getCallLogsFailure = action(actions.getCallLogsAction.rejected, payload());
export var DisableButtonAnimation = action(actions.disableButtonAnimationAction.requested, payload);
export var EnableCallNotification = action(actions.enableNotificationAction.requested, payload);
export var DisableCallNotification = action(actions.disableNotificationAction.requested, payload);
export var requestResolvedSuccess = action(actions.requestResolvedAction.fulfilled, payload());
export var requestResolved = action(actions.requestResolvedAction.requested, payload());
export var requestResolvedFailure = action(actions.requestResolvedAction.rejected, payload());
