var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { Form, Field, reduxForm, SubmissionError, } from "redux-form";
import { ClipLoader } from "react-spinners";
import * as styles from "./styles.module.sass";
// import { auth } from "../../redux-store/actions/auth-action";
// import history from "../../helper/history";
import { useSelect } from "../../../../../helper/use-select";
import { Modal } from "@material-ui/core";
import { required } from "../../../../../redux-store/validators";
import { RenderGenericField } from "../../../../Shared-Components/FormElements/RenderGenericField";
import { verifyPinCodeApi } from "../../../../../axios/wallet/verifyPinCode";
import { useMutation } from "react-query";
var PinCodeModal = function (props) {
    var handleSubmit = props.handleSubmit, valid = props.valid, submitSucceeded = props.submitSucceeded, open = props.open, onClose = props.onClose, confirm = props.confirm;
    var _a = useSelect(function (state) { return state.authReducer; }), loadingStatus = _a.loadingStatus, token = _a.token;
    var sendPin = useMutation(function (pincode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, verifyPinCodeApi(token, {
                        pin_code: pincode,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, { retry: 2 }).mutateAsync;
    // const dispatch = useDispatch();
    var submit = function (_a) {
        var pincode = _a.pincode;
        return __awaiter(void 0, void 0, void 0, function () {
            var is_verified;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, sendPin(pincode)];
                    case 1:
                        is_verified = (_b.sent()).is_verified;
                        if (is_verified) {
                            confirm();
                            onClose();
                        }
                        else {
                            throw new SubmissionError({ pincode: "incorrect pincode" });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return (React.createElement(Modal, { onClose: onClose, open: open },
        React.createElement("div", { className: styles.roundWrapper },
            React.createElement("div", { className: styles.pincodeWrapper },
                React.createElement("div", { className: styles.closeWrapper },
                    React.createElement("span", { className: styles.title }, "Enter pin code to edit amount"),
                    React.createElement("img", { onClick: onClose, src: require("../../../../../assets/icon-close.svg") })),
                React.createElement("div", { "data-test-id": "pincode-wrapper", className: styles.loginPage },
                    React.createElement(Form, { "data-test-id": "login-form", className: styles.form, onSubmit: handleSubmit(submit) },
                        React.createElement("div", { "data-test-id": "pincode", className: styles.usernameWrapper },
                            React.createElement(Field, { component: RenderGenericField, name: "pincode", label: "Pincode", type: "password", placeholder: "e.g. 15455", validate: [required] })),
                        React.createElement("div", { style: { marginTop: "8px" } }),
                        React.createElement("div", { className: styles.footer + " " + styles.submit },
                            React.createElement("button", { className: !valid ? styles.disabled : styles.Btn, disabled: !valid, type: "submit" }, loadingStatus === "loading" && submitSucceeded ? (React.createElement(ClipLoader, { size: 30, color: "#fff" })) : ("Continue")))))))));
};
export default reduxForm({
    form: "pincode",
})(PinCodeModal);
