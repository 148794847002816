import axios from "./axios";
import * as Requests from "./requests";
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["resolved"] = "resolved";
    RequestStatus["unresolved"] = "unresolved";
})(RequestStatus || (RequestStatus = {}));
export var requestResolved = function (token, id, status) {
    return axios.put(Requests.requestResolved(id), { status: status }, {
        headers: {
            token: token
        }
    });
};
