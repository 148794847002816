import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useSelect } from "../../helper/use-select";
import { getUserProfile } from "../../redux-store/actions/user-profile-actions";
import { LoadingStatus } from "../../redux-store/reducers/withLoadingState";
import * as styles from "./userSearchBox.module.sass";
export var UserSearchBox = function () {
    // Redux dispatch & select
    var dispatch = useDispatch();
    var history = useHistory();
    var location = useLocation();
    var loadingStatus = useSelect(function (state) { return state.getUserProfileReducer; }).loadingStatus;
    // local state & handlers
    var _a = useState(""), searchValue = _a[0], setSearchValue = _a[1];
    useEffect(function () {
        if (!searchValue && location.pathname.split("user-profile/")[1]) {
            setSearchValue(location.pathname.split("user-profile/")[1]);
        }
    }, [location]);
    useEffect(function () {
        if (!location.pathname.includes("user-profile")) {
            setSearchValue("");
        }
    }, [location]);
    var handleKeyPress = function (event) {
        if (event.key === "Enter") {
            dispatch(getUserProfile({
                phone_number: searchValue,
                history: history,
            }));
        }
    };
    var handleClick = function () {
        if (searchValue && loadingStatus !== LoadingStatus.loading) {
            dispatch(getUserProfile({
                phone_number: searchValue,
                history: history,
            }));
        }
    };
    return (React.createElement("div", { className: styles.search },
        React.createElement("input", { type: "tel", placeholder: "Search for customer by phone…", className: styles.input, value: searchValue, onChange: function (e) { return setSearchValue(e.target.value); }, onKeyPress: handleKeyPress, disabled: loadingStatus === LoadingStatus.loading, "data-test-id": "cy-user-search-phone-number" }),
        loadingStatus === LoadingStatus.loading ? (React.createElement(CircularProgress, { size: 22, color: "inherit" })) : (React.createElement("img", { onClick: handleClick, src: require("../../assets/search.svg"), className: styles.searchIcon }))));
};
