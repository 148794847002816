import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import CustomizedFilter from "./CustomizeFilter";
var BrandHeader = function (_a) {
    var handleSearch = _a.handleSearch, brandsCounter = _a.brandsCounter, inputValue = _a.inputValue, onSelect = _a.onSelect;
    var handleChange = useCallback(function (e) {
        handleSearch(e.target.value);
    }, []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.headerWrapper },
            React.createElement("span", { className: styles.brandsCounter },
                "Brands (",
                brandsCounter,
                ")"),
            React.createElement(CustomizedFilter, { handleSelect: onSelect })),
        React.createElement("div", { className: styles.searchWrapper },
            React.createElement("div", { className: styles.search },
                React.createElement("input", { type: "text", placeholder: "Search for brands", className: styles.input, value: inputValue, onChange: handleChange }),
                React.createElement("img", { src: require("../../../../assets/search.svg"), className: styles.searchIcon })))));
};
export default hot(module)(BrandHeader);
