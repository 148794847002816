import * as React from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import GenericButtonWithIcon from "../../../Shared-Components/GenericButtonWithIcon";
import { useSelect } from "../../../../helper/use-select";
import SingleContactItem from "./SingleContactItem";
import { useDispatch } from "react-redux";
import { DisableButtonAnimation } from "../../../../redux-store/actions/get-call-logs";
var ContactSupportModal = function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var openHandler = function () {
        setOpen(true);
        dispatch(DisableButtonAnimation());
    };
    // const [is_open , setIs_open] = React.useState(open || false);
    var handleClose = function () {
        setOpen(false);
    };
    var _b = useSelect(function (state) { return state.CallLogsReducer; }), unseenCallLogs = _b.unseenCallLogs, callLogsById = _b.callLogsById, isNotified = _b.isNotified, isReceivedRequest = _b.isReceivedRequest;
    var unseenCallLogsArray = Array.from(unseenCallLogs);
    return (React.createElement(React.Fragment, null,
        isNotified ? '' : React.createElement("a", { id: "calltrap_btn", onClick: openHandler, className: styles.BtnWrapper + " " + (isReceivedRequest ? styles.calltrap_offline : '') },
            React.createElement("div", { id: "calltrap_ico" }),
            React.createElement("img", { className: styles.img, src: require("../../../../assets/phone-callNav.svg") })),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.insideWrapper },
                    React.createElement("div", { className: styles.header },
                        "Call requests (",
                        unseenCallLogsArray.length,
                        ")"),
                    React.createElement("div", { className: styles.contentWrapper }, unseenCallLogsArray && unseenCallLogsArray.map(function (it) { return it !== "" ? React.createElement(SingleContactItem, { branch_name: callLogsById[it].branch_name, id: callLogsById[it].id, phone_number: callLogsById[it].cashier_phone_number, store_name: callLogsById[it].store_name }) : ''; })),
                    React.createElement("div", { className: styles.closeModal, onClick: handleClose },
                        React.createElement(GenericButtonWithIcon, { label: "close", className: styles.btn })))))));
};
export default hot(module)(ContactSupportModal);
