// IMPORTS
import { useGetCustomerStores } from "./customerStores.hook";
import { useGetOfflinePointOperations } from "./useGetOfflinePointOperations";
import { useGetOfflineRedemption } from "./useGetOfflineRedemption";
import { useGetOnlineOrder } from "./useGetOnlineOrder";
import { useGetCurrency } from "./useGetCurrency";
import { useGetUserVisits } from "./userVisits.query";
import { useHasTruthyValue } from "./useHasTruthyValue";
import { useGetStores } from "./useGetStores";
import { useGetCancellationReasons } from "./getCancellationReasons.hook";
import { useAssignPromocode, useGetCompensationPromo, } from "./compensationPromo.hook";
import { useCompare } from "./useCompare";
import { useBlockCustomer } from "./useBlockCustomer";
import { useSendCompensationalPoints } from "./compensationPoints.hook";
export var Query = {
    useGetCompensationPromo: useGetCompensationPromo,
    useGetCustomerStores: useGetCustomerStores,
    useGetUserVisits: useGetUserVisits,
    useGetOfflineRedemption: useGetOfflineRedemption,
    useGetOnlineOrder: useGetOnlineOrder,
    useGetOfflinePointOperations: useGetOfflinePointOperations,
    useGetCancellationReasons: useGetCancellationReasons,
    useGetStores: useGetStores,
};
export var Mutation = {
    useAssignPromocode: useAssignPromocode,
    useBlockCustomer: useBlockCustomer,
    useSendCompensationalPoints: useSendCompensationalPoints,
};
export var Helpers = {
    useGetCurrency: useGetCurrency,
    useHasTruthyValue: useHasTruthyValue,
    useCompare: useCompare,
};
// EXPORTS
export { useRouter } from "./useRouter";
export { useGetOfflinePointOperations } from "./useGetOfflinePointOperations";
export { useGetOfflineRedemption } from "./useGetOfflineRedemption";
export { useGetOnlineOrder } from "./useGetOnlineOrder";
export { useGetCurrency } from "./useGetCurrency";
export { useGetUserVisits } from "./userVisits.query";
export { useHasTruthyValue } from "./useHasTruthyValue";
