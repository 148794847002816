import React, { useState, useMemo } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import ZonesModal from "./ZonesModal";
var DeliveryInformation = function (_a) {
    var delivery_zones = _a.delivery_zones, delivery_fees = _a.delivery_fees, currency = _a.currency, delivery_time = _a.delivery_time;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var openModalHandler = function () { return setOpen(true); };
    var closeModalHandler = function () { return setOpen(false); };
    var deliveryZonesCount = useMemo(function () { return delivery_zones.length; }, [
        delivery_zones
    ]);
    var seeMore = useMemo(function () { return delivery_zones.length - 1; }, [delivery_zones]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("span", { className: styles.title }, "Delivery Information"),
        React.createElement("div", { className: styles.deliveryWrapper },
            React.createElement("div", { className: styles.deliveryCostWrapper },
                React.createElement("img", { className: styles.img, src: require("../../../../../assets/coin.svg") }),
                React.createElement("div", { className: styles.costWrapper },
                    React.createElement("span", { className: styles.costTitle }, "Delivery Cost"),
                    React.createElement("span", { className: styles.costSubTitle },
                        delivery_fees,
                        " ",
                        currency))),
            React.createElement("div", { style: { margin: "0 20px" } }),
            React.createElement("div", { className: styles.deliveryCostWrapper },
                React.createElement("img", { className: styles.img, src: require("../../../../../assets/clock.svg") }),
                React.createElement("div", { className: styles.costWrapper },
                    React.createElement("span", { className: styles.costTitle }, "Delivery time"),
                    React.createElement("span", { className: styles.costSubTitle },
                        delivery_time,
                        " min")))),
        React.createElement("span", { className: styles.zonesCount },
            "Delivery zones (",
            deliveryZonesCount,
            ")"),
        React.createElement("div", { className: styles.zonesWrapper }, delivery_zones && delivery_zones[0] && (React.createElement("div", null,
            React.createElement("span", { className: styles.city }, delivery_zones[0].city_en_name),
            React.createElement("span", { className: styles.area },
                delivery_zones[0].district_en_name,
                " -",
                " ",
                delivery_zones[0].sub_district_en_name)))),
        seeMore !== 0 && (React.createElement("span", { onClick: openModalHandler, className: styles.seeMore },
            "See ",
            seeMore,
            " more zones")),
        open && (React.createElement(ZonesModal, { delivery_zones: delivery_zones, open: open, onClose: closeModalHandler }))));
};
export default hot(module)(DeliveryInformation);
