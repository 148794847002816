var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getOrdersAction, ORDERS_PAGE_SIZE } from "../../constants";
import { getOrdersSuccess, notifyNewOrder, checkOrdersUpdateSuccess, incomingOrderStatus, } from "../actions/orders-action";
var sortDescending = function (a, b) { return b - a; };
var sortOrders = function (orderAId, orderBId, ordersById) {
    return sortDescending(ordersById[orderAId].created_at, ordersById[orderBId].created_at);
};
var processIdCollection = function (orders) {
    return orders.reduce(function (acc, next) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
    }, {});
};
var processAndOrderOrders = function (orderedDuplicatedOrders) {
    var index = 0;
    var ordersOrdering = {};
    orderedDuplicatedOrders.forEach(function (orderId) {
        if (ordersOrdering[orderId] === undefined) {
            ordersOrdering[orderId] = index++;
        }
    });
    var uniqueOrderedOrders = Array.from(new Set(orderedDuplicatedOrders)).sort(function (a, b) { return ordersOrdering[a] - ordersOrdering[b]; });
    // the code below should remove the trailing orders from the next page for
    // consistency as when getting the next page should not have much duplicates
    // the current page should be the floored current orders count divided by the
    // page size
    var currentPage = Math.ceil(uniqueOrderedOrders.length / ORDERS_PAGE_SIZE);
    // then we slice the orders as to be exactly whatever the page size times the
    // current page so for example
    // if we have 40 records and the page size is 15,
    // then the current page is 40 / 15 = 2
    // and page 2 contains only 30 orders
    // so cut everything after 30th order
    var exactly15PagesOrders = uniqueOrderedOrders.slice(0, currentPage * ORDERS_PAGE_SIZE);
    return exactly15PagesOrders;
};
var objectValuesAllUnset = function (obj) {
    return Object.keys(obj).every(function (key) { return obj[key] === undefined; });
};
export var ordersReducer = withLoadingReducer(reducer({
    order: [],
    orderById: {},
    ordersHasMore: true,
    keptFilters: {},
    page: 0,
    seenOrders: new Set(),
}, on(getOrdersSuccess, function (state, _a) {
    var payload = _a.payload;
    var seenOrders = new Set(__spreadArrays(state.order, payload.orders.map(function (a) { return a.id; })));
    var orderById = __assign(__assign({}, (payload.page !== 0 ? state.orderById : {})), payload.orders.reduce(function (acc, next) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a)));
    }, {}));
    return __assign(__assign({}, state), { seenOrders: seenOrders, order: processAndOrderOrders(__spreadArrays((payload.page !== 0 ? state.order : []), payload.orders.map(function (a) { return a.id; }))), keptFilters: payload.filters, ordersHasMore: payload.hasMore, page: payload.page, orderById: orderById });
}), on(incomingOrderStatus, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { orderById: Object.values(state.orderById).reduce(function (acc, next) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[next.id] = __assign(__assign({}, next), { incoming_order_status: payload }), _a)));
        }, {}) }));
}), on(notifyNewOrder, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { seenOrders: new Set(__spreadArrays(Array.from(state.seenOrders), [payload.orderId])) }));
}), on(checkOrdersUpdateSuccess, function (state, _a) {
    var payload = _a.payload;
    var noFiltersApplied = objectValuesAllUnset(state.keptFilters);
    if (noFiltersApplied) {
        var orderById = __assign(__assign({}, state.orderById), processIdCollection(payload.sort(function (item1, item2) {
            return item2.created_at - item1.created_at;
        })));
        var order = processAndOrderOrders(__spreadArrays(payload.map(function (a) { return a.id; }), state.order));
        return __assign(__assign({}, state), { order: order,
            orderById: orderById });
    }
    return state;
})), getOrdersAction);
