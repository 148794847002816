import axios from './axios';
import * as Requests from './requests';
export var getCountries = function (token) {
    return axios.get(Requests.getCountries, {
        baseURL: process.env.ENDPOINT,
        headers: {
            token: token,
        }
    });
};
