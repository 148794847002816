import { action, payload } from "ts-action";
import { brandsGetNextPageAction } from "../../constants";
export var brandsGetNextPage = action(brandsGetNextPageAction.requested);
export var brandsGetNextPageRefresh = action("ACTION_BRAND_GET_NEXT_PAGE_REFRESH");
export var filterBrands = action("ACTION_FILTER_BRANCH_STATUS_BRANDS", payload());
// the saga should handle the case when the pages in a certain status end
// then it would send back the status to get next time, if the pages has not ended
// then it would send back the current status
// it should also return the page as 1 if it got to the end of a certain status and went to another status
// and it should return the next page if it found items in the current status
export var brandsGetNextPageSuccess = action(brandsGetNextPageAction.fulfilled, payload());
// Failure is highly unlikely but ye know
export var brandsGetNextPageFailure = action(brandsGetNextPageAction.rejected, payload());
