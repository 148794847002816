import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
var BrandsHeader = function (props) {
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("span", { className: styles.content },
            props.name,
            " (",
            props.count,
            ")")));
};
export default hot(module)(BrandsHeader);
