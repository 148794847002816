import { action, payload } from "ts-action";
import { ACTION_RECEIVE_CASHIER_SUPPORT_REQUEST, ACTION_AUTHENTICATE_SOCKET, ACTION_DISCONNECT_SOCKET, } from "../../constants/index";
export var OrderingStatus;
(function (OrderingStatus) {
    OrderingStatus["disabledIndefinitely"] = "service_disabled";
    OrderingStatus["enabled"] = "service_enabled";
    OrderingStatus["pausedByCashier"] = "paused_by_cashier";
    OrderingStatus["pausedByBrand"] = "paused_by_brand";
    OrderingStatus["pausedForOutsideWorkingHours"] = "paused_outside_working_hours";
    OrderingStatus["pausedForInactivity"] = "paused_for_inactivity";
    OrderingStatus["pausedForDisconnection"] = "paused_for_disconnection";
    OrderingStatus["manual"] = "manual";
})(OrderingStatus || (OrderingStatus = {}));
export var receivedCashierSupportRequest = action(ACTION_RECEIVE_CASHIER_SUPPORT_REQUEST, payload());
export var receiveBranchStatusChange = action("ACTION_RECEIVE_BRANCH_STATUS_CHANGE", payload());
export var newlyCreatedOrder = action("NEWLY_CREATED_ORDER", payload());
export var authenticateSocket = action(ACTION_AUTHENTICATE_SOCKET, payload());
export var disconnectSocket = action(ACTION_DISCONNECT_SOCKET);
// export const receivedCashierSupportRequestSuccess = action(
//   receiveCashierSupportRequestAction.fulfilled,
//   payload<IRequests>()
// );
// export const receivedCashierSupportRequestFailure = action(
//   receiveCashierSupportRequestAction.rejected,
//   payload<Error>()
// );
